import React, { useCallback, useState } from 'react';
import { Navbar } from '../../../../Navbar';
import { StepTab, TabGroup } from '../../../../shared';
import { ContributorCountryPlansStatus } from '../../../../4-1-medical-strategy/shared/feature-key-events-sidebar/src';
import {
  GlobalPlanButton,
  StyledTabGroupWrapper,
  StyledTwoButtonModal,
  TabsAndButton,
} from '../../../../../containers/MedicalStrategy/index.style';
import {
  SubStep,
  useStrategyUpdateMutation,
} from '../../../../../data/graphql/generated';
import { StepBarsWrapper } from '../../../../../containers/StrategicQuestion';
import { useHistory } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { verifyUserRole } from '../../../../../utils/verifyUserRole';
import useDesktop from '../../../../../hooks/useDesktop';

type Props = {
  drugId: string;
  strategyId: string;
  countryTacticsEnabled: boolean;
  setCountryTacticsEnabled: (enabled: boolean | null | undefined) => void;
  subStep: SubStep;
  strategicImperativeId?: number;
  countryContributionsExist: boolean;
};

export const CommercialStrategyNav = ({
  drugId,
  strategyId,
  countryTacticsEnabled,
  setCountryTacticsEnabled,
  subStep,
  strategicImperativeId,
  countryContributionsExist,
}: Props) => {
  const history = useHistory();
  const isDesktop = useDesktop();

  const [countryTacticsWarningModal, setCountryTacticsWarningModal] = useState(
    false
  );

  const [{ user }] = useAuthContext();
  const { isLead, isCountryContributor, isGlobalContributor } = verifyUserRole(
    user?.role,
    user?.country
  );

  const showContributorCountryPlan =
    (isCountryContributor || isGlobalContributor) && countryTacticsEnabled;

  const [updateStrategy] = useStrategyUpdateMutation();

  const toggleEnableCountryTactics = useCallback(async () => {
    if (countryTacticsEnabled && countryContributionsExist) {
      setCountryTacticsWarningModal(true);
      return;
    }

    try {
      await updateStrategy({
        variables: {
          id: +strategyId,
          data: {
            enableCountryTactics: !countryTacticsEnabled,
          },
        },
      });
      setCountryTacticsEnabled(!countryTacticsEnabled);
    } catch (err) {
      alert('Something went wrong');
    }
  }, [
    countryContributionsExist,
    countryTacticsEnabled,
    setCountryTacticsEnabled,
    strategyId,
    updateStrategy,
  ]);

  const handleGlobalPlanDisableCountryPlans = async () => {
    try {
      await updateStrategy({
        variables: {
          id: +strategyId,
          data: {
            enableCountryTactics: false,
          },
        },
      });
    } catch (err) {
      alert('Something went wrong');
    } finally {
      setCountryTacticsWarningModal(false);
    }
  };

  return (
    <>
      <StyledTwoButtonModal
        modalOpen={countryTacticsWarningModal}
        handleClose={() => setCountryTacticsWarningModal(false)}
        title="Disabling country plans will hide content"
        body="Contributors have made use of country-specific features. These will be hidden for all users if you disable country plans."
        rightButtonText="Disable country plans"
        leftButtonOnClick={() => setCountryTacticsWarningModal(false)}
        rightButtonOnClick={handleGlobalPlanDisableCountryPlans}
      />
      <Navbar
        pageNavChildren={
          <>
            {showContributorCountryPlan && !isDesktop && (
              <ContributorCountryPlansStatus />
            )}
          </>
        }
      >
        <TabsAndButton showGlobalPlanButton={isLead}>
          {isLead && (
            <GlobalPlanButton
              onClick={toggleEnableCountryTactics}
              complete={countryTacticsEnabled}
              messaging={['Enable country plans']}
            />
          )}
          {showContributorCountryPlan && !!isDesktop && (
            <ContributorCountryPlansStatus />
          )}

          <StyledTabGroupWrapper>
            <TabGroup componentName="StepTab">
              <StepBarsWrapper>
                {[
                  {
                    title: 'Part 1: Big Ideas',
                    substep: SubStep.BigIdeas,
                  },
                  {
                    title: 'Part 2: Winning actions',
                    substep: SubStep.WinningActions,
                  },
                  {
                    title: 'Part 3: The Plan',
                    substep: SubStep.ThePlan,
                  },
                  {
                    title: 'Summary',
                    substep: SubStep.Summary,
                  },
                ].map((tab) => {
                  return (
                    <StepTab
                      key={tab.substep}
                      active={
                        tab.substep.toLowerCase() === subStep.toLowerCase()
                      }
                      text={tab.title}
                      onClick={() => {
                        if (
                          tab.substep.toLowerCase() ===
                          SubStep.Summary.toLowerCase()
                        ) {
                          let url = `/d/${drugId}/strategy/${strategyId}/4_3/summary`;
                          if (strategicImperativeId) {
                            url += `/${strategicImperativeId}`;
                          }

                          url += '/global';

                          history.push(url);
                        } else {
                          let url = `/d/${drugId}/strategy/${strategyId}/4_3/${kebabCase(
                            tab.substep
                          )}`;

                          if (strategicImperativeId) {
                            url += `/${strategicImperativeId}`;
                          }

                          url += '/global';

                          history.push(url);
                        }
                      }}
                    />
                  );
                })}
              </StepBarsWrapper>
            </TabGroup>
          </StyledTabGroupWrapper>
        </TabsAndButton>
      </Navbar>
    </>
  );
};
