import React, { useState } from 'react';

import { Navbar } from 'components/Navbar';
import { Page } from 'components/Page';
import { colors } from 'constants/colors';
import {
  Avatar,
  BodyExtraLarge,
  BodyLarge,
  BodySmall,
  BodyNormal,
  ButtonLabel,
  ButtonPill,
  ButtonRound,
  Caption,
  CategoryTab,
  ContributionTab,
  CountryFlag,
  Heading1,
  Heading2,
  Heading3,
  StepTab,
  Subtitle1,
  Subtitle2,
  TabGroup,
  TextInput,
  Icon,
  StepButton,
  ButtonNote,
  ButtonPillExpand,
} from 'components/shared';

import { countries } from 'constants/index';
import styled from 'styled-components/macro';
import { ButtonRoundExpand } from 'components/shared/ButtonRoundExpand';
import { useAuthContext } from 'contexts/AuthContext';
import {
  PostItCardType,
  StrategicQuestionType,
  User,
  CriticalMetricGoalInput,
} from 'data/graphql/generated';
import FormDropdown from 'components/shared/FormDropdown';
import FormTextInput from 'components/shared/FormTextInput';
import FormPasswordInput from 'components/shared/FormPasswordInput';
import { countryArr, Country } from 'types';
import FormCountryDropdown from 'components/shared/FormCountryDropdown';
import { StrategyDefinitionHeader } from 'components/StrategyDefinitionHeader';
import { CircularProgressBar } from 'components/CircularProgressBar';
import { ButtonNoteCardType } from 'components/shared/ButtonNoteCardType';
import FormCountryDropdownSearch from 'components/shared/FormCountryDropdownSearch';
import { GoalInputField } from 'components/3-5-critical-metrics/3-5-2-goals/feature-goals-table-row/src/lib/ui/GoalInputField';

const tabs = [
  {
    label: 'Origination',
    id: 1,
  },
  {
    label: 'Diagnosis',
    id: 2,
  },
  {
    label: 'Treatment',
    id: 3,
  },
];

const tabs2 = [
  {
    label: 'Aspirin (Bayer)',
    id: 1,
  },
  {
    label: 'Double anti-platelet therapy (DAPT)',
    id: 2,
  },
  {
    label: 'Clopidogrel/Plavix (Sanofi-aventis)',
    id: 3,
  },
];

const tabs3 = [
  {
    label: 'Pellentesque ac risus habitant netus quis eu tortor eget.',
    id: 1,
    secondary: 'Origination',
  },
  {
    label:
      'At a eleifend aliquam enim, et faucibus accumsan interdum. Cras velit congue diam vitae nulla mattis dictum aliquam. Felis feugiat quis imperdiet eget venenatis integer. In eget et sit.',
    id: 2,
    secondary: 'Diagnosis',
  },
  {
    label: 'Facilisi ultrices habitant risus integer amet, aenean dignissim.',
    id: 3,
    secondary: 'Treatment',
  },
];

const Content = styled.section`
  background: ${colors.greyMedium};
  border-radius: 5px;
  padding: 20px;
`;

const Section = styled.section<{ column?: boolean }>`
  margin: 30px 0;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
`;

const StepButtonWrapper = styled(Section)`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 20px;
  }
  background: #141427;
  padding: 10;
`;

const FormFieldSection = styled(Section)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const StyleGuide = () => {
  const [activeTabId, setActiveTabId] = useState<string | number>(1);
  const [buttonOpen, setButtonOpen] = useState(false);
  const [dropdownActive, setDropdownActive] = useState<boolean>(false);
  const [countryDropdownActive, setCountryDropdownActive] = useState<boolean>(
    false
  );
  const [selectedCountry, setSelectedCountry] = useState<Country>(
    'united kingdom'
  );
  const [selectedItem, setSelectedItem] = useState<string | number>('');
  const [formFieldsDisabled, setFormFieldsDisabled] = useState<boolean>(false);
  const [formFieldsErrorMessage, setFormFieldsErrorMessage] = useState<string>(
    ''
  );

  const toggleFormFieldError = () => {
    setFormFieldsErrorMessage(
      formFieldsErrorMessage ? '' : 'Please enter a valid value'
    );
  };

  const [pillButtonOpen, setPillButtonOpen] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [{ user }] = useAuthContext();

  return (
    <Page>
      <Navbar
        title="Style Guide"
        disableSecondary
      />

      <Content>
        <Section>
          <div style={{ background: colors.red }}>
            <GoalInputField
              editEnabled={true}
              isDesktop={true}
              hasTracking={true}
              setSelectedInput={() => {}}
              selectedInput={{
                id: CriticalMetricGoalInput.GlobalOnly,
                value: CriticalMetricGoalInput.GlobalOnly,
              }}
              onUpdate={async (data: any) => Promise.resolve()}
              onLockedFieldClick={() => {}}
            />
            <GoalInputField
              editEnabled={true}
              isDesktop={false}
              hasTracking={true}
              setSelectedInput={() => {}}
              selectedInput={{
                id: CriticalMetricGoalInput.GlobalOnly,
                value: CriticalMetricGoalInput.GlobalOnly,
              }}
              onUpdate={async (data: any) => Promise.resolve()}
              onLockedFieldClick={() => {}}
            />
          </div>
        </Section>
        <Section>
          <ButtonPill iconName="Plus" text="Add group" onClick={() => {}} />
          <ButtonPill
            iconName="Plus"
            text="Create group"
            level="secondary"
            onClick={() => {}}
          />
          <ButtonPill
            iconName="Plus"
            text="Add note"
            level="note"
            onClick={() => {}}
          />
          <ButtonPill
            iconName="Arrow-up"
            text="Add advantage"
            level="advantage"
            onClick={() => {}}
          />
          <ButtonPill
            iconName="Arrow-down"
            text="Add limitation"
            level="limitation"
            onClick={() => {}}
          />
          <ButtonPill
            iconName="Export"
            text="Export"
            level="export"
            onClick={() => {}}
          />
          <ButtonPill
            loading
            iconName="Wave segment"
            text="Export"
            level="export"
            onClick={() => {}}
          />
        </Section>
        <Section>
          <ButtonRound
            iconName="Observation"
            level="primary"
            onClick={() => {}}
          />
          <ButtonRound
            iconName="Add patient"
            level="secondary"
            size="large"
            onClick={() => {}}
          />
          <ButtonRound
            iconName="Trash"
            level="secondary"
            size="small"
            tooltip="Delete group"
            onClick={() => {}}
          />
          <ButtonRound
            iconName="Trash"
            level="secondary"
            size="small"
            disabled
            tooltip="Group must be empty to delete"
            onClick={() => {}}
          />
        </Section>

        <Section>
          <ButtonNote level={PostItCardType.Advantage} />
          <ButtonNote level={PostItCardType.Adaptation} />
          <ButtonNote level={PostItCardType.Limitation} />
          <ButtonNote level={PostItCardType.Pattern} />
          <ButtonNote level={PostItCardType.Trigger} />
          <ButtonNote level={PostItCardType.Unexpected} />
          <ButtonNote level={PostItCardType.Other} text="e.t.c" />
        </Section>
        <Section>
          <ButtonPillExpand
            text="Add note"
            setOpen={setButtonOpen}
            open={buttonOpen}
          >
            <ButtonNote level={PostItCardType.Advantage} />
            <ButtonNote level={PostItCardType.Limitation} />
          </ButtonPillExpand>

          <ButtonPillExpand
            text="Add note"
            setOpen={setButtonOpen}
            open={buttonOpen}
          >
            <ButtonNote level={PostItCardType.Trigger} />
            <ButtonNote level={PostItCardType.Unexpected} />
            <ButtonNote level={PostItCardType.Adaptation} />
            <ButtonNote level={PostItCardType.Pattern} />
          </ButtonPillExpand>

          <ButtonPillExpand
            text="Add"
            setOpen={setButtonOpen}
            open={buttonOpen}
          >
            <ButtonNoteCardType
              onClick={() => {}}
              text="Image"
              icon={<Icon name="Image" size={30} color="black" />}
            />
            <ButtonNoteCardType
              onClick={() => {}}
              text="Note"
              icon={<Icon name="Text" size={30} color="black" />}
            />
          </ButtonPillExpand>

          <ButtonRoundExpand setOpen={setPillButtonOpen} open={pillButtonOpen}>
            <ButtonNote level={PostItCardType.Trigger} />
            <ButtonNote level={PostItCardType.Unexpected} />
            <ButtonNote level={PostItCardType.Adaptation} />
            <ButtonNote level={PostItCardType.Pattern} />
          </ButtonRoundExpand>
        </Section>
        <Section>
          {countries.map((country, index) => {
            const { name } = country;
            if (index < 10) {
              return (
                <div key={name + index} style={{ marginRight: 10 }}>
                  <CountryFlag
                    size="sm"
                    user={{ ...((user as unknown) as User), country: name }}
                  />
                  <CountryFlag
                    size="lg"
                    user={{ ...((user as unknown) as User), country: name }}
                  />
                </div>
              );
            } else return null;
          })}
        </Section>
        <Section>
          <Heading1>H1</Heading1>
          <Heading2>H2</Heading2>
          <Heading3>H3</Heading3>
          <Subtitle1>Subtitle 1</Subtitle1>
          <Subtitle2>Subtitle 2</Subtitle2>
          <BodyExtraLarge>Body Extra Large</BodyExtraLarge>
          <BodyLarge>Body Large</BodyLarge>
          <BodyNormal>Body Normal</BodyNormal>
          <BodySmall>Body Small</BodySmall>
          <ButtonLabel>Button</ButtonLabel>
          <Caption>Caption</Caption>
        </Section>
        <Section>
          <Avatar
            size={80}
            src="/Patient_image.png"
            tooltip="Men and women > 40"
          />
        </Section>
        <Section>
          <div
            style={{
              background: colors.black,
              padding: '10px',
              width: '100%',
            }}
          >
            <TabGroup componentName="CategoryTab">
              <>
                {[...tabs, ...tabs, ...tabs, ...tabs, ...tabs].map(
                  (val, index) => {
                    return (
                      <div
                        key={val.id + index}
                        style={{ display: 'inline-block', padding: '0 2px' }}
                      >
                        <CategoryTab
                          text={val.label}
                          active={val.id + index === activeTabId}
                          onClick={() => {
                            setActiveTabId(val.id + index);
                          }}
                        />
                      </div>
                    );
                  }
                )}
              </>
            </TabGroup>
            <br />
            <TabGroup componentName="StepTab">
              <>
                {[...tabs2, ...tabs2, ...tabs2, ...tabs2, ...tabs2].map(
                  (val, index) => {
                    return (
                      <div
                        key={val.id + index}
                        style={{ display: 'inline-block', padding: '0 2px' }}
                      >
                        <StepTab
                          text={val.label}
                          active={val.id + index === activeTabId}
                          onClick={() => {
                            setActiveTabId(val.id + index);
                          }}
                        />
                      </div>
                    );
                  }
                )}
              </>
            </TabGroup>
            <br />
            <TabGroup componentName="ContributionTab">
              <>
                {[...tabs3, ...tabs3, ...tabs3, ...tabs3, ...tabs3].map(
                  (val, index) => {
                    return (
                      <div
                        key={val.id + index}
                        style={{ display: 'inline-block', padding: '0 2px' }}
                      >
                        <ContributionTab
                          id={'0'}
                          text={val.label}
                          active={val.id + index === activeTabId}
                          onClick={() => {
                            setActiveTabId(val.id + index);
                          }}
                          secondary={val.secondary}
                          displaySummary={() => null}
                          sidebarOpen={false}
                        />
                      </div>
                    );
                  }
                )}
              </>
            </TabGroup>
          </div>
        </Section>
        <Section>
          <Section column>
            <TextInput value="Lorem ipsum" onChange={() => {}} />
            <TextInput value="Lorem ipsum" onChange={() => {}} disabled />
            <TextInput value="Lorem ipsum" onChange={() => {}} invalid />
          </Section>
          <Section column>
            <TextInput borderless value="Lorem ipsum" onChange={() => {}} />
            <TextInput
              borderless
              value="Lorem ipsum"
              onChange={() => {}}
              disabled
            />
          </Section>
        </Section>
        <Section>
          <Icon name="Action" color={colors.purple} size={30} />
          <Icon name="Add patient" color={colors.purple} size={30} />
          <Icon name="Arrow-down" color={colors.purple} size={30} />
          <Icon name="Arrow-right" color={colors.purple} size={30} />
          <Icon name="Arrow-up" color={colors.purple} size={30} />
          <Icon name="Attachment" color={colors.purple} size={30} />
          <Icon name="Back" color={colors.purple} size={30} />
          <Icon name="Chevron-down" color={colors.purple} size={30} />
          <Icon name="Chevron-up" color={colors.purple} size={30} />
          <Icon name="Close" color={colors.purple} size={30} />
          <Icon name="Collapse right" color={colors.purple} size={30} />
          <Icon name="Comment" color={colors.purple} size={30} />
          <Icon name="Dollar" color={colors.purple} size={30} />
          <Icon name="Export" color={colors.purple} size={30} />
          <Icon name="Files" color={colors.purple} size={30} />
          <Icon name="HCP" color={colors.purple} size={30} />
          <Icon name="Help" color={colors.purple} size={30} />
          <Icon name="Home" color={colors.purple} size={30} />
          <Icon name="Jobs" color={colors.purple} size={30} />
          <Icon name="Lightning" color={colors.purple} size={30} />
          <Icon name="Lock" color={colors.purple} size={30} />
          <Icon name="Menu" color={colors.purple} size={30} />
          <Icon name="Notifications OFF" color={colors.purple} size={30} />
          <Icon name="Notifications ON" color={colors.purple} size={30} />
          <Icon name="Notification" color={colors.purple} size={30} />
          <Icon name="Observation" color={colors.purple} size={30} />
          <Icon name="Patient" color={colors.purple} size={30} />
          <Icon name="People" color={colors.purple} size={30} />
          <Icon name="Plus" color={colors.purple} size={30} />
          <Icon name="Strategy" color={colors.purple} size={30} />
          <Icon name="Thumbs up Active" color={colors.purple} size={30} />
          <Icon name="Thumbs up" color={colors.purple} size={30} />
          <Icon name="Tick" color={colors.purple} size={30} />
          <Icon name="Touchpoint" color={colors.purple} size={30} />
          <Icon name="Trash" color={colors.purple} size={30} />
          <Icon name="Treatment" color={colors.purple} size={30} />
          <Icon name="Trigger" color={colors.purple} size={30} />
          <Icon name="Upload" color={colors.purple} size={30} />
        </Section>
        <StepButtonWrapper>
          <StepButton
            tooltip="Previous step: Level of Satisfaction"
            direction="l"
          />
          <StepButton tooltip="Next step: Target Patient" direction="r" />
          <StepButton direction="r" disabled />
        </StepButtonWrapper>
        <FormFieldSection>
          <FormDropdown
            title="Strings"
            name="strings"
            placeholder="Placeholder Text"
            onClick={() => setDropdownActive(!dropdownActive)}
            dropdownActive={dropdownActive}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setDropdownActive={setDropdownActive}
            options={[
              'Option A',
              'Option B',
              'Option C',
              'Option D',
              'Option E',
              'Option F',
              'Option G',
              'Option H',
              'Option I',
            ]}
            disabled={formFieldsDisabled}
            errorMessage={formFieldsErrorMessage}
          />

          <FormCountryDropdown
            title="Countries"
            name="countries"
            placeholder="Placeholder Text"
            onClick={() => setCountryDropdownActive(!countryDropdownActive)}
            dropdownActive={countryDropdownActive}
            selectedItem={selectedCountry}
            setSelectedItem={setSelectedCountry}
            setDropdownActive={setCountryDropdownActive}
            options={[...countryArr] as Country[]}
            disabled={formFieldsDisabled}
            errorMessage={formFieldsErrorMessage}
          />
          <FormCountryDropdownSearch
            title="Countries"
            name="countries"
            placeholder="Placeholder Text"
            onClick={() => setCountryDropdownActive(!countryDropdownActive)}
            dropdownActive={countryDropdownActive}
            selectedItem={selectedCountry}
            setSelectedItem={(val) => setSelectedCountry(val)}
            setDropdownActive={setCountryDropdownActive}
            options={[...countryArr] as Country[]}
            disabled={formFieldsDisabled}
            errorMessage={formFieldsErrorMessage}
          />

          <FormTextInput
            name="email"
            title="Email Address"
            onChange={(e) => setEmailAddress(e.target.value)}
            type="email"
            value={emailAddress}
            disabled={formFieldsDisabled}
            errorMessage={formFieldsErrorMessage}
          />

          <FormPasswordInput
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            title="Password"
            value={password}
            disabled={formFieldsDisabled}
            errorMessage={formFieldsErrorMessage}
          />
          <button onClick={() => setFormFieldsDisabled(!formFieldsDisabled)}>
            {formFieldsDisabled ? 'Enable' : 'Disable'} form fields
          </button>
          <button onClick={toggleFormFieldError}>
            {formFieldsErrorMessage
              ? 'Remove error state from'
              : 'Apply error state to'}{' '}
            form fields
          </button>
        </FormFieldSection>

        <Section style={{ background: 'white', padding: 50 }}>
          <StrategyDefinitionHeader
            type={StrategicQuestionType.Text}
            collaboration={{
              id: 1,
              alignmentCount: 10,
              commentCount: 1,
              attachmentCount: 1,
            }}
            totalUsers={20}
            isLead={false}
            title={'Winning aspiration'}
            textValue={
              'To change the way people  - think, feel, and their expectations - about treatment options, and the disease.'
            }
            handleSave={async () => {}}
          />
        </Section>
        <Section style={{ background: 'white', padding: 50 }}>
          <div style={{ width: 60 }}>
            <CircularProgressBar progress={20} />
          </div>
        </Section>
      </Content>
    </Page>
  );
};
