import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Link, useLocation, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { colors, colorsv2, texts, themes } from 'constants/index';
import { useAuthContext } from 'contexts/AuthContext';
import apolloClient from 'data/apollo';
import { BodySmall, ButtonRound, Icon, Subtitle1, Subtitle2 } from './shared';
import buildStrategyURL from 'utils/buildStrategyURL';
import { zIndex } from 'constants/index';
import { ExcludedFeature } from 'types';
import { 
  useDrugsQuery, 
  useSignOutMutation, 
  useDrugQuery, 
  useStrategyQuery, 
  useStakeholderDefinitionsQuery
} from 'data/graphql/generated';
import getInitials from 'utils/getInitials';
import { NotificationBell } from './notifications/NotificationBell';
import { checkIfFullNmblr } from 'containers/Strategies';
import { PageNav } from 'components/PageNav';
import useMobile from 'hooks/useMobile';
import _ from 'lodash';
import { device } from 'utils/breakpoints';
import { useWidth } from 'hooks/useWidth';
import useTablet from 'hooks/useTablet';
import { toCss } from 'utils/toCss';
import * as themeUtils from 'utils/themes';

const TermsAndPolicies = styled(Subtitle2)`
  align-items: center;
  color: ${colors.white};
  display: flex;
  height: 40px;
  padding-left: 10px;

  cursor: pointer;
  pointer-events: all;

  a {
    color: inherit;
  }

  &:hover {
    background: ${colorsv2.bg.layerHover}; 
  }
`;

const ProfileIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  height: 40px;
  padding-left: 8px;

  cursor: pointer;
  pointer-events: all;
  &:hover {
    background: ${colorsv2.bg.layerHover}; 
  }
`;

const Mobile = styled.div<{ isAdmin: boolean }>`
  display: ${({ isAdmin }) => (isAdmin ? 'none' : 'block')};

  // @media screen and (min-width: 945px) {
  //   display: none;
  // }
`;

const Content = styled.div`
  background: ${colorsv2.bg.surfaceBase};
  position: fixed;
  display: block;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 48px;
  text-align: center;
  z-index: ${zIndex.nav};

  &::after {
    content: '';
    clear: both;
    display: block;
  }
`;

const Logo = styled.div`
  height: 100%;
  display: grid;
  place-content: center;
  position: absolute;
  left: 50%;
  width: 50px;
  transform: translateX(-50%);
  a {
    display: inline-block;
    vertical-align: top;
  }
`;

const SidebarTrigger = styled.div`
  display:flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-left: 4px;
  place-content: center;
  vertical-align: top;
  float: left;
  cursor: pointer;

  @media ${device.mobile} {
    max-width: calc(100% - 60px);
  }
`;

const SidebarIcon = styled.div`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  width: 40px;
`;

const SideMenu = styled.nav<{ visible: boolean }>`
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1),
    0px 11px 11px 0px rgba(0, 0, 0, 0.09),
    0px 25px 15px 0px rgba(0, 0, 0, 0.05),
    0px 44px 18px 0px rgba(0, 0, 0, 0.01),
    0px 69px 19px 0px rgba(0, 0, 0, 0);

  bottom: 10px;
  border-radius: 8px;
  background: ${colorsv2.bg.layer};
  display: flex;
  flex-direction: column;
  left: ${({ visible }) => (visible ? '10px' : '0px')};
  justify-content: space-between;
  overflow: hidden;
  position: fixed;
  transform: translate(${({ visible }) => (visible ? 0 : '-100%')}, 0);
  transition: transform 0.5s;
  text-align: left;
  top: 60px;
  width: 350px;
  z-index: ${zIndex.navSideMenu};

  @media ${device.mobile} {
    border-radius: 0;
    top: 48px;
    left: 0px;
    bottom: 0px;
    width: 100%;
  }
`;

const SideMenuBG = styled.div<{ visible: boolean }>`
  opacity: 0.95;
  position: fixed;
  top: 50px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: left;
  z-index: ${zIndex.navSideMenuBG};
  transform: translate(${({ visible }) => (visible ? 0 : '-100%')}, 0);
  transition: transform 0.3s;
  cursor: pointer;
`;

const SideMenuFooter = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Signout = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding-left: 10px;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  &:hover {
    background: ${colorsv2.bg.layerHover}; 
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
  padding: 15px 23px;
`;

const Section = styled.div`
`;

const SectionTitle = styled.div<{ isOpen: boolean }>`
  ${() => toCss(texts.button100)}
  display: flex;
  align-items: center;
  background: ${colorsv2.bg.layer};
  height: 40px;
  cursor: pointer;
  color: ${colorsv2.textInverted.default};
 
  &:hover {
    background: ${colorsv2.bg.layerHover}; 
  }
`;

const Submenu = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  overflow: hidden;
  background: ${colorsv2.bg.surfaceMedium};

  a:last-child {
    border-bottom: 0 !important;
  }
`;

const MenuItem = styled(Link) <{ active?: boolean }>`
  ${() => toCss(texts.button100)}
  background: ${({ active }) => (active ? colorsv2.bg.brand : colorsv2.bg.surfaceMedium)};
  border-bottom: 1px solid ${colorsv2.border.medium};
  display: block;
  color: ${({ active }) => (active ? colorsv2.textInverted.default : colorsv2.text.default)};
  height: 40px;
  line-height: 1;
  overflow: hidden;
  padding: 11px 10px 11px 0px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background: ${({ active }) => (active ? colorsv2.bg.brand : colorsv2.bg.surfaceStrong)};
  }
`;

const CurrentPageWrapper = styled.div`
  display: flex;
  height: 100%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
`;

const MenuLink = styled(Link) <{ active?: boolean }>`
  ${() => toCss(texts.button100)}
  align-items: end;
  background: ${({ active }) => (active ? colorsv2.bg.brand : colorsv2.bg.layer)};
  display: flex;
  height: 40px;
  padding: 8px;
  gap: 8px;

  p {
    line-height: 21px;
  }

  ${({ active }) => !active && `
    &:hover {
      background: ${colorsv2.bg.layerHover}
    }
  `};
`;

const Logo2 = styled.div`
  float: left;
  height: 100%;
  width: 60px;
  display: inline-block;
  overflow: hidden;

  a {
    width: 60px;
    height: 100%;
    display: grid;
    vertical-align: top;
    place-content: center;
  }
`;

const TitleWrapper = styled.div<{ maxWidth?: number }>`
  display: flex;
  gap: 5px;
  margin: 1px 0 0 4px;
  max-width: ${({ maxWidth }) => maxWidth || 300}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  p:last-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const NotificationWrapper = styled.div`
  margin-right: 4px;

  &::after {
    content: '';
    display: block;
    clear: both;
  }
`;

interface Props {
  title?: string;
  hideArrows: boolean;
  drugId: string;
  strategyId: string;
  navMenuChildren?: React.ReactNode | null;
  exclude?: ExcludedFeature[];
  showNotifications(): void;
  notificationsVisible: boolean;
  notificationsUnread: number;
  showAccountModal(): void;
  mobileNavRef?: React.RefObject<HTMLDivElement>;
}

export const NavbarMobile: React.FC<Props> = ({
  title,
  drugId,
  hideArrows,
  strategyId,
  exclude,
  showNotifications,
  notificationsVisible,
  notificationsUnread,
  showAccountModal,
  mobileNavRef,
}) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const width = useWidth();
  const [auth, setAuth] = useAuthContext();
  const [signOut] = useSignOutMutation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const strategyHome = buildStrategyURL(drugId, strategyId, '');
  const peoplePage = buildStrategyURL(drugId, strategyId, 'people');
  const welcomeHome = buildStrategyURL(drugId, strategyId, 'welcome');
  const isLandingPage = !!pathname.match(/^\/$/g);
  const isAuthPage = pathname.startsWith('/signin') || pathname.startsWith('/signup');
  const isAdminPage = !!pathname.match(/^\/admin(\/.*)?$/g) || pathname.includes('/blocks');
  const isWelcomePage = !!pathname.match(/^\/d\/\d+\/strategy\/\d+\/welcome(\/)?$/);
  const isCreatePeriod = !!pathname.match(/^\/d\/\d+\/strategy\/\d+\/create\/.*$/);
  const isCollaboratorPage = !!pathname.match(/^\/d\/\d+\/strategy\/\d+\/people$/);
  const stepNumber = pathname.split('/').find((p) => p.includes('_')) || '';
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [activeBlock, setActiveBlock] = useState<string | null>(null);

  const {
    data: drug,
    loading: drugLoading
  } = useDrugQuery({
    variables: { id: Number(drugId) },
  });

  const {
    data,
    loading: strategyLoading,
  } = useStrategyQuery({
    variables: { id: strategyId ? Number(strategyId) : 0 },
  });

  const { 
    data: drugData,
    loading: drugDataLoading
  } = useDrugsQuery({
    variables: {
      where: {
        id: Number(drugId),
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: stakeholderDefinitions,
    loading: stakeholderDefinitionsLoading,
  } = useStakeholderDefinitionsQuery({
    fetchPolicy: 'network-only',
    variables: { 
      where: { 
        id: data?.strategy?.stakeholderDefinitionId || 0, 
        strategyId: +strategyId || 0 
      } 
    },
  });

  const initials = getInitials(auth?.user?.name);
  const possibleDrugs = drugData?.drugs?.items;
  const ProductOnDrugs = themeUtils.sortProductOnDrugs(
    drugData?.drugs?.items?.filter(
      (e) => e.id === parseInt(drugId)
    )[0]?.ProductOnDrugs
  );
  
  const miniFeatureSet = useMemo(() => {
    if (possibleDrugs) {
      return possibleDrugs.filter(i => i.id === Number(drugId)).flatMap((p: any) =>
        p.ProductOnDrugs?.flatMap((f: any) =>
          f.Product?.features?.map((g: any) => g.featureSet)
        )
      );
    }
    return [];
  }, [drugId, possibleDrugs]);

  const isFullNmblr = checkIfFullNmblr(miniFeatureSet);
  const isMenuItemActive = (pathname: string, targetUrl: string): boolean => pathname.includes(targetUrl);
  const titleMaxWidth = hideArrows ? Math.floor(width / 2 - 220) : Math.floor(width / 2 - 250);
  const drugName = drug?.drug?.name;

  const targetPopulation = stakeholderDefinitions?.stakeholderDefinitions?.items?.filter(
    (e) => e.id === data?.strategy?.stakeholderDefinitionId
  )[0]?.title || null

  const buildURL = (page: string) => `/d/${drugId}/strategy/${strategyId}/${page}`;
  const isLoading = drugLoading || strategyLoading || drugDataLoading || stakeholderDefinitionsLoading;

  const isPatternMatch = (pathname: string) => {
    const regex = /^\/d\/\d+\/strategy\/\d+(\/)?$/;
    return regex.test(pathname);
  };

  const renderSidebarTrigger = () => {
    if (exclude?.includes('sidebarTrigger')) {
      return null;
    }

    const companyName = auth?.user?.companyName;
    const isGeneral = (
      isLandingPage
      || isCreatePeriod
    );

    const showCompany = (
      !isLoading
      && !isMobile
      && !isTablet
      && isGeneral
      && companyName
    );

    const showTargetPopulation = (
      !isLoading
      && !isMobile
      && !isTablet
      && !isGeneral
      && targetPopulation
    );

    const showToolName = (
      !isLoading
      && (isMobile || isTablet)
      && stepNumber
    );

    let titleEl = null, titlePopup = '', maxWidth = titleMaxWidth;
    if (showCompany) {
      titleEl = (
        <BodySmall
          color={colorsv2.textInverted.default}
          wordWrap="unset"
        >
          {companyName}
        </BodySmall>
      );

      titlePopup = companyName;
    } else if (showTargetPopulation) {
      titleEl = (
        <>
          <BodySmall 
            color={colorsv2.textInverted.weak} 
            wordWrap="unset"
          >
            {drugName}
          </BodySmall>
          <span style={{ color: colorsv2.textInverted.weak }}>/</span>
          <BodySmall 
            color={colorsv2.textInverted.default} 
            wordWrap="unset"
          >
            {targetPopulation}
          </BodySmall>
        </>
      );

      titlePopup = `${drugName} / ${targetPopulation}`;
      console.log(maxWidth);
    } else if (showToolName) {
      const toolTitle = themeUtils.getThemeTool(stepNumber.replace('_', '.'));

      titleEl = (
        <Subtitle1 color={colorsv2.textInverted.default}>
          {toolTitle.name}
        </Subtitle1>
      );

      titlePopup = toolTitle.name as string;
      maxWidth = 0;
    }
    
    return (
      <SidebarTrigger onClick={() => setDropdownVisible(!dropdownVisible)}>
        {isMobile && dropdownVisible ? (
          <SidebarIcon>
            <Icon name="Close" color={colors.white} size={24} />
          </SidebarIcon>
        ) : (
          <SidebarIcon>
            <Icon name="Menu" color={colors.white} size={24} />
          </SidebarIcon>
        )}

        {titleEl && (
          <TitleWrapper maxWidth={maxWidth} title={titlePopup}>
            {titleEl}
          </TitleWrapper>
        )}
      </SidebarTrigger>
    )
  }

  const renderLogoOrTitle = () => {
    const showSmallLogo = (
      isMenuItemActive(pathname, 'terms/terms-of-service')
      || isMenuItemActive(pathname, '/terms/privacy-policy')
    );

    const showLogo = (
      isAuthPage
      || isLandingPage
      || isWelcomePage
      || isCreatePeriod
      || isPatternMatch(pathname)
      || isMobile
      || isTablet
    ) && !isCollaboratorPage && !stepNumber;

    const showTitle = (
      isCollaboratorPage
      || (stepNumber && !isMobile && !isTablet)
    );

    if (showSmallLogo) {
      return (
        <Logo2>
          <Link to="/">
            <Icon name="Home" color={colorsv2.bg.surfaceMedium} size={30} />
          </Link>
        </Logo2>
      );
    } else if (showLogo) {
      return (
        <Logo>
          <Link to="/">
            <Icon name="FullLogoWhite" color={colorsv2.bg.surfaceMedium} size={102} />
          </Link>
        </Logo>
      );
    } else if (showTitle) {
      return (
        <CurrentPageWrapper>
          <PageNav
            hideArrows={hideArrows}
            title={title}
          />
        </CurrentPageWrapper>
      );
    }

    return null;
  };

  useEffect(() => {
    if (!isFullNmblr && activeBlock && !isWelcomePage) {
      setActiveSection(activeBlock);
    } else {
      const themeKey = themeUtils.getThemeKey(stepNumber);
      setActiveSection(themeKey);
    }
  }, [
    pathname, 
    activeBlock, 
    stepNumber,
    isFullNmblr,
    isWelcomePage
  ]);

  useEffect(() => {
    const search = new URLSearchParams(history.location.search);
    let block = search.get('block');
    if (!block) {
      // try to get from local storage
      const localBlock = themeUtils.getActiveBlockCache()
      if (localBlock) {
        block = localBlock;
      }

      if (!block) {
        return;
      }
    }

    setActiveBlock(block);
    themeUtils.setActiveBlockCache(block);
  }, [history.location.search]);

  return (
    <Mobile isAdmin={isAdminPage && auth?.user?.role === 'ADMIN'}>
      <Content ref={mobileNavRef} className="main__mobileNav">
        {renderSidebarTrigger()}
        {renderLogoOrTitle()}

        {!exclude?.includes('notifications') && (
          <NotificationWrapper>
            <NotificationBell
              onClick={showNotifications}
              visible={notificationsVisible}
              notificationsUnread={notificationsUnread}
            />
          </NotificationWrapper>
        )}
      </Content>

      <SideMenu visible={dropdownVisible}>
        <div>
          {(!isLandingPage || !isCreatePeriod) && (
            <>
              {!isFullNmblr && ProductOnDrugs && (
                ProductOnDrugs.map((drug: any, i: number) => (
                  <Section key={i}>
                    <SectionTitle
                      isOpen={activeSection === drug?.block?.key}
                      onClick={() => setActiveSection(
                        activeSection === drug?.block?.key ? null : drug?.block?.key
                      )}
                    >
                      <Icon 
                        name={
                          activeSection === drug?.block?.key 
                          ? "Chevron-down-full" 
                          : "Chevron-right-full"
                        } 
                        size={24} 
                        width={40}
                        color={colors.white} 
                      />
                      <span>{drug?.Product?.productName}</span>
                    </SectionTitle>
                    <Submenu isOpen={activeSection === drug?.block?.key}>
                      {_.sortBy(drug.Product?.features, ['featureSet']).map(
                        (d: any) => (
                          <MenuItem 
                            key={d.featureSet}
                            to={buildURL(d.featureSet.replace('.', '_')) + `?block=${drug?.block?.key}`} 
                            active={isMenuItemActive(
                              pathname, 
                              `/${d.featureSet.replace('.', '_')}`
                            ) && activeBlock === drug?.block?.key}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                textAlign: 'center',
                                width: 40
                              }}
                            ></span>
                            {themes?.[themeUtils.getThemeKey(d.featureSet) as any]?.tools?.[d.featureSet]?.name}
                          </MenuItem>
                        )
                      )}
                    </Submenu>
                  </Section>
                ))
              )}

              {isFullNmblr && (
                <>
                  {Object.keys(themes).map((key) => (
                    <Section key={key}>
                      <SectionTitle
                        isOpen={activeSection === themes[key].key}
                        onClick={() => setActiveSection(
                          activeSection === themes[key].key ? null : themes[key].key
                        )}
                      >
                        <Icon
                          name={
                            activeSection === themes[key].key
                            ? "Chevron-down-full" 
                            : "Chevron-right-full"
                          } 
                          size={24} 
                          width={40}
                          color={colorsv2.textInverted.default}
                        />

                        <span>{themes[key].label}</span>
                      </SectionTitle>

                      <Submenu key={`${key}_${themes[key].key}`} isOpen={activeSection === themes[key].key}>
                        {Object.keys(themes[key].tools).map((toolKey) => (
                          <MenuItem 
                            key={toolKey}
                            to={buildURL(toolKey.replace('.', '_'))} 
                            active={isMenuItemActive(pathname, `/${toolKey.replace('.', '_')}`)}
                          >
                            <span
                              style={{
                                display: 'inline-block',
                                textAlign: 'center',
                                width: 40
                              }}
                            >{toolKey}</span>
                            {themes[key].tools[toolKey].name}
                          </MenuItem>
                        ))}
                      </Submenu>
                    </Section>
                  ))}
                </>
              )}
            </>
          )}
        </div>

        <SideMenuFooter>
          {!isLandingPage && !isAdminPage && (
            <>
              <MenuLink 
                active={isPatternMatch(pathname)} 
                to={drugId && strategyId && isFullNmblr ? strategyHome : drugId && strategyId && !isFullNmblr ? welcomeHome : '/'}
              >
                <Icon name="Grid" color={colors.white} size={24} />
                <Subtitle2 color={colors.white}>Tools</Subtitle2>
              </MenuLink>

              <MenuLink active={isCollaboratorPage} to={drugId && strategyId ? peoplePage : '/'}>
                <Icon name="Users" color={colors.white} size={24} />
                <Subtitle2 color={colors.white}>Collaborators</Subtitle2>
              </MenuLink>

              <MenuLink active={isLandingPage} to='/'>
                <Icon name="HomeIcon" color={colors.white} size={24} />
                <Subtitle2 color={colors.white}>Home</Subtitle2>
              </MenuLink>
            </>
          )}

          {isAdminPage && (
            <>
              <MenuLink to={'/admin'}>
                <Icon name="People" color={colors.white} size={24} />
                <Subtitle2 color={colors.white}>Company</Subtitle2>
              </MenuLink>

              <MenuLink to={'/blocks'}>
                <Icon name="Product" color={colors.white} size={24} />
                <Subtitle2 color={colors.white}>Products</Subtitle2>
              </MenuLink>
            </>
          )}

          <ProfileIconWrapper
            onClick={() => {
              showAccountModal();
              setDropdownVisible(false);
            }}
          >
            <ButtonRound text={initials} level="primary" size={24} />
            <Subtitle2 color={colors.white}>Account</Subtitle2>
          </ProfileIconWrapper>

          <TermsAndPolicies>
            <a href="/terms/terms-of-service" target="_blank">
              Terms and Policies
            </a>
          </TermsAndPolicies>

          <Signout
            onClick={async () => {
              await signOut();
              setDropdownVisible(false);
              history.push('/signin');
              setAuth({
                loading: false,
                user: undefined,
              });
              apolloClient.clearStore();
              Sentry.configureScope((scope) => scope.setUser(null));
            }}
          >
            <Subtitle2 color={colors.white}>Sign out</Subtitle2>
          </Signout>
        </SideMenuFooter>
      </SideMenu>

      <SideMenuBG
        onClick={() => setDropdownVisible(false)}
        visible={dropdownVisible}
      >
        <CloseBtn onClick={() => setDropdownVisible(false)}>
        </CloseBtn>
      </SideMenuBG>
    </Mobile >
  );
};