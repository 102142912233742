import React, { useContext } from 'react';
import * as Sentry from '@sentry/browser';
import { Formik } from 'formik';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { prettyGraphqlError } from '../../utils/prettyGraphqlError';
import { AuthContext } from '../../contexts/AuthContext';
import { useSignInMutation } from 'data/graphql/generated';
import {
  ErrorBox,
  ErrorMessage,
  Form,
  Title,
  SubmitButton,
  Signin,
} from '../../containers/Signin';
import FormPasswordInput from 'components/shared/FormPasswordInput';
import FormTextInput from 'components/shared/FormTextInput';
import { ButtonLabel } from 'components/shared';

const ForgotPasswordButton = styled(ButtonLabel)`
`;

const ResetPasswordLink = styled(Link)`
  display: inline;
  color: inherit;
  font-weight: 500;
  font-size: 14px;
`;

const formatErrorMessage = (message: string) => {
  if (
    message ===
    'The username or password is incorrect. Please try again, or reset your password.'
  ) {
    return (
      <>
        <ErrorMessage>
          The username or password is incorrect. Please try again, or{' '}
        </ErrorMessage>
        <ResetPasswordLink to="/signin/forgot-password">
          reset your password.
        </ResetPasswordLink>
      </>
    );
  } else {
    return <ErrorMessage>{message}</ErrorMessage>;
  }
};

export default function SigninPage() {
  const [auth, setAuth] = useContext(AuthContext);
  const [signin] = useSignInMutation();
  const history = useHistory();

  return (
    <Signin>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          try {
            const res = await signin({
              variables: {
                email: values.email.toLowerCase(),
                password: values.password,
              },
            });

            const signInData = get(res, 'data.signIn');

            setAuth({
              user: signInData.user,
            });
            Sentry.setUser({ email: signInData.user.email });
            if (signInData?.user?.role === 'ADMIN') {
              history.push('/admin');
              // NOTE this should match what's in AuthRedirect
            } else if (
              auth.redirectPath &&
              !auth.redirectPath.includes('/signin')
            ) {
              history.push(auth.redirectPath);
            } else {
              history.push('/');
            }
          } catch (err) {
            console.error(err);
            Sentry.captureException(err);
            actions.setStatus({
              error: prettyGraphqlError(err as Error),
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, handleSubmit, handleChange, status, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Sign in to your account</Title>

            <FormTextInput
              title="Email address"
              name="email"
              type="email"
              autoFocus
              onChange={handleChange}
              value={values.email}
              className="cypress-email"
            />

            <FormPasswordInput
              title="Password"
              name="password"
              onChange={handleChange}
              value={values.password}
              className="cypress-password"
            />

            <ForgotPasswordButton
              onClick={() => history.push('/signin/forgot-password')}
              className="cypress-forgot-password-btn"
            >
              Forgot your password ?
            </ForgotPasswordButton>

            <SubmitButton
              width="100%"
              onClick={() => {}}
              disabled={isSubmitting}
              level="primary"
              text={isSubmitting ? 'Loading...' : 'Next'}
              type="submit"
              className="cypress-signin-btn"
            />

            {status && status.error ? (
              <ErrorBox>{formatErrorMessage(status.error)}</ErrorBox>
            ) : null}
          </Form>
        )}
      </Formik>
    </Signin>
  );
}
