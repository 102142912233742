import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { Link, useParams } from 'react-router-dom';
import { Navbar } from 'components/Navbar';
import {
  BodyNormal,
  BodySmall,
  StepHeaderBar,
  Subtitle2,
} from 'components/shared';
import {
  BlockType,
  LeveragePointFragment,
  Sort,
  Stakeholder,
  StakeholderDefinitionsQueryVariables,
  StrategicPossibilityUpdateInput,
  useLeveragePointsQuery,
  usePatientJourneyBlocksQuery,
  useStakeholderDefinitionsQuery,
  useStrategicPossibilityCreateMutation,
  useStrategicPossibilityDeleteMutation,
  useStrategicPossibilityUpdateMutation,
} from 'data/graphql/generated';
import { Page, Wrapper } from 'components/Page';
import { device } from 'utils/breakpoints';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { colors, polling } from 'constants/index';
import useDesktop from 'hooks/useDesktop';
import { LeveragePoint } from 'components/Options/LeveragePoint';
import { useAuthContext } from 'contexts/AuthContext';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { Stage } from 'konva/types/Stage';
import { PatientJourneyRecapSidebar } from 'components/2-3-strategic-possibilities/PatientJourneyRecapSidebar/src';

const ScrollWrapper = styled.div<{ sideBarIsOpen: boolean; offsetTop: number }>`
  width: ${({ sideBarIsOpen }) => {
    return sideBarIsOpen ? 'calc(100vw - 555px)' : '100%';
  }};
  height: ${({ offsetTop }) => `calc(100vh - ${offsetTop}px)`};
  overflow: auto;
  will-change: width;
  transition: width 0.2s ease-out;

  @media ${device.mobile} {
    width: 100%;
  }
`;

const StepHeader = styled(StepHeaderBar)<{ isDesktop: boolean }>`
  display: block;
  padding: 15px 15px 0 15px;
  border-bottom: 0.5px solid ${colors.greyMedium};
  margin-top: ${({ isDesktop }) => (isDesktop ? '50px' : '110px')};
  min-height: auto;
  overflow: visible;
`;

const LeveragePointsWrapper = styled.div<{
  sideBarIsOpen: boolean;
  currentWidth?: number | undefined;
}>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 1400px;
  position: relative;
  width: 100%;

  @media ${device.tabletMax} {
    width: 100%;
    min-width: 480px;
  }

  @media ${device.mobile} {
    width: 100vw;
    min-width: 100vw;
  }
`;

const PageWrapper = styled(Page)`
  padding: 0px 0 60px;
  overflow: visible; // for the dropdown components

  margin-top: 15px;

  @media ${device.tabletMin} {
    margin-top: 15px;

    ${Wrapper} {
      width: auto;
      padding: 0 15px;
    }
  }

  @media ${device.desktopMin} {
    margin-top: 20px;

    ${Wrapper} {
      padding: 0 20px;
    }
  }
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  stakeholderDefinitionId: string;
}

export const StrategicPossibilities = () => {
  const {
    drugId,
    strategyId,
    stakeholderDefinitionId,
  }: URLParams = useParams();
  const [{ user }] = useAuthContext();
  const isDesktop = useDesktop();
  const [strategicPossibilityCreate] = useStrategicPossibilityCreateMutation();
  const [strategicPossibilityUpdate] = useStrategicPossibilityUpdateMutation();
  const [strategicPossibilityDelete] = useStrategicPossibilityDeleteMutation();

  const [openSidebar, setOpenSidebar] = useState(false);
  const scrollWrapperRef = useRef<HTMLDivElement | null>(null);
  const [scrollWrapperOffsetTop, setScrollWrapperOffsetTop] = useState(0);

  useEffect(() => {
    if (scrollWrapperRef.current?.offsetTop) {
      setScrollWrapperOffsetTop(scrollWrapperRef.current?.offsetTop);
    }
  }, [scrollWrapperRef.current?.offsetTop]);

  const stakeholderDefinitionsQueryVars: StakeholderDefinitionsQueryVariables = {
    where: {
      stakeholder: Stakeholder.Patient,
      strategyId: +strategyId,
    },
    orderBy: { createdAt: Sort.Asc },
    hasImageOrTitle: true,
  };

  const leveragePointsWrapper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Fixes jumping of content when sidebar is opened

    const wrapper = leveragePointsWrapper.current?.style;
    const offsetWidth = leveragePointsWrapper.current?.offsetWidth;

    // get and assign current width before transition begins
    if (offsetWidth && !!wrapper && !!isDesktop) {
      wrapper.width = offsetWidth + 'px';
    }

    const timeout = setTimeout(() => {
      const wrapper = leveragePointsWrapper.current?.style;
      if (!!wrapper && !!isDesktop) {
        // slightly after transition ends, change back to responsive values
        if (openSidebar) {
          // 40 = padding X 2
          wrapper.width = 'calc(100vw - 40px)';
        }
      }
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [openSidebar, isDesktop]);

  const {
    data: stakeholderDefinitions,
    error: stakeholderDefinitionsError,
    loading: stakeholderDefinitionsLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsQuery({
    variables: stakeholderDefinitionsQueryVars,
    fetchPolicy: 'network-only',
  });

  const {
    data: leveragePointsData,
    error: leveragePointsError,
    loading: leveragePointsLoading,
    startPolling: leveragePointsStartPolling,
    stopPolling: leveragePointsStopPolling,
    refetch: refetchLeveragePoints,
  } = useLeveragePointsQuery({
    variables: {
      where: { strategyId: +strategyId },
      include: {
        keyStakeholdersList: true,
        stakeholderDefinitions: true,
        strategicPossibilities: true,
        unlockingInsightsList: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: patientJourneyBlocksData,
    loading: patientJourneyBlocksLoading,
    error: patientJourneyBlocksError,
    startPolling: patientJourneyBlocksStartPolling,
    stopPolling: patientJourneyBlocksStopPolling,
  } = usePatientJourneyBlocksQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        type: BlockType.Step,
      },
    },
  });

  useEffect(() => {
    startPolling(polling.default);
    leveragePointsStartPolling(polling.default);
    patientJourneyBlocksStartPolling(polling.default);
    return () => {
      stopPolling();
      leveragePointsStopPolling();
      patientJourneyBlocksStopPolling();
    };
  }, [
    leveragePointsStartPolling,
    leveragePointsStopPolling,
    startPolling,
    stopPolling,
    patientJourneyBlocksStartPolling,
    patientJourneyBlocksStopPolling,
  ]);

  async function createStrategicPossibility(
    leveragePoint: LeveragePointFragment
  ) {
    try {
      await strategicPossibilityCreate({
        variables: {
          data: {
            patientFlowBlockId: leveragePoint.blockId,
            strategyId: +strategyId,
          },
        },
      });

      await refetchLeveragePoints();
    } catch (err) {
      alert(err);
    }
  }

  async function updateStrategicPossibility(
    id: number,
    data: StrategicPossibilityUpdateInput
  ) {
    await strategicPossibilityUpdate({
      variables: {
        id,
        data,
      },
    });

    await refetchLeveragePoints();
  }

  async function deleteStrategicPossibility(id: number) {
    await strategicPossibilityDelete({ variables: { id } });

    await refetchLeveragePoints();
  }

  const relevantLeveragePoints =
    leveragePointsData?.leveragePoints?.items || [];

  const noLeveragePoints = Number(relevantLeveragePoints?.length) < 1;

  const patientJourneyBlocks =
    patientJourneyBlocksData?.patientJourneyBlocks?.items;

  const stageRef = useRef<null | Stage>(null);

  return (
    <>
      <Navbar disableSecondary={isDesktop} />
      <StepHeader isDesktop={isDesktop}>
        <Subtitle2 style={{ marginBottom: '5px' }}>
          How might you win at each leverage point?
        </Subtitle2>
        <BodyNormal color={colors.greyDark} style={{ marginBottom: '15px' }}>
          Consider desired behaviours of key stakeholders that might positively
          impact the patient journey
        </BodyNormal>
      </StepHeader>
      <ScrollWrapper
        ref={scrollWrapperRef}
        offsetTop={scrollWrapperOffsetTop}
        sideBarIsOpen={openSidebar}
      >
        <ErrorWrapper
          isLoading={
            stakeholderDefinitionsLoading ||
            leveragePointsLoading ||
            patientJourneyBlocksLoading
          }
          errors={[
            stakeholderDefinitionsError || leveragePointsError,
            patientJourneyBlocksError,
          ]}
          dataMissing={
            !stakeholderDefinitions ||
            !leveragePointsData ||
            !patientJourneyBlocksData
          }
        >
          <PageWrapper paddingTop={false} fullWidthMobile>
            <PatientJourneyRecapSidebar
              isOpen={openSidebar}
              onClose={() => {
                setOpenSidebar(!openSidebar);
              }}
              sidebarStageRef={stageRef}
            />

            {noLeveragePoints ? (
              <PostItsEmpty title="Nothing to discuss">
                <BodySmall
                  color={colors.greyDark}
                  style={{ display: 'inline', marginBottom: 5 }}
                >
                  A Lead must star a leverage point in the{' '}
                </BodySmall>
                <Link
                  to={`/d/${drugId}/strategy/${strategyId}/2_2`}
                  style={{
                    color: colors.greyDark,
                    display: 'inline',
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  2.2 Patient Flow{' '}
                </Link>
                <BodySmall
                  color={colors.greyDark}
                  style={{ display: 'inline' }}
                >
                  to explore strategic possibilities
                </BodySmall>
              </PostItsEmpty>
            ) : (
              <LeveragePointsWrapper
                ref={leveragePointsWrapper}
                currentWidth={leveragePointsWrapper.current?.offsetWidth}
                sideBarIsOpen={openSidebar}
              >
                {relevantLeveragePoints.length &&
                  relevantLeveragePoints?.map((leveragePoint) => (
                    <LeveragePoint
                      key={leveragePoint?.blockId}
                      leveragePoint={leveragePoint}
                      user={user}
                      createStrategicPossibility={createStrategicPossibility}
                      updateStrategicPossibility={updateStrategicPossibility}
                      deleteStrategicPossibility={deleteStrategicPossibility}
                      stakeholderDefinitionId={Number(stakeholderDefinitionId)}
                      patientJourneyBlocks={patientJourneyBlocks || []}
                      sidebarStageRef={stageRef}
                      setOpenSidebar={setOpenSidebar}
                    />
                  ))}
              </LeveragePointsWrapper>
            )}
          </PageWrapper>
        </ErrorWrapper>
      </ScrollWrapper>
    </>
  );
};
