import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { colors, zIndex } from '../../constants';
import { Icon, Subtitle2, ButtonLabel, BodySmall } from '../shared';
import { device } from 'utils/breakpoints';
import { NotificationItem } from './NotificationItem';
import {
  NotificationFragment,
  useNotificationsUpdateMutation,
} from 'data/graphql/generated';
import { Loading } from 'components/Loading';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Background = styled.div`
  display: block;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: ${zIndex.notificationsBG};
  opacity: 0;
`;

const Wrapper = styled.div`
  @media ${device.mobile} {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    margin-top: 48px;
    height: calc(100% - 50px);
    background: ${colors.white};
    z-index: ${zIndex.notificationsBG + 1};
  }
`;

const Content = styled.div`
  display: block;
  opacity: 0;
  animation: ${fadeIn} 0.3s;
  animation-fill-mode: forwards;
  background: ${colors.white};
  padding-bottom: 10px;
  z-index: ${zIndex.notificationsFeed};
  position: relative;

  @media ${device.mobile} {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 50px);
  }

  @media ${device.tabletMin} {
    max-height: calc(100vh - 45px - 30px);
    width: 90%;
    max-width: 481px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05),
      0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 48px auto 20px auto;
    max-width: 460px;
    position: fixed;
    top: 0px;
    right: 5px;
    overflow-y: auto;
  }
`;

const MobileHeader = styled.div`
  background: ${colors.black05};
  padding: 10px 6px;
  height: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;

  @media (min-width: 480px) {
    display: none;
  }

  span {
    margin-top: 4px;
    margin-left: 2px;
  }
`;

const MobileHeaderBack = styled.div`
  transform: rotate(90deg);
  width: 30px;
  height: 30px;
  display: inline-block;
`;

const FilterBy = styled(BodySmall)`
  position: absolute;
  top: 65px;
  left: 15px;
  pointer-events: auto;
  cursor: pointer;

  @media (min-width: 480px) {
    top: 15px;
  }
`;

const Title = styled(BodySmall)`
  display: block;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  color: white;

  @media (min-width: 480px) {
    color: ${colors.greyDark};
  }
`;

const MarkAsRead = styled(BodySmall)`
  position: absolute;
  top: 65px;
  right: 15px;
  pointer-events: auto;
  cursor: pointer;

  @media (min-width: 480px) {
    top: 15px;
  }
`;

const EmptyState = styled.div`
  background: ${colors.yellow20};
  border-radius: 5px;
  height: calc(100vh - 200px);
  padding: 37px 0px 38px 0px;
  margin: 15px;
  text-align: center;
  pointer-events: none;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tabletMin} {
    height: calc(100vh - 150px);
  }
`;

interface Props {
  visible: boolean;
  loading: boolean;
  notifications: NotificationFragment[];
  refetch(): any;
  hideNotifications(): void;
}

export const NotificationsFeed: React.FC<Props> = ({
  hideNotifications,
  loading,
  notifications: allNotifications,
  refetch,
}) => {
  const [viewAll, setViewAll] = useState(true);

  const [notificationsUpdate] = useNotificationsUpdateMutation();

  const notifications = allNotifications.filter((n) => {
    return viewAll ? true : !n.read;
  });

  async function markAllAsRead() {
    await notificationsUpdate({
      variables: {
        read: notifications.filter((n) => !n.read).map((n) => n.id),
        unread: [],
      },
    });
    await refetch();
  }

  return (
    <>
      <Wrapper>
        <Content>
          <MobileHeader onClick={hideNotifications}>
            <MobileHeaderBack>
              <Icon name="Chevron-down" size={30} color={colors.black} />
            </MobileHeaderBack>
            <span>Notifications</span>
          </MobileHeader>

          <FilterBy color={colors.black}>
            {viewAll ? (
              <span onClick={() => setViewAll(false)}>Filter by unread</span>
            ) : (
              <span onClick={() => setViewAll(true)}>View all</span>
            )}
          </FilterBy>

          <Title color={colors.greyDark}>Notifications</Title>

          <MarkAsRead
            color={colors.black}
            onClick={(e) => {
              markAllAsRead();
            }}
          >
            Mark all as read
          </MarkAsRead>

          {loading ? (
            <Loading style={{ marginTop: 50 }} />
          ) : (
            <>
              {notifications.length === 0 ? (
                <EmptyState>
                  <div>
                    <Icon
                      name="NotificationEmptyState"
                      size={115}
                      height={115}
                      color="initial"
                      style={{ margin: '0 auto' }}
                    />
                    {viewAll ? (
                      <Subtitle2 color={colors.greyDark}>
                        No notifications
                      </Subtitle2>
                    ) : (
                      <>
                        <Subtitle2 color={colors.greyDark}>
                          No unread notifications
                        </Subtitle2>
                        <ButtonLabel
                          color={colors.greyDark}
                          style={{ fontWeight: 400 }}
                        >
                          Click 'View all' above to see read notifications
                        </ButtonLabel>
                      </>
                    )}
                  </div>
                </EmptyState>
              ) : (
                notifications.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    hideNotifications={hideNotifications}
                    refetch={refetch}
                  />
                ))
              )}
            </>
          )}
        </Content>
      </Wrapper>

      <Background
        onClick={(e: React.MouseEvent) => {
          if (e.target === e.currentTarget) {
            hideNotifications();
          }
        }}
      />
    </>
  );
};
