import React from 'react';
import styled from 'styled-components';

import { colors } from 'constants/index';

const DrugWrapper = styled.div`
  overflow: hidden;
  background: ${colors.white};
  border-radius: 8px;
  position: relative;
`;

const InformationModal: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <DrugWrapper className={className}>
      {children}
    </DrugWrapper>
  );
};

export default InformationModal;
