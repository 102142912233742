import React from 'react';
import styled from 'styled-components';

import { Stakeholder, StakeholderDefinitionFragment, CompetitiveCategory } from 'data/graphql/generated';
import { SubMenu, TabGroup } from 'components/shared';
import { device } from 'utils/breakpoints';
import { Outcomes } from './Outcomes';
import { useHistory, useParams } from 'react-router-dom';
import useMobile from 'hooks/useMobile';
import useTablet from 'hooks/useTablet';

const StepBarsWrapper = styled.div`
  align-items: center;
  width: 100%;
  display: flex;

  > * + * {
    margin-left: 15px;
  }
`;

const TabGroupWrapper = styled.div`
  margin-top: 0px;
  @media ${device.tabletMax} {
    margin-top: 20px;
  }
`;

interface Props {
  isLead: boolean;
  hasNoTargetPatientsError: boolean;
  hasNoSelectedCompetitor: boolean;
  stakeholder: Stakeholder;
  setStakeholder(stakeholder: Stakeholder): void;
  refetchGroupsWithCards(): void;
  refetchStakeholderDefinitions(): void;
  selectedStakeholderDefinition?: StakeholderDefinitionFragment;
}

interface URLParams {
  category: CompetitiveCategory;
}

export const StakeHolderMenu: React.FC<Props> = ({
  stakeholder,
  setStakeholder,
  hasNoTargetPatientsError,
  hasNoSelectedCompetitor,
  isLead,
  refetchStakeholderDefinitions,
  refetchGroupsWithCards,
  selectedStakeholderDefinition,
}) => {

  const {
    category,
  }: URLParams = useParams();

  const { drugId, strategyId, stakeholderDefinitionId } = useParams<{
    drugId: string;
    strategyId: string;
    stakeholderDefinitionId: string;
  }>();

  const isTablet = useTablet();
  const isMobile = useMobile();
  const history = useHistory();

  return (
    <TabGroupWrapper>
      <TabGroup componentName="StepTab">
        <StepBarsWrapper>
          {isLead &&
            <>
              <Outcomes
                refetchGroupsWithCards={refetchGroupsWithCards}
                selectedStakeholderDefinition={selectedStakeholderDefinition}
                hasNoSelectedCompetitor={hasNoSelectedCompetitor}
                hasNoTargetPopulation={hasNoTargetPatientsError}
              />
            </>
          }

          {!isMobile && !isTablet && (
            <SubMenu
              border={true}
              gradient={true}
              onClick={() => {
                history.push(
                  `/d/${drugId}/strategy/${strategyId}/1_3/${category}/${stakeholder}/result/${stakeholderDefinitionId}`
                )
              }
              }
              icon="Lightning"
              text="Results"
            />
          )}
        </StepBarsWrapper>
      </TabGroup>
    </TabGroupWrapper>
  );
};