import { colors } from 'constants/index';
import { BodySmall, Subtitle2 } from 'components/shared';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { CompetitorSimpleFragment } from 'data/graphql/generated';
import { Link } from 'react-router-dom';
import { EmptyState } from 'components/Outcomes/OutcomesContext';
import { TreatmentIcon } from 'components/CompetitiveLandscape/TreatmentIcon';

const ModalContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 15px;
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const Section = styled.section`
  border: 1px solid ${colors.black10a};
  border-radius: 5px;
  padding: 15px;
  background: ${colors.white};
`;

const CompetitorImageAndTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const CompetitorImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const BulletPointList = styled.ul`
  margin: 0 0 0 50px;
  padding: 0;
  flex-direction: column;
  display: flex;
  gap: 15px;
`;

const BulletPointAndText = styled.div`
  display: flex;
`;

const BulletPoint = styled.div`
  width: 15px;
  height: 15px;
  border: 5px solid ${colors.purple};
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 2px;
  flex: none;
`;

const EmptyStateWrapper = styled.div`
  height: 100%;
  display: flex;
  padding: 105px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardEmptyWrapper = styled.div`
display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5px;
  border-radius: 5px;
  background: ${colors.yellow20};
  padding: 25px 15px;
  `

const GroupHeader = styled.div<{ centeredTitle: boolean }>`
  margin-bottom: 15px;
  display: block;
  justify-content: space-between;
  align-items: center;
  flex-wrap: ${({ centeredTitle }) => (centeredTitle ? 'nowrap' : 'wrap')};

  @media ${device.tabletMin} {
    justify-content: flex-start;
  }
`;

interface Props {
  handleClose(): void;
  strategyId: number;
  drugId: number;
  competitors: (CompetitorSimpleFragment | null | undefined)[];
}

export const SpaceOccupiedMenuCards = ({
  handleClose,
  competitors,
  drugId,
  strategyId,
}: Props) => {

  const LinkButton = () => (
    <Link
      to={`/d/${drugId}/strategy/${strategyId}/3_1/competitor-positioning`}
      style={{
        color: colors.greyDark,
        display: 'inline',
        fontSize: 14,
        fontWeight: 500,
      }}
      onClick={handleClose}
    >
      {' '}
      Part 1
    </Link>
  )
  return (
    <>
      <ModalContent>
        <Sections>
          {competitors.length ? (
            competitors.map((c) => {
              if (!c) return null;
              return (
                <Section key={c.id}>
                  <CompetitorImageAndTitle>
                    {c.image ? (
                      <CompetitorImage src={c.image} alt={c.title} />
                    ) : <TreatmentIcon />}
                    <BodySmall>{c.title}</BodySmall>
                  </CompetitorImageAndTitle>

                  {c.brandAssociations.length ? (
                    <BulletPointList>
                      {c.brandAssociations.map((b, idx) => (
                        <BulletPointAndText key={idx}>
                          <BulletPoint />
                          <BodySmall color={colors.greyDark}>{b}</BodySmall>
                        </BulletPointAndText>
                      ))}
                    </BulletPointList>
                  ) : (
                    <CardEmptyWrapper>
                      <Subtitle2 color={colors.greyDark}>
                        No brand associations idenfitied yet
                      </Subtitle2>
                      <div>
                        <BodySmall
                          style={{ display: 'inline' }}
                          color={colors.greyDark}
                        >
                          Leads should decide these in
                        </BodySmall>
                        <LinkButton />
                      </div>
                    </CardEmptyWrapper>
                  )}
                </Section>
              );
            })
          ) : (
            <EmptyStateWrapper>
              <GroupHeader centeredTitle>
                <div style={{ textAlign: 'center', marginTop: 50 }}>
                  <EmptyState
                    hasIcon
                    text="No competitors yet"
                  />
                  <div>
                    <BodySmall color={colors.greyDark} style={{ marginTop: 10, display: 'inline' }}>
                      You can add these in
                    </BodySmall>
                    <LinkButton />
                  </div>
                </div>
              </GroupHeader>
            </EmptyStateWrapper>
          )}
        </Sections>
      </ModalContent>
    </>
  );
};
