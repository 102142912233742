import { useState } from 'react';
import styled from 'styled-components';

import { ButtonPill } from 'components/shared';
import FormCountryDropdownSearch from 'components/shared/FormCountryDropdownSearch';
import FormPasswordInput from 'components/shared/FormPasswordInput';
import FormTextInput from 'components/shared/FormTextInput';
import { Form, Signin, Title } from 'containers/Signin';
import {
  useUserInviteQuery,
  useUserUpdateMutation,
} from 'data/graphql/generated';
import { Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { useSignInRedirect } from 'hooks/useSignInAndRedirect';
import { Country, countryArr } from 'types';
import { PasswordRequirements } from './PasswordRequirements';
import { globalContributor } from 'constants/index';

const PhoneInputWrapper = styled.div`
  display: flex;
  width: 100%;
  > *:first-child {
    margin-right: 15px;
    width: 117px;
    flex-shrink: 0;
  }
`;

const StyledPasswordRequirements = styled(PasswordRequirements)`
  margin: 0;
  margin-top: -5px;
`;

export const CreateAccount = () => {
  const defaultCountry = 'united kingdom';
  const [userUpdate] = useUserUpdateMutation();
  const [countryDropdownActive, setCountryDropdownActive] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country>(
    defaultCountry
  );
  const signInAndRedirect = useSignInRedirect();
  const uid = useQuery().get('id');

  const { data, loading, error } = useUserInviteQuery({
    variables: {
      uid,
    },
    onCompleted: () => {
      const phoneCountry = data?.userInvite?.user?.phoneCountry;
      const country =
        data?.userInvite?.user?.country === globalContributor
          ? defaultCountry
          : data?.userInvite?.user?.country;

      setSelectedCountry(
        (phoneCountry || country || defaultCountry) as Country
      );
    },
  });

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error || !data?.userInvite) {
    return <span>This invite has expired. Please request a new invite.</span>;
  }

  const {
    user: { name, email, id: userId },
    id: inviteId,
  } = data.userInvite;

  return (
    <Signin>
      <Formik
        initialValues={{
          name: name,
          phoneNumber: '',
          password: '',
        }}
        validate={(values) => {
          const errors = {} as { name: string; password: string };

          if (!values.name) {
            errors.name = 'Cannot be empty';
          }

          if (!values.password) {
            errors.password = 'Cannot be empty';
          }

          return errors;
        }}
        onSubmit={async (values, actions) => {
          try {
            await userUpdate({
              variables: {
                id: userId,
                data: {
                  name: values.name,
                  password: values.password,
                  verifiedEmail: true,
                  inviteId: inviteId,
                  phoneNumber: values.phoneNumber,
                  phoneCountry: selectedCountry,
                },
              },
            });
          } catch (error) {
            alert(error);
            console.error(error);
            return;
          }
          signInAndRedirect(email, values.password);
        }}
      >
        {({ values, handleSubmit, handleChange, errors, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Create an account</Title>

            <FormTextInput
              name="name"
              type="text"
              onChange={handleChange}
              value={values.name}
              title="Name"
              errorMessage={errors.name}
            />
            <PhoneInputWrapper>
              <FormCountryDropdownSearch
                title="Country code"
                name="countries"
                placeholder="Placeholder Text"
                onClick={() => setCountryDropdownActive(!countryDropdownActive)}
                dropdownActive={countryDropdownActive}
                selectedItem={selectedCountry}
                setSelectedItem={(val) => {
                  setSelectedCountry(val);
                }}
                setDropdownActive={setCountryDropdownActive}
                options={countryArr}
                phoneCode
              />
              <FormTextInput
                autocomplete="off"
                name="phoneNumber"
                type="tel"
                onChange={(e) => {
                  const { value } = e.target;
                  //Only allows 0-9
                  const regex = /^[0-9]*$/;
                  if (value.length < 1 || regex.test(value.toString())) {
                    handleChange(e);
                  }
                }}
                value={values.phoneNumber}
                title="Phone (optional)"
              />
            </PhoneInputWrapper>

            <FormPasswordInput
              name="password"
              title="Create Password"
              onChange={handleChange}
              value={values.password}
              errorMessage={
                !!values.password.length ? (passwordValid ? '' : ' ') : ''
              }
            />

            <StyledPasswordRequirements
              password={values.password}
              allPassed={(passed) => {
                setPasswordValid(passed);
              }}
            />

            <ButtonPill
              width="100%"
              onClick={() => {}}
              disabled={!values.name || !passwordValid || isSubmitting}
              level="primary"
              text={isSubmitting ? 'Loading...' : 'Create account'}
            />
          </Form>
        )}
      </Formik>
    </Signin>
  );
};
