import { colors, colorsv2 } from 'constants/index';
import React from 'react';
import styled from 'styled-components';
import { IconName } from 'types';
import { uid } from 'uid';
import { AnimatingWaveIcon } from './AnimatingWaveIcon';
import { Icon } from './Icon';
import { ButtonLabel } from './TextStyles';

import { Tooltip } from './Tooltip';

type ButtonRoundLevel = 'primary' | 'secondary' | 'add' | 'edit';
type ButtonRoundSize = 'large' | 'small' | number | undefined;

interface Props {
  innerRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined;
  level: ButtonRoundLevel;
  size?: ButtonRoundSize;
  disabled?: boolean;
  tooltip?: string;
  iconName?: IconName;
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
  className?: string;
  btnClassName?: string;
  text?: string;
  /**
   *Prevents the tooltip from wrapping across multiple lines
   */
  noWrap?: boolean;
  loading?: boolean;
  iconStyle?: React.CSSProperties;
}

function getPxFromSize(level: ButtonRoundLevel, size: ButtonRoundSize) {
  if (typeof size === 'number') {
    return size;
  }

  switch (level) {
    case 'primary':
      return 60;
    case 'secondary':
    case 'add':
    case 'edit':
      return size === 'large' ? 100 : 30;
  }
}

export const TooltipWrapper = styled.div<{
  level: ButtonRoundLevel;
  size: ButtonRoundSize;
}>`
  display: block;
  border: none;
  width: ${({ level, size }) => getPxFromSize(level, size)}px;
  height: ${({ level, size }) => getPxFromSize(level, size)}px;
`;

const Wrapper = styled.button<Props>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ level, size, disabled }) => {
    switch (level) {
      case 'primary':
        return colorsv2.bg.brand;
      case 'secondary':
        return size === 'large'
          ? colors.purple10
          : disabled
          ? colors.cream30
          : colors.cream;
      case 'add':
        return colors.yellow;
      case 'edit':
        return colors.cream;
    }
  }};
  border: none;
  border-radius: 100px;

  width: ${({ level, size }) => getPxFromSize(level, size)}px;
  height: ${({ level, size }) => getPxFromSize(level, size)}px;

  position: relative;
  & > div {
    z-index: 1;
  }
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 100px;
    background: ${({ level, size, disabled }) => {
      switch (level) {
        case 'primary':
          return `linear-gradient(rgb(0 0 0 / 10%), rgb(0 0 0 / 10%)), linear-gradient(315deg, #ff00c8 -83%, #7800ff 93.75%)`;

        case 'secondary':
          return size === 'large'
            ? colors.purple15
            : disabled
            ? colors.cream30
            : colors.cream;
      }
    }};
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: ${({ text }) => (text ? 0 : 1)};
  }

  &:focus {
    outline: none;
  }

  transition: all 0.3s ease;
`;

function getLevelColor(
  level: ButtonRoundLevel,
  size: ButtonRoundSize,
  disabled: boolean | undefined
) {
  switch (level) {
    case 'primary':
      return colors.white;
    case 'secondary':
      if (size === 'large') {
        return colors.purple;
      }
      if (disabled) {
        return colors.red30;
      }
      return colors.red;
    case 'add':
      return colors.black;
    case 'edit':
      return 'transparent';
  }
}

export const ButtonRound = ({
  level,
  size,
  disabled = false,
  iconName,
  tooltip = '',
  onClick,
  className,
  btnClassName = '',
  innerRef,
  text,
  noWrap,
  loading,
  iconStyle,
}: Props) => {
  const key = uid();

  return (
    <TooltipWrapper
      ref={innerRef}
      className={className}
      data-offset="{ 'top': -11 }"
      data-for={key}
      data-tip={tooltip}
      level={level}
      size={size}
      //Without this, we get issues with getting focus events for this element on Safari
      //https://stackoverflow.com/questions/42764494/blur-event-relatedtarget-returns-null
      tabIndex={0}
    >
      <Wrapper
        className={btnClassName}
        onClick={(e) => {
          if (!disabled && onClick) onClick(e);
        }}
        level={level}
        size={size}
        disabled={disabled}
        text={text}
      >
        {iconName ? (
          loading ? (
            <AnimatingWaveIcon
              size={typeof size === 'number' ? size : 30}
              color="white"
            />
          ) : (
            <Icon
              name={iconName}
              size={typeof size === 'number' ? size : 30}
              color={getLevelColor(level, size, disabled)}
              fillColor={level === 'edit' ? colors.black : undefined}
              style={iconStyle}
            />
          )
        ) : null}
        {text && <ButtonLabel color={colors.white}>{text}</ButtonLabel>}
      </Wrapper>
      <Tooltip noWrap={noWrap} id={key} />
    </TooltipWrapper>
  );
};
