import React from 'react';
import styled from 'styled-components';

import { colors, colorsv2 } from 'constants/index';
import { Icon } from 'components/shared';

const Notifications = styled.div<{ hasUnread: boolean; visible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  vertical-align: top;
  float: right;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-top: 4px;
  transition: all 0.3s;

  &:hover {
    background: ${({ visible }) => (visible ? colorsv2.bg.brand : colorsv2.bg.layer)};
  }
`;

const Badge = styled.div`
  align-items: center;
  background: ${colorsv2.bg.brand};
  display: flex;
  justify-content: center;
  width: 18px;
  height: 18px;
  position: absolute;
  margin-top: -1px;
  top: 0;
  right: 0;
  border-radius: 4px;
  color: ${colors.white};
  padding-bottom: 2px;
  font-size: 12px;
`;

interface Props {
  onClick(): void;
  visible: boolean;
  notificationsUnread: number;
}

export const NotificationBell: React.FC<Props> = ({
  onClick,
  visible,
  notificationsUnread,
}) => {
  return (
    <Notifications
      onClick={onClick}
      hasUnread={notificationsUnread > 0}
      visible={visible}
    >
      {notificationsUnread  ? (
        <Badge>{notificationsUnread > 99 ? 99 : notificationsUnread}</Badge>
      ) : null}

      <Icon name="Bell" size={24} color={colorsv2.textInverted.default} />
    </Notifications>
  );
};
