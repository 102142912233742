import { Navbar } from 'components/Navbar';

interface Props {}

export const Files = (props: Props) => {
  return (
    <div>
      <Navbar
        hideArrows
        title="Files"
      />
    </div>
  );
};
