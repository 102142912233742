import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { ErrorWrapper } from '../components/ErrorLoadingComponent';

import { EditCollaboratorModal } from 'components/CreateStrategy/EditCollaboratorModal';
import { DeleteCollaboratorModal } from 'components/CreateStrategy/DeleteCollaboratorModal';
import { AddCollaboratorButton } from 'components/CreateStrategy/StrategyCollaboratorsCreate';
import { Navbar } from 'components/Navbar';
import { Heading3 } from 'components/shared';
import CollaboratorsTable, {
  UserRow,
  TableTitles,
} from 'components/shared/CollaboratorsTable';
import { colors } from 'constants/colors';
import { useAuthContext } from 'contexts/AuthContext';
import {
  useCompanyUsersQuery,
  User,
  useStrategyWithDrugQuery,
} from 'data/graphql/generated';
import { device, size } from 'utils/breakpoints';
import { AddCollaboratorModal } from 'components/shared/AddCollaboratorModal';
import lodash from 'lodash';

const SMALL_DESKTOP_COLUMNS = css`
  grid-template-columns: 35fr 15fr 45fr;
`;
const LARGE_DESKTOP_COLUMNS = css`
  grid-template-columns: 30fr 15fr 45fr;
`;

interface Props {}

interface Params {
  drugId: string;
  strategyId: string;
}

const PageWrapper = styled.div`
  margin-top: 48px;
  padding: 30px 15px 15px 15px;
  min-height: calc(100vh - 50px);
  background: ${colors.white};
  ${Heading3} {
    line-height: 45px;
    margin-bottom: 10px;

    @media ${device.tabletMin} {
      margin-bottom: 0;
    }
  }

  @media ${device.desktopMin} {
    padding: 30px 20px 80px;

    ${UserRow} {
      ${SMALL_DESKTOP_COLUMNS}
    }

    ${TableTitles} {
      ${SMALL_DESKTOP_COLUMNS}
    }
  }

  @media screen and (min-width: 1240px) {
    ${UserRow} {
      ${LARGE_DESKTOP_COLUMNS}
    }

    ${TableTitles} {
      ${LARGE_DESKTOP_COLUMNS}
    }
  }

  @media screen and (min-width: ${size.desktop}) {
    padding: 30px 50px 80px;
  }
`;

const TitleAndButton = styled.div`
  margin-bottom: 30px;

  @media ${device.tabletMin} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const isProduction = process.env.REACT_APP_BUILD_ENV === 'production';

export const People: React.FC<Props> = () => {
  const { strategyId, drugId }: Params = useParams();
  const history = useHistory();

  const [{ user }] = useAuthContext();

  const { data, refetch, loading, error } = useStrategyWithDrugQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'no-cache',
  });

  const [usersForDropdown, setUsersForDropdown] = useState<
    (User | null | undefined)[]
  >([]);

  const drug = data?.strategy?.drug;
  const companyId = data?.strategy?.drug?.companyId;

  // https://github.com/apollographql/apollo-client/issues/5268#issuecomment-749501801
  const { refetch: refetchUsersForDropdown } = useCompanyUsersQuery({});

  const currentStrategyUsers = data?.strategy?.users?.flat() || [];
  const currentDrugUsers = data?.strategy?.drug?.users?.flat() || [];

  const currentStrategyAndDrugUniqueUsers = lodash.uniqBy(
    [...currentStrategyUsers, ...currentDrugUsers],
    'id'
  );

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  useEffect(() => {
    // hide people page for strategy 53 in production for everyone except Janice
    if (
      isProduction &&
      strategyId === '53' &&
      user?.email !== 'maclennanjanice+brandlead@gmail.com'
    ) {
      history.replace(`/d/${drugId}/strategy/${strategyId}`);
    }
  }, [drugId, history, strategyId, user?.email]);

  useEffect(() => {
    if (!editModalOpen && !deleteModalOpen && !addModalOpen) {
      setSelectedUser(null);

      //refetch when closing modal
      //Feels inconsistant so using an await to hopefully fix that
      (async () => await refetch())();
    }
  }, [editModalOpen, deleteModalOpen, addModalOpen, refetch]);

  useEffect(() => {
    //companyId has to be retrieved asynchronously
    //so it's being used to get usersForDropdown via this useEffect
    if (typeof companyId !== 'undefined') {
      (async () => {
        try {
          const response = await refetchUsersForDropdown({
            id: +companyId,
            strategyId: +strategyId,
          });

          if (response?.data?.companyUsers?.items) {
            setUsersForDropdown(response.data.companyUsers?.items);
          }
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [companyId, refetchUsersForDropdown, strategyId, addModalOpen]);

  function closeModal() {
    setAddModalOpen(false);
    setEditModalOpen(false);
    setSelectedUser(null);
  }

  return (
    <>
      <Navbar
        disableSecondary
        title={`${drug?.name || ''} ||•|| ${data?.strategy?.startYear || ''} - ${data?.strategy?.endYear || ''}`}
        hideArrows
      />
      {addModalOpen && (
        <AddCollaboratorModal
          handleClose={closeModal}
          visible
          usersForDropdown={usersForDropdown}
          usersToExclude={currentStrategyAndDrugUniqueUsers}
          excludedUsersMessage="Already in strategy"
          heading="Add collaborator"
          message="Enter their email address then confirm their role."
          strategyId={+strategyId}
          drugId={+drugId}
          submitBtnMessage="Send invite"
        />
      )}
      <EditCollaboratorModal
        modalOpen={editModalOpen}
        closeModal={closeModal}
        type="edit"
        user={selectedUser}
      />
      <DeleteCollaboratorModal
        name={selectedUser?.name}
        collabUserId={selectedUser?.id}
        removeStrategyId={+strategyId}
        modalOpen={deleteModalOpen}
        setModalOpen={(state) => {
          setDeleteModalOpen(state);
        }}
        message="This person won’t be able to access the strategy. Their contributions will remain."
      />

      <ErrorWrapper isLoading={loading} errors={[error]} dataMissing={!data}>
        <PageWrapper>
          <TitleAndButton>
            <Heading3>Collaborators</Heading3>
            {user?.role === 'LEAD' && (
              <AddCollaboratorButton
                onClick={() => {
                  setAddModalOpen(true);
                }}
              />
            )}
          </TitleAndButton>
          <CollaboratorsTable
            users={data?.strategy?.users || []}
            editUser={(user) => {
              setSelectedUser(user);
              setEditModalOpen(true);
            }}
            deleteUser={(user) => {
              setSelectedUser(user);
              setDeleteModalOpen(true);
            }}
            displayVerifiedStatus={true}
            companyId={drug?.companyId}
            drugId={+drugId}
            strategyId={+strategyId}
          />
        </PageWrapper>
      </ErrorWrapper>
    </>
  );
};
