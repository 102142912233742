import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Wrapper as StrategyStepWrapper } from './StrategyStep';
import { colors, themes } from '../constants';
import {
  BodyNormal,
  ButtonPill,
  Heading3,
  Icon,
} from './shared';
import { device } from 'utils/breakpoints';
import { useContributionsQuery, useDrugsQuery } from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { BubbleSummary } from './Strategy/BubbleSummary';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { StrategyStepV2 } from './StrategyStepV2';
import { ButtonArrow } from './shared/ButtonArrow';
import { useQuery } from 'hooks/useQuery';
import * as themeUtils from 'utils/themes';

interface PhaseProps {
  active?: boolean;
  finished?: boolean;
  mobileView?: boolean;
  mobileViewHide: boolean;
}

const Background = styled.div<{
  mobileView: boolean;
  totalBubbles?: number;
  bubbleSummaryOpen: boolean;
}>`
  background: url('/strat-gradient.png') ${colors.black};
  min-height: calc(100vh - 50px);
  height: ${({ bubbleSummaryOpen, totalBubbles }) =>
    bubbleSummaryOpen ? `calc(100vh - 50px)` : `${totalBubbles ? totalBubbles * 750 : 750}px`};
  margin-top: 48px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    background: rgba(250, 250, 250);
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.2;
    pointer-events: none;
    z-index: 1;
  }

  &::before {
    content: '';
    background: url(/noise.png) repeat;
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0.1;
    pointer-events: none;
    z-index: 3;
  }

  @media ${device.tabletMax} {
    height: 1452px;
  }

  @media ${device.tabletMax} {
    ${({ mobileView }) =>
    mobileView
      ? css`
            height: calc(100vh - 50px);
            min-height: 800px;
          `
      : ''}
  }
`;

const PhaseTitle = styled(Heading3) <{ small?: boolean; mobileView?: boolean }>`
  color: #ffffff;
  display: block;
  text-align: center;

  font-family: GTPressura;
  font-weight: 400;
  font-size: ${({ small }) => (small ? 40 : 60)}px;
  line-height: 80px;

  ${({ mobileView }) => {
    if (mobileView)
      return css`
        font-size: 40px;
        line-height: 50px;
      `;
  }};

  @media ${device.tabletMax} {
    font-size: ${({ small }) => (small ? 32 : 40)}px;
    line-height: 80px;
  }
`;

const PhaseSubtitle = styled(Heading3) <{
  mobileView?: boolean;
}>`
  color: #ffffff;
  display: block;
  text-align: center;
  font-family: GTPressura;
  font-weight: 400;
  line-height: 45px;
  padding-top: ${({ mobileView }) => (mobileView ? 0 : 120)}px;
  font-size: 60px;

  ${({ mobileView }) => {
    if (mobileView)
      return css`
        font-size: 20px;
        line-height: 140px;
      `;
  }};

  @media ${device.tablet} {
    padding-top: ${({ mobileView }) => (!mobileView ? 0 : 160)}px;
    line-height: 45px;
    display: inline-block;
    width: ${({ mobileView }) => (mobileView ? '300px' : 'auto')};
    font-size: 32px;
    word-break: break-word;
  }

  @media ${device.mobile} {
   padding-top: ${({ mobileView }) => (!mobileView ? 0 : 160)}px;
   line-height: 45px;
   display: inline-flex;
   width: ${({ mobileView }) => (mobileView ? '300px' : 'auto')};
   font-size: 32px;
  }

  @media ${device.desktop} {
   font-size: 60px;
   line-height: 45px;
   padding-top: 120px;
  }
`;

const Phases = styled.div`
  max-width: 1200px;

  position: relative;
  margin: 0 auto;

  @media ${device.tabletMax} {
    max-width: 400px;
  }
`;

const PhaseSteps = styled.span<{
  visible: boolean;
  mobileView?: boolean;
  index: number;
}>`
  position: absolute;
  left: 0px;
  top: 0px;

  opacity: ${({ visible }) => (visible ? 1 : 0)};
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  @media ${device.tabletMax} {
    ${({ mobileView }) =>
      mobileView
        ? css`
            top: 165px;
            right: 0px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
          `
        : css`
            display: none;
        `
    };
  }
`;

const Phase = styled.div<PhaseProps>`
  border-radius: 50%;
  z-index: 1;
  user-select: none;
  text-align: center;
  display: flex;
  flex-direction: column;

  ${({ finished }) =>
    !!finished &&
    css`
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background: #141427;
        border-radius: 50%;
        opacity: 0.6;
      }
    `}

  ${({ active }) =>
    active
      ? css`
          box-shadow: 0 0 0 5px #ffffff, 0 0 0 50px rgba(255, 255, 255, 0.05),
            0 0 0 100px rgba(255, 255, 255, 0.05);
          @media ${device.tabletMax} {
            box-shadow: 0 0 0 5px #ffffff, 0 0 0 20px rgba(255, 255, 255, 0.05),
              0 0 0 40px rgba(255, 255, 255, 0.05);
          }
        `
      : ''}

  ${({ active, finished }) =>
    active || finished
      ? css`
          background: linear-gradient(
              0deg,
              rgba(20, 20, 39, 0.2),
              rgba(20, 20, 39, 0.2)
            ),
            url('/strat-grad.png') center center, #665c97;
          background-size: contain;
        `
      : css`
          background: #a397ef;
        `}

  ${({ mobileView, mobileViewHide }) =>
    mobileView
      ? css`
          @media ${device.tabletMax} {
            .mobileViewHide {
              display: none;
            }

            position: absolute !important;
            top: 40px !important;
            left: 50% !important;
            height: 682px !important;
            padding-top: 60px !important;
            transform: translate(-50%, 0) !important;
            width: 682px !important;
            z-index: 2 !important;
            display: ${mobileViewHide ? 'none' : 'block'};
          }
        `
      : mobileViewHide
        ? css`
          @media ${device.tabletMax} {
            display: none !important;
          }
        `
        : null}
`;

const Phase1 = styled(Phase) <PhaseProps>`
  position: relative;
  display: flex;
  width: 500px;
  height: 500px;
  margin-top: 50px;
  gap: 40px;
  padding-top: 102px;
  left: 50%;
  transform: translate(-50%, 100px);

  @media ${device.tabletMax} {
    width: 345px;
    height: 345px;

    transform: translate(-50%, 40px);

    ${({ mobileView }) => mobileView
      ? css`
          ${PhaseTitle} {
            font-size: 40px;
            line-height: 50px;
          }

          ${PhaseSteps} {
            top: 170px;
          }
        `
      : css`
          ${StrategyStepWrapper} {
            bottom: 83px;
            margin-bottom: 0;
          }
        `}
  }
`;

const BackNav = styled.div<{ mobileView: boolean }>`
  width: 30px;
  height: 30px;
  background-color: ${colors.black50a};
  border-radius: 5px;
  position: fixed;
  top: 70px;
  left: 15px;
  z-index: 8;

  display: none;
  @media ${device.tabletMax} {
    display: ${({ mobileView }) => (mobileView ? 'block' : 'none')};
  }
`;

const TopRightContainer = styled.div`
  width: 400px;
  border: 2px solid var(--purple, #7800ff);
  background-color: ${colors.white};
  border-radius: 5px;
  position: fixed;
  top: 70px;
  right: 15px;
  z-index: 8;
  display: flex;

  @media ${device.tabletMax} {
  }
`;

const Content = styled.div`
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 10px 3px 10px 15px;
`;

const FindOutMoreContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-right: 15px;
  width: 100%;
  z-index: 3;
  justify-content: flex-start;
  align-items: right;
`;

const PhaseOverview = styled.span`
  display: none;
  align-self: center;

  @media ${device.tabletMax} {
    display: block;
    margin-bottom: 100px;
  }
`;

const FiveStepsLink = styled(ButtonArrow)`
  display: relative;
  justify-content: center;
  margin-bottom: 100px;
`;

interface Props {
  drugId: number;
  strategyId: number;
}

export const WelcomeScreen: React.FC<Props> = ({ drugId, strategyId }) => {
  const query = useQuery();
  const [mobileView, setMobileView] = useState<number | undefined>(
    Number(query.get('nav'))
  );
  const [bubbleSummary, setBubbleSummary] = useState<number | null>(null);
  const closeBubbleSummary = () => setBubbleSummary(null);
  const isDesktop = useDesktop();
  const history = useHistory();

  const {
    data: contributionsData,
    loading: contributionLoading,
  } = useContributionsQuery({
    variables: { where: { strategyId } },
    fetchPolicy: 'cache-and-network',
  });

  const { data: drugData } = useDrugsQuery({
    variables: { where: { id: drugId } },
    fetchPolicy: 'network-only',
  });

  const ProductOnDrugs = themeUtils.sortProductOnDrugs(
    drugData?.drugs?.items?.filter(
      (e) => e.id === drugId
    )[0]?.ProductOnDrugs
  );

  if (!contributionsData || contributionLoading) {
    return (
      <Background
        mobileView={!!mobileView}
        bubbleSummaryOpen={!!bubbleSummary}
      ></Background>
    );
  }

  function buildURL(page: string) {
    return `/d/${drugId}/strategy/${strategyId}/${page}`;
  }

  const calculateTop = (index: number, total: number) => {
    const decreaseTop = !!mobileView ? 40 : 120;
    if (total === 1) {
      return 330 - decreaseTop;
    } else if (total === 2) {
      switch (index) {
        case 0:
          return 300 - decreaseTop;
        case 1:
          return 360 - decreaseTop;

        default:
          break;
      }
    } else if (total === 3) {
      switch (index) {
        case 0:
          return 300 - decreaseTop;
        case 1:
          return 370 - decreaseTop;

        case 2:
          return 440 - decreaseTop;

        default:
          break;
      }
    } else if (total === 4) {
      switch (index) {
        case 0:
          return 280 - decreaseTop;
        case 1:
          return 350 - decreaseTop;
        case 2:
          return 420 - decreaseTop;
        case 3:
          return 490 - decreaseTop;

        default:
          break;
      }
    } else if (total === 5) {
      switch (index) {
        case 0:
          return 260 - decreaseTop;
        case 1:
          return 320 - decreaseTop;
        case 2:
          return 380 - decreaseTop;
        case 3:
          return 440 - decreaseTop;
        case 4:
          return 500 - decreaseTop;

        default:
          break;
      }
    }
    switch (index) {
      case 0:
        return 90;
      case 1:
        return 160;
      case 2:
        return 230;
      case 3:
        return 300;
      case 4:
        return 370;

      default:
        break;
    }
  };
  const calculateLeft = (index: number, total: number) => {
    if (total === 1) {
      return 485;
    } else if (total === 2) {
      switch (index) {
        case 0:
          return 475;
        case 1:
          return 475;

        default:
          break;
      }
    } else if (total === 3) {
      switch (index) {
        case 0:
          return 475;
        case 1:
          return 485;
        case 2:
          return 475;

        default:
          break;
      }
    } else if (total === 4) {
      switch (index) {
        case 0:
          return 475;
        case 1:
          return 485;
        case 2:
          return 475;
        case 3:
          return 455;

        default:
          break;
      }
    } else if (total === 5) {
      switch (index) {
        case 0:
          return 475;
        case 1:
          return 485;
        case 2:
          return 475;
        case 3:
          return 455;
        case 4:
          return 435;

        default:
          break;
      }
    }
    switch (index) {
      case 0:
        return 442;
      case 1:
        return 475;
      case 2:
        return 485;
      case 3:
        return 475;
      case 4:
        return 442;

      default:
        break;
    }
  };

  const getFeatureSetTitle = (featureSet: string) => {
    switch (featureSet) {
      case '1.1':
        return themes.discover.tools['1.1'].caption;
      case '1.2':
        return themes.discover.tools['1.2'].caption;
      case '1.3':
        return themes.discover.tools['1.3'].caption;
      case '1.4':
        return themes.discover.tools['1.4'].caption;
      case '1.5':
        return themes.discover.tools['1.5'].caption;
      case '2.1':
        return themes.explore.tools['2.1'].caption;
      case '2.2':
        return themes.explore.tools['2.2'].caption;
      case '2.3':
        return themes.explore.tools['2.3'].caption;
      case '2.4':
        return themes.explore.tools['2.4'].caption;
      case '2.5':
        return themes.explore.tools['2.5'].caption;
      case '3.1':
        return themes.develop.tools['3.1'].caption;
      case '3.2':
        return themes.develop.tools['3.2'].caption;
      case '3.3':
        return themes.develop.tools['3.3'].caption;
      case '3.4':
        return themes.develop.tools['3.4'].caption;
      case '3.5':
        return themes.develop.tools['3.5'].caption;
      case '4.1':
        return themes.activate.tools['4.1'].caption;
      case '4.2':
        return themes.activate.tools['4.2'].caption;
      case '4.3':
        return themes.activate.tools['4.3'].caption;
      case '4.4':
        return themes.activate.tools['4.4'].caption;
      case '4.5':
        return themes.activate.tools['4.5'].caption;
      default:
        return '';
    }
  };

  const getFeatureSetSubtitle = (featureSet: string) => {
    switch (featureSet) {
      case '1.1':
        return themes.discover.tools['1.1'].name;
      case '1.2':
        return themes.discover.tools['1.2'].name;
      case '1.3':
        return themes.discover.tools['1.3'].name;
      case '1.4':
        return themes.discover.tools['1.4'].name;
      case '1.5':
        return themes.discover.tools['1.5'].name;
      case '2.1':
        return themes.explore.tools['2.1'].name;
      case '2.2':
        return themes.explore.tools['2.2'].name;
      case '2.3':
        return themes.explore.tools['2.3'].name;
      case '2.4':
        return themes.explore.tools['2.4'].name;
      case '2.5':
        return themes.explore.tools['2.5'].name;
      case '3.1':
        return themes.develop.tools['3.1'].name;
      case '3.2':
        return themes.develop.tools['3.2'].name;
      case '3.3':
        return themes.develop.tools['3.3'].name;
      case '3.4':
        return themes.develop.tools['3.4'].name;
      case '3.5':
        return themes.develop.tools['3.5'].name;
      case '4.1':
        return themes.activate.tools['4.1'].name;
      case '4.2':
        return themes.activate.tools['4.2'].name;
      case '4.3':
        return themes.activate.tools['4.3'].name;
      case '4.4':
        return themes.activate.tools['4.4'].name;
      case '4.5':
        return themes.activate.tools['4.5'].name;
      default:
        return '';
    }
  };

  return (
    <Background totalBubbles={ProductOnDrugs?.length ?? 100} mobileView={!!mobileView} bubbleSummaryOpen={!!bubbleSummary}>
      {!!bubbleSummary && (
        <BubbleSummary
          closeBubbleSummary={closeBubbleSummary}
          strategyId={strategyId}
          bubble={bubbleSummary}
        />
      )}

      {!bubbleSummary && (
        <>
          <Phases>
            {ProductOnDrugs &&
              ProductOnDrugs.map((drug: any, index: number) => (
                <Phase1
                  key={drug.Product?.productName}
                  active={true}
                  finished={false}
                  mobileView={mobileView === index + 1}
                  mobileViewHide={!!mobileView}
                >
                  <div>
                    <PhaseSubtitle mobileView={!!mobileView}>
                      {drug?.Product?.productName}
                    </PhaseSubtitle>
                  </div>
                  
                  {_.sortBy(drug.Product?.features, ['featureSet']).map(
                    (d: any, i: number) => (
                      <React.Fragment key={`${d.featureSet}-${i}`}>
                        <StrategyStepV2
                          text={getFeatureSetTitle(d.featureSet)}
                          subtext={getFeatureSetSubtitle(d.featureSet)}
                          className="cypress-pagelink-keystakeholders"
                          isMobileView={!!mobileView}
                          index={index}
                          iconName="Step Incomplete"
                          hasProduct={false}
                          top={calculateTop(i, drug.Product?.features.length)}
                          left={calculateLeft(i, drug.Product?.features.length)}
                          link={buildURL(d.featureSet.replace('.', '_')) + `?block=${drug.block.key}`}
                          iconText={d.featureSet}
                        />
                      </React.Fragment>
                    )
                  )}

                  {!isDesktop && (
                    <PhaseOverview className="mobileViewHide">
                      <FiveStepsLink
                        text={`${drug.Product?.features.length} tools`}
                        name={'Complete'}
                        onClick={() => setMobileView(index + 1)}
                      />
                    </PhaseOverview>
                  )}
                </Phase1>
              ))}
          </Phases>

          {isDesktop && ProductOnDrugs?.length !== 0 && (
            <TopRightContainer>
              <Content
                style={{
                  backgroundColor: '#EBD9FF',
                  width: 101,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon name="Atom" size={40} color={colors.black} />
              </Content>
              <Content>
                <BodyNormal color={colors.black}>
                  There's a whole lot you can achieve with Nmblr.
                </BodyNormal>
                <FindOutMoreContainer>
                  <ButtonPill
                    level="primary-solid"
                    className="add-insight"
                    clickClassName="cypress-insight-create"
                    onClick={async (e) => {
                      e.stopPropagation();
                      history.push(buildURL(''));
                    }}
                    text="Find out more"
                  />
                </FindOutMoreContainer>
              </Content>
            </TopRightContainer>
          )}

          <BackNav
            onClick={() => {
              setMobileView(0);
            }}
            mobileView={!!mobileView}
          >
            <Icon name="Back" size={30} color={colors.cream} />
          </BackNav>
        </>
      )}
    </Background>
  );
};
