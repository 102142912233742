import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { Navbar } from 'components/Navbar';
import { Page, Wrapper } from 'components/Page';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';

import {
  TabGroup,
  StepTab,
  BodyNormal,
  Subtitle2,
  StrategicSummary,
  KeyStatements,
  CategoryTab,
  ButtonPill,
  BodySmall,
} from 'components/shared';
import {
  Step,
  SubStep,
  useStrategyQuery,
  useStrategicQuestionsQuery,
  useStrategicQuestionUpdateMutation,
  useKeyStatementsQuery,
  useKeyStatementUpsertMutation,
  useKeyStatementUpdateMutation,
  useKeyStatementDeleteMutation,
  KeyStatementsDocument,
} from 'data/graphql/generated';
import { camelCase, kebabCase, startCase } from 'lodash';
import { TabGroupWrapper, StepBarsWrapper } from './StrategicQuestion';
import { colors, polling } from 'constants/index';
import { StepHeader } from './BrandPositioning';
import useDesktop from 'hooks/useDesktop';
import { useAuthContext } from 'contexts/AuthContext';
import {
  Divider,
  EvaluateButtonContainerDesktop,
} from './CompetitiveLandscape';
import { device } from 'utils/breakpoints';
import { SupportingPage } from 'components/ValueProposition/SupportingPage';
import { usePostItCards } from 'hooks/usePostItCards';
import { PostItGroupAndCards } from 'types';
import { usePostItGroups } from 'hooks/usePostItGroups';
import { usePostItCardMove } from 'hooks/usePostItCardMove';
import { useQuery } from 'hooks/useQuery';
import {
  apolloCreateHelper,
  apolloDeleteHelper,
} from 'utils/apolloQueryHelpers';
import { StrategyDefinitionHeader } from 'components/StrategyDefinitionHeader';
import { PostIts } from 'components/PostIts';
import { EvaluateButtonWrapper } from 'components/CompetitiveLandscape/EvaluationButtons';
import { Proposition } from 'components/ValueProposition/Proposition';
import { verifyUserRole } from 'utils/verifyUserRole';
import { LoadingComponent } from 'components/Loading';
import EmptyState from 'components/3-3-core-value-messages/supporting-messages/src/lib/EmptyState';
import { sortPostIts } from '../hooks';
import { useGlobalContext } from 'contexts/GlobalContext';
import OneButtonModalLink from 'components/OneButtonModalLink';
import { ModalButtonLink } from 'components/shared/KeyStatement';

const StyledButtonPill = styled(ButtonPill)`
  button {
    padding: 5px 15px;
    width: fit-content;
  }
`;

const StyledCoreValuePostIts = styled(PostIts)`
  width: 100%;
`;

const TabsWrapper = styled.div<{ hideBorder: boolean }>`
  border-bottom: ${({ hideBorder }) =>
    hideBorder ? 'none' : `0.5px solid ${colors.greyMedium}`};
  margin: 0 -20px;
  padding: 0 20px;
`;

const StyledTabGroupWrapper = styled(TabGroupWrapper)`
  display: flex;
  align-items: center;
  overflow: hidden;
  @media ${device.tabletMax} {
    ${EvaluateButtonContainerDesktop} {
      margin-top: 0;
    }
  }
`;

const PageWrapper = styled(Page) <{
  onValuePropositionPage: boolean;
  isLoading: boolean;
}>`
  ${({ onValuePropositionPage, isLoading }) => {
    if (!!isLoading) {
      return css`
        margin-top: 50px;
      `;
    }
    if (!!onValuePropositionPage) {
      return css`
        padding-top: 15px;
        padding-bottom: 0;

        @media ${device.desktopMin} {
          padding-top: 20px;
        }
      `;
    }
  }}
  ${Wrapper} {
    width: 100%;
    padding: 0 20px;
    max-width: 1440px;

    @media ${device.tabletMax} {
      width: 100%;
      padding: 0;
    }
  }
`;

export interface URLParams {
  drugId: string;
  strategyId: string;
  coreValueMessage: string;
}

export const CoreValueMessages = () => {
  const { drugId, strategyId, coreValueMessage }: URLParams = useParams();

  const isDesktop = useDesktop();
  const history = useHistory();
  let query = useQuery();
  const [{ user }] = useAuthContext();
  const [
    hasSupportingMsgPrioritisationError,
    setHasSupportingMsgPrioritisationError,
  ] = useState(false);

  const [hasSupportingMsgGapsError, setHasSupportingMsgGapsError] = useState<
    string | null
  >(null);

  const [activeSubstep, setActiveSubstep] = useState(
    kebabCase(coreValueMessage) || kebabCase(SubStep.DiseaseBurdenAndUnmetNeed)
  );
  const activeSubstepCamelCase = camelCase(activeSubstep) as SubStep;
  const step = Step.ValueProposition;

  const { isLead } = verifyUserRole(user?.role, user?.country);

  const onValuePropositionPage = activeSubstep === 'summary';

  const [coreValueMessages, setCoreValueMessages] = useGlobalContext(
    'coreValueMessages'
  );
  const [isSupportingMessagePageGlobal, setIsSupportingMessagePageGlobal] = useGlobalContext(
    'isSupportingMessagePageGlobal'
  );

  useEffect(() => {
    if (coreValueMessages) {
      history.replace(
        coreValueMessages
      );
    }
  }, [drugId, history, coreValueMessage, coreValueMessages]);

  useEffect(() => {
    // Default url
    if (!coreValueMessage) {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/3_2/${activeSubstep}${isSupportingMessagePageGlobal ? '?support=true' : ''
        }`
      );
    }
  }, [
    history,
    coreValueMessage,
    drugId,
    strategyId,
    isSupportingMessagePageGlobal,
    activeSubstep,
  ]);

  useEffect(() => {
    //respond to changes in url
    setActiveSubstep(
      kebabCase(coreValueMessage) ||
      kebabCase(SubStep.DiseaseBurdenAndUnmetNeed)
    );
  }, [coreValueMessage, query]);

  const {
    data: strategyData,
    loading: strategyLoading,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
    error: strategyError,
  } = useStrategyQuery({
    variables: { id: +strategyId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const {
    data: strategicQuestionsData,
    error: strategicQuestionsError,
    loading: strategicQuestionsLoading,
    startPolling: startStrategicQuestionsPolling,
    stopPolling: stopStrategicQuestionsPolling,
  } = useStrategicQuestionsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        field: onValuePropositionPage ? undefined : activeSubstepCamelCase,
      },
    },
    fetchPolicy: 'network-only',
  });

  const strategicQuestion =
    strategicQuestionsData?.strategicQuestions?.items[0];

  const [updateStrategicQuestion] = useStrategicQuestionUpdateMutation();

  const subStepTitle = startCase(activeSubstep).replace(/ And /g, ' and ');

  const keyStatementsQueryVars = {
    where: {
      strategyId: +strategyId,
      step,
      subStep: onValuePropositionPage ? undefined : activeSubstepCamelCase,
    },
  };

  const {
    data: keyStatementsData,
    error: keyStatementsError,
    loading: keyStatementsLoading,
    startPolling: startKeyStatementsPolling,
    stopPolling: stopKeyStatementsPolling,
  } = useKeyStatementsQuery({
    variables: keyStatementsQueryVars,
  });

  const keyStatements = keyStatementsData?.keyStatements?.items;

  const [upsertKeyStatement] = useKeyStatementUpsertMutation();
  const [updateKeyStatement] = useKeyStatementUpdateMutation();
  const [deleteKeyStatement] = useKeyStatementDeleteMutation();

  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step,
        substep: activeSubstepCamelCase,
        preset: !isSupportingMessagePageGlobal,
      },
    },
    {
      strategyId,
      drugId,
      step,
      substep: activeSubstepCamelCase,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step,
        substep: activeSubstepCamelCase,
      },
    },
    {
      strategyId,
      drugId,
      step,
      substep: activeSubstepCamelCase,
    }
  );

  const groups: PostItGroupAndCards[] = sortPostIts(groupItems, cardItems);

  const isLoading =
    keyStatementsLoading ||
    strategyLoading ||
    groupsLoading ||
    cardsLoading ||
    strategicQuestionsLoading;

  const onChange = usePostItCardMove(groups, updateCard);

  useEffect(() => {
    startStrategyPolling(polling.default);
    startKeyStatementsPolling(polling.default);
    startStrategicQuestionsPolling(polling.default);
    return () => {
      stopStrategyPolling();
      stopKeyStatementsPolling();
      stopStrategicQuestionsPolling();
    };
  }, [
    startStrategicQuestionsPolling,
    startStrategyPolling,
    startKeyStatementsPolling,
    stopKeyStatementsPolling,
    stopStrategicQuestionsPolling,
    stopStrategyPolling,
  ]);

  return (
    <>
      <StrategicSummary />

      <Navbar>
        <StyledTabGroupWrapper>
          <TabGroup componentName="StepTab">
            <StepBarsWrapper>
              {[
                {
                  title: 'Disease Burden and Unmet Need',
                  substep: kebabCase(SubStep.DiseaseBurdenAndUnmetNeed),
                },
                {
                  title: 'Clinical Value',
                  substep: kebabCase(SubStep.ClinicalValue),
                },
                {
                  title: 'Humanistic Value',
                  substep: kebabCase(SubStep.HumanisticValue),
                },
                {
                  title: 'Economic Value',
                  substep: kebabCase(SubStep.EconomicValue),
                },
              ].map((v) => {
                return (
                  <StepTab
                    key={v.substep}
                    active={activeSubstep === v.substep}
                    text={v.title}
                    onClick={() => {
                      const url = `/d/${drugId}/strategy/${strategyId}/3_2/${kebabCase(
                        v.substep
                      )}${isSupportingMessagePageGlobal ? '?support=true' : ''}`
                      history.push(url);
                      setCoreValueMessages(url)
                    }}
                  />
                );
              })}
              <EvaluateButtonContainerDesktop>
                <Divider style={{ marginLeft: 5 }} />

                <EvaluateButtonWrapper showEvaluation={onValuePropositionPage}>
                  <StyledButtonPill
                    text={'Summary'}
                    onClick={() => {
                      const url = `/d/${drugId}/strategy/${strategyId}/3_2/summary`;
                      history.push(url);
                      setCoreValueMessages(url)
                    }}
                    level={onValuePropositionPage ? 'evaluating' : 'evaluate'}
                    color={onValuePropositionPage ? colors.white : colors.black}
                  />
                </EvaluateButtonWrapper>
              </EvaluateButtonContainerDesktop>
            </StepBarsWrapper>
          </TabGroup>
        </StyledTabGroupWrapper>
      </Navbar>
      {!onValuePropositionPage && (
        <>
          <StepHeader isDesktop={isDesktop}>
            <Subtitle2 style={{ marginBottom: '5px' }}>
              {`Define the ${subStepTitle} then consider supporting messages`}
            </Subtitle2>
            <BodyNormal
              color={colors.greyDark}
              style={{ display: 'inline-block', marginBottom: 15 }}
            >
              Add notes with suggestions. A Lead should finalise statements.
            </BodyNormal>
            <TabsWrapper hideBorder={isSupportingMessagePageGlobal}>
              <TabGroup componentName="CategoryTab">
                <div style={{ display: 'flex', gap: 2 }}>
                  {[
                    { label: 'Core Value Message' },
                    { label: 'Supporting Messages' },
                  ].map((val, index) => {
                    return (
                      <div
                        key={val.label + index}
                        style={{ display: 'inline-block' }}
                        className={`cypress-tab-${kebabCase(val.label)}`}
                      >
                        <CategoryTab
                          fivePxRadius={true}
                          text={val.label}
                          active={
                            val.label === 'Core Value Message'
                              ? !isSupportingMessagePageGlobal
                              : isSupportingMessagePageGlobal
                          }
                          onClick={() => {
                            if (val.label === 'Core Value Message') {
                              history.push({ search: '' });
                              setIsSupportingMessagePageGlobal(false)

                            } else {
                              history.push({ search: 'support=true' });
                              setIsSupportingMessagePageGlobal(true)
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </TabGroup>
            </TabsWrapper>
            {!!strategicQuestion?.collaboration && !isSupportingMessagePageGlobal && (
              <StrategyDefinitionHeader
                type={strategicQuestion.type}
                collaboration={strategicQuestion?.collaboration}
                totalUsers={strategyData?.strategy?.users.length}
                isLead={isLead}
                title={subStepTitle}
                textValue={strategicQuestion?.text || ''}
                handleSave={async (value) => {
                  if (typeof value === 'string') {
                    await updateStrategicQuestion({
                      variables: {
                        id: strategicQuestion?.id,
                        data: {
                          text: value,
                        },
                      },
                    });
                  }
                }}
              />
            )}
          </StepHeader>

          {!!keyStatements &&
            isSupportingMessagePageGlobal &&
            !!strategicQuestion?.text && (
              <LoadingComponent
                isLoading={keyStatementsLoading || strategicQuestionsLoading}
              >
                <KeyStatements
                  isLead={isLead}
                  style={{ width: '100%' }}
                  placeholder="Type a supporting message"
                  marketRepPlaceholder="None yet. A Lead will add supporting messages here."
                  title="Supporting Messages"
                  addStatement={({ text, localUid }) => {
                    const itemData = {
                      localUid,
                      text,
                      title: 'Supporting Messages',
                      strategyId: Number(strategyId),
                      step,
                      subStep: activeSubstepCamelCase,
                      competitiveAdvantageRowId: null,
                      tactics: [],
                    };
                    const id = Math.ceil(Math.random() * 20000);
                    try {
                      upsertKeyStatement({
                        variables: {
                          data: {
                            ...itemData,
                          },
                        },
                        optimisticResponse: {
                          keyStatementUpsert: {
                            ...itemData,
                            id,
                            lastUpdated: new Date().toISOString(),
                            createdAt: new Date().toISOString(),
                            __typename: 'KeyStatement',
                            region: 'global',
                            focus: false,
                            focusRationale: '',
                            collaboration: null,
                            accessStrategyPrioritiseSupportingMessages: [],
                            accessStrategyEndpointTargets: [],
                          },
                        },
                        update: apolloCreateHelper({
                          responseField: 'keyStatementUpsert',
                          query: KeyStatementsDocument,
                          queryName: 'keyStatements',
                          queryVars: keyStatementsQueryVars,
                        }),
                      });
                    } catch (error) {
                      alert('something went wrong');
                    }
                  }}
                  deleteStatement={async (keyStatement) => {
                    try {
                      await deleteKeyStatement({
                        variables: { localUid: keyStatement.localUid },
                        optimisticResponse: {
                          keyStatementDelete: keyStatement,
                        },
                        update: apolloDeleteHelper({
                          responseField: 'keyStatementDelete',
                          query: KeyStatementsDocument,
                          queryVars: keyStatementsQueryVars,
                          queryName: 'keyStatements',
                        }),
                      });
                    } catch (err) {
                      if (
                        err instanceof Error &&
                        err.message.includes(`DELETE_ERROR_GAPS`)
                      ) {
                        setHasSupportingMsgGapsError(keyStatement.localUid);
                      } else if (
                        err instanceof Error &&
                        (err.message.includes(`DELETE_ERROR_PRIORITISATION`) ||
                          err.message.includes(`DELETE_ERROR_EVIDENCE`))
                      ) {
                        setHasSupportingMsgPrioritisationError(true);
                      } else {
                        console.error(err);
                        alert('something went wrong');
                      }
                    }
                  }}
                  updateStatement={({ localUid, text }) => {
                    try {
                      updateKeyStatement({
                        variables: { localUid, data: { text } },
                      });
                    } catch (error) {
                      alert('something went wrong');
                    }
                  }}
                  data={keyStatements}
                />
              </LoadingComponent>
            )}
        </>
      )}
      <PageWrapper
        isLoading={isLoading}
        paddingTop={false}
        fullWidthMobile
        onValuePropositionPage
      >
        <ErrorWrapper
          isLoading={isLoading}
          errors={[
            strategyError,
            keyStatementsError,
            groupsError,
            cardsError,
            strategicQuestionsError,
          ]}
          dataMissing={!strategyData || !keyStatementsData}
        >
          <main
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {!!onValuePropositionPage ? (
              <div
                style={{
                  marginTop: isDesktop ? 110 : 160,
                  width: '100%',
                }}
              >
                <Proposition
                  strategyId={strategyId}
                  isLead={isLead}
                  strategicQuestions={
                    strategicQuestionsData?.strategicQuestions?.items
                  }
                  keyStatements={keyStatements}
                  strategyCoreValueProposition={
                    strategyData?.strategy?.coreValueProposition || ''
                  }
                />
              </div>
            ) : (
              <>
                {!isSupportingMessagePageGlobal ? (
                  <StyledCoreValuePostIts
                    step={step}
                    groups={groups}
                    addCard={createCard}
                    removeCard={removeCard}
                    updateCard={updateCard}
                    handleCardChange={onChange}
                    createGroup={createGroup}
                    updateGroup={updateGroup}
                    createGroupHide
                    removeGroup={() => { }}
                  />
                ) : !strategicQuestion?.text ? (
                  <EmptyState
                    drugId={drugId}
                    strategyId={strategyId}
                    activeSubstep={activeSubstep}
                  />
                ) : (
                  <SupportingPage
                    step={step}
                    params={{ drugId, strategyId, coreValueMessage }}
                    groups={groups}
                    addCard={createCard}
                    removeCard={removeCard}
                    updateCard={updateCard}
                    handleCardChange={onChange}
                    createGroup={createGroup}
                    updateGroup={updateGroup}
                    removeGroup={removeGroup}
                    isDesktop={isDesktop}
                    coreValueMessage={strategicQuestion?.text}
                  />
                )}
              </>
            )}
          </main>
        </ErrorWrapper>
      </PageWrapper>

      <OneButtonModalLink
        title="Cannot delete this supporting message"
        body="Cannot delete this supporting message. The assessment in 4.1 part 1 is using this supporting message."
        modalOpen={hasSupportingMsgPrioritisationError || !!hasSupportingMsgGapsError}
        handleClose={() => {
          setHasSupportingMsgPrioritisationError(false)
          setHasSupportingMsgGapsError(null)
        }}
        onClick={() => {
          setHasSupportingMsgPrioritisationError(false)
          setHasSupportingMsgGapsError(null)
        }}
      >
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          Remove from grid in{' '}
        </BodySmall>
        <ModalButtonLink
          to={`/d/${drugId}/strategy/${strategyId}/4_1/prioritisation`}
        >
          4.1 Part 1{' '}
        </ModalButtonLink>
        <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
          to do this.
        </BodySmall>
      </OneButtonModalLink>
      {
        /**  commenting first, but leaving for reference
          * 
        <TwoButtonModal
          handleClose={() => setHasSupportingMsgGapsError(null)}
          rightButtonLevel={'secondary'}
          leftButtonOnClick={() => setHasSupportingMsgGapsError(null)}
          rightButtonText={'OK'}
          rightButtonOnClick={() => setHasSupportingMsgGapsError(null)}
         
          leftButtonLevel={'secondary'}
          leftButtonText={'OK'}
         
          rightButtonOnClick={async () => {
            try {
              const keyStatementToDelete = keyStatements?.find(
                (k) => k.localUid === hasSupportingMsgGapsError
              );
   
              if (!keyStatementToDelete || !hasSupportingMsgGapsError) return;
   
              await deleteKeyStatement({
                variables: {
                  localUid: hasSupportingMsgGapsError,
                  confirmDelete: true,
                },
                optimisticResponse: {
                  keyStatementDelete: keyStatementToDelete,
                },
                update: apolloDeleteHelper({
                  responseField: 'keyStatementDelete',
                  query: KeyStatementsDocument,
                  queryVars: keyStatementsQueryVars,
                  queryName: 'keyStatements',
                }),
              });
            } catch (err) {
              alert('Something went wrong');
            } finally {
              setHasSupportingMsgGapsError(null);
            }
          }}
  
          modalOpen={!!hasSupportingMsgGapsError}
          title={
            'Cannot delete this supporting message'
          }
          body={
            'Cannot delete this supporting message. The assessment in 4.1 part 1 is using this supporting message. Remove from grid in 4.1 part 1 to do this.'
          }
        /> 
        */
      }
    </>
  );
};
