import React from 'react';
import styled from 'styled-components';
import { BodySmall, Subtitle1 } from 'components/shared';
import { Signin } from 'containers/Signin';
import { Navbar } from 'components/Navbar';

const Wrapper = styled.div``;

export const Title = styled(Subtitle1)`
  margin-bottom: 15px;
`;

interface Props {
  userEmail: string;
}

const InstructionsSent: React.FC<{ userEmail: string }> = ({
  userEmail,
}: Props) => {
  return (
    <Signin>
      <Navbar
      exclude={[
        'drugLink',
        'strategyLink',
        'filesLink',
        'profile',
        'notifications',
        'navItems',
        'navContent',
        'sidebarTrigger',
      ]}
      disableSecondary
    />
      <Wrapper>
        <Title>Instructions sent</Title>
        <BodySmall>
          If this account exists, instructions to reset the password will be
          sent to {userEmail}.
        </BodySmall>
        <br />
        <BodySmall>
          The email will arrive within 5 minutes. Check your spam folder if
          you don’t see it.
        </BodySmall>
      </Wrapper>
    </Signin>
  );
};

export default InstructionsSent;
