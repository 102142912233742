import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { colors } from 'constants/colors';
import { ModalXClose } from 'components/ModalXClose';
import { Icon } from 'components/shared/Icon';

const Title = styled.div`
  color: var(--black, #141427);
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 10px;
`;

const SubTitle = styled.div`
  color: var(--black, #141427);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const OptionButton = styled.div<{ isSelected?: boolean; hoverColor: string }>`
  display: flex;
  margin: 0 auto;
  width: 80%;
  align-items: center;
  padding: 8px 20px;
  border: 1px solid ${colors.black10};
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ isSelected, hoverColor }) =>
    isSelected ? `${hoverColor}10` : colors.white};
  font-size: 14px;
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:hover {
    background-color: ${({ hoverColor }) => `${hoverColor}10`};
  }
`;

const IconWrapper = styled.div<{ borderColor: string }>`
  background: ${({ borderColor }) => `${borderColor}20`};
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OpportunityOrThreatModal = ({
  handleClose,
  visible,
  onSelect,
}: ModalProps) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleSelectOption = (option: string) => {
    setSelectedOption(option);
    onSelect(option);
    console.log(`${option} selected`);
    handleClose();
  };

  useEffect(() => {
    if (!visible) {
      setSelectedOption(null);
    }
  }, [visible]);

  return (
    <ModalXClose handleClose={handleClose} visible={visible}>
      <ModalWrapper>
        <Title>Focus</Title>
        <SubTitle>Does this present an opportunity or a threat?</SubTitle>
        <OptionWrapper>
          {/* Opportunity Button */}
          <OptionButton
            onClick={() => handleSelectOption('Opportunity')}
            isSelected={selectedOption === 'Opportunity'}
            hoverColor={colors.greenDark}
          >
            <IconWrapper borderColor={colors.greenDark}>
              <Icon name="Tick" size={30} color={colors.white} />
            </IconWrapper>
            Opportunity
          </OptionButton>

          {/* Threat Button */}
          <OptionButton
            onClick={() => handleSelectOption('Threat')}
            isSelected={selectedOption === 'Threat'}
            hoverColor={colors.red}
          >
            <IconWrapper borderColor={colors.red}>
              <Icon name="Tick" size={30} color={colors.white} />
            </IconWrapper>
            Threat
          </OptionButton>
        </OptionWrapper>
      </ModalWrapper>
    </ModalXClose>
  );
};

interface ModalProps {
  handleClose(): void;
  visible: boolean;
  onSelect: (selection: string) => void;
}
