import styled from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';
import useDesktop from 'hooks/useDesktop';
import { Navbar } from 'components/Navbar';
import { StepTab, TabGroup } from 'components/shared';
import { StepBarsWrapper, TabGroupWrapper } from 'containers/StrategicQuestion';
import { ArchetypeSwitcher } from './ArchetypeSwitcher';
import { SubStep } from 'data/graphql/generated';
import { kebabCase } from 'lodash';

interface URLParams {
  drugId: string;
  strategyId: string;
}

interface Props {
  substep: SubStep;
}

const StyledTabGroupWrapper = styled(TabGroupWrapper)`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const TabsAndArchetypeSwitcher = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 10px;
`;

export default function AccessStrategyNav({ substep }: Props) {
  const { drugId, strategyId }: URLParams = useParams();
  const isDesktop = useDesktop();
  const history = useHistory();

  return (
    <Navbar
      pageNavChildren={
        <ArchetypeSwitcher
          drugId={drugId}
          strategyId={strategyId}
          globalView={substep !== SubStep.Prioritisation}
        />
      }
    >
      <TabsAndArchetypeSwitcher>
        <StyledTabGroupWrapper>
          <TabGroup componentName="StepTab">
            <StepBarsWrapper>
              {[
                {
                  title: 'Part 1: Prioritisation',
                  substep: SubStep.Prioritisation,
                  className: 'cypress-prioritisation-tab',
                },
                {
                  title: 'Part 2: Gaps',
                  substep: SubStep.Gaps,
                  className: 'cypress-gaps-tab',
                },
                {
                  title: 'Part 3: Evidence',
                  substep: SubStep.Evidence,
                  className: 'cypress-evidence-tab',
                },
                {
                  title: 'Part 4: PICO',
                  substep: SubStep.Pico,
                  className: 'cypress-pico-tab',
                },
                {
                  title: 'Part 5: Winning actions',
                  substep: SubStep.WinningActions,
                  className: 'cypress-tactics-tab',
                },
                {
                  title: 'Summary',
                  substep: SubStep.Summary,
                  className: 'cypress-summary-tab',
                },
              ].map((v) => {
                return (
                  <StepTab
                    key={v.substep}
                    active={v.substep === substep}
                    text={v.title}
                    className={v.className}
                    onClick={() => {
                      // prevent clicking on Prioritisation tab if we are currently on Prioritisation page
                      // because it does not populate the archetype id when done from here
                      if (
                        substep === SubStep.Prioritisation &&
                        v.substep === SubStep.Prioritisation
                      ) {
                        return;
                      }
                      history.push(
                        `/d/${drugId}/strategy/${strategyId}/4_2/${kebabCase(v.substep)}`
                      );
                    }}
                  />
                );
              })}
            </StepBarsWrapper>
          </TabGroup>
        </StyledTabGroupWrapper>

        {isDesktop && (
          <ArchetypeSwitcher
            drugId={drugId}
            strategyId={strategyId}
            globalView={substep !== SubStep.Prioritisation}
          />
        )}
      </TabsAndArchetypeSwitcher>
    </Navbar>
  );
}
