import { ModalForm } from 'components/ModalForm';
import { colors, globalContributor } from 'constants/index';
import { ModalButton, ModalButtonContainer } from 'containers/AdminCompany';
import { ErrorBox, ErrorMessage } from 'containers/Signin';
import {
  Role,
  useAddCollaboratorMutation,
  User,
  useUserUpdateMutation,
} from 'data/graphql/generated';
import { validate } from 'email-validator';
import { Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { CountryGlobalRegional, countryAndGlobalRegionalArr } from 'types';
import { mapRoleEnumToReadableString } from 'utils/mapRoleEnum';
import { preventEnterSubmit } from 'utils/preventEnterSubmit';
import { startCase } from 'utils/startCase';
import { CountryFlag } from '.';
import FormCountryDropdownSearch from './FormCountryDropdownSearch';
import FormDropdown from './FormDropdown';
import FormTextInput from './FormTextInput';
import { TextInputWithDropdownAndSelection } from './TextInputWithDropdownAndSelection';
import { BodySmall, ButtonLabel } from './TextStyles';

const StyledTitleTextInput = styled(FormTextInput)<{
  errorMessageDisplayed: boolean;
}>`
  margin-bottom: ${({ errorMessageDisplayed }) =>
    errorMessageDisplayed ? '20px' : '10px'};
`;

const StyledNameTextInput = styled(FormTextInput)`
  margin-bottom: 10px;
`;

const StyledFormCountryDropdown = styled(FormCountryDropdownSearch)`
  margin-bottom: 10px;
  label {
    margin-bottom: 0;
  }
` as typeof FormCountryDropdownSearch;

const Wrapper = styled(ModalForm)`
  label[for='email'] {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .CTAWrapper {
    position: relative;
  }
`;

const StyledErrorBox = styled(ErrorBox)`
  margin-top: 30px;
  text-align: left;
`;

interface Props {
  handleClose(): void;
  visible: boolean;
  heading: string;
  message: string;
  usersForDropdown?: (User | null | undefined)[];
  usersToExclude: (User | null | undefined)[];
  excludedUsersMessage: string;
  strategyId: number;
  drugId: number;
  submitBtnMessage: string;
}

export const AddCollaboratorModal = ({
  handleClose,
  visible,
  heading,
  message,
  usersForDropdown,
  usersToExclude,
  excludedUsersMessage,
  strategyId,
  drugId,
  submitBtnMessage,
}: Props) => {
  const [showNameAndRoleField, setShowNameAndRoleField] = useState(false);
  const [showSelectedRole, setShowSelectedRole] = useState<User>();
  const [roleDropdownActive, setRoleDropdownActive] = useState(false);
  const [showEditRoleInputs, setShowEditRoleInputs] = useState(false);
  const [countryDropdownActive, setCountryDropdownActive] = useState(false);
  const [
    selectedCountry,
    setSelectedCountry,
  ] = useState<CountryGlobalRegional>();

  const [networkError, setNetworkError] = useState(false);

  const [addCollaborator] = useAddCollaboratorMutation();
  const [userUpdate] = useUserUpdateMutation();

  async function handleSubmit({
    name,
    email,
    role,
    country,
    userId,
    title,
    strategyId,
    drugId,
  }: {
    name: string;
    email: string;
    role: string;
    country: string;
    userId: string;
    title: string;
    strategyId: number;
    drugId: number;
  }) {
    email = email?.trim();
    name = name?.trim();
    title = title?.trim();
    country = role === Role.Lead ? '' : country;

    try {
      if (!userId) {
        await addCollaborator({
          variables: {
            data: {
              drugId: +drugId,
              strategyId: +strategyId,
              name: name || undefined,
              email: email || undefined,
              role: role as Role,
              country: country || undefined,
              phoneCountry: country || undefined,
              title:
                role === Role.Contributor && country !== globalContributor
                  ? ''
                  : title || undefined,
            },
          },
        });
      } else {
        await userUpdate({
          variables: {
            id: +userId,
            data: {
              name: name || undefined,
              email: email || undefined,
              drugId: +drugId,
              role: (role as Role) || undefined,
              country: role === Role.Lead ? globalContributor : country || undefined,
              title:
                role === Role.Contributor && country !== globalContributor
                  ? undefined
                  : title || undefined,
              strategyId: +strategyId,
            },
          },
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
      throw error;
    }
  }

  if (!visible) {
    return null;
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        email: '',
        name: '',
        role: '',
        country: '',
        userId: '',
        title: '',
      }}
      validate={({ name, email, title, role, country }) => {
        setNetworkError(false);
        const errors = {} as {
          name: string;
          email: string;
          title: string;
          country: string;
          role: boolean;
        };

        if (!name) {
          errors.name = 'Cannot be empty';
        }

        if (!email) {
          errors.email = 'Cannot be empty';
        }

        if (!validate(email)) {
          errors.email = 'Invalid email address';
        }

        if (!role) {
          errors.role = false;
        }

        if (role === Role.Lead && !title) {
          errors.title = 'Leads must have a title.';
        }

        if (
          role === Role.Contributor &&
          (country || selectedCountry) === globalContributor &&
          !title
        ) {
          errors.title = 'Global / Regional contributors must have a title.';
        }

        if (role === Role.Contributor && !country) {
          errors.country = 'Contributors must be assigned a market.';
        }

        return errors;
      }}
      onSubmit={async (
        { email, name, role, country, userId, title },
        actions
      ) => {
        try {
          await handleSubmit({
            name,
            email,
            role,
            country,
            userId,
            title,
            strategyId,
            drugId,
          });

          actions.resetForm();
          handleClose();
        } catch (error) {
          if (
            [
              'Unique constraint failed on the fields: (`email`)',
              'This email address is already in use',
            ].some(
              (message) =>
                error instanceof Error && error.message.includes(message)
            )
          ) {
            actions.setFieldError('email', 'Email address already in use');
          } else {
            setNetworkError(true);
            console.error(error);
          }
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        setFieldValue,
        isValid,
        dirty,
        resetForm,
        isSubmitting,
        touched,
        setFieldTouched,
        setValues,
      }) => {
        const isViewingGlobalContributor =
          values.role === Role.Contributor &&
          (values.country || selectedCountry) === globalContributor;
        return (
          <form onKeyDown={preventEnterSubmit} onSubmit={handleSubmit}>
            <Wrapper
              handleClose={() => {
                resetForm();
                handleClose();
              }}
              visible={visible}
              heading={heading}
              message={message}
            >
              <TextInputWithDropdownAndSelection
                emailFieldValue={values.email}
                nameValue={values.name}
                onDisabledChange={(disableState) => {
                  if (!disableState) {
                    setShowNameAndRoleField(false);
                  } else {
                    setFieldValue('name', '', true);
                  }
                }}
                usersForDropdown={usersForDropdown}
                handleValueChange={handleChange}
                emailErrorMessage={errors.email}
                usersToExclude={usersToExclude}
                onOptionSelected={(user) => {
                  const { name, email, id, title, role, country } = user;

                  if (id) {
                    setValues({
                      name,
                      email,
                      userId: String(id),
                      title: title || '',
                      role: role.toUpperCase(),
                      country: country || '',
                    });
                  } else {
                    setFieldValue('email', email);
                  }

                  setShowSelectedRole(user);
                }}
                excludedUsersMessage={excludedUsersMessage}
                disabledState={showNameAndRoleField}
                setDisabledState={setShowNameAndRoleField}
                onSelectedItemChange={() => {
                  setShowSelectedRole(undefined);
                  setShowEditRoleInputs(false);

                  resetForm();
                }}
              />

              {showNameAndRoleField && (
                <StyledNameTextInput
                  name="name"
                  value={values.name}
                  title="Name"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  errorMessage={touched.name ? errors.name : ''}
                />
              )}

              {!!showSelectedRole && !showEditRoleInputs ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    gap: 5,
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                >
                  <BodySmall>
                    {
                      mapRoleEnumToReadableString[
                        showSelectedRole.role as keyof typeof mapRoleEnumToReadableString
                      ]
                    }
                  </BodySmall>

                  <CountryFlag user={showSelectedRole} size={20} showLabel />
                  <div>
                    <ButtonLabel
                      color={colors.purple}
                      onClick={() => {
                        setShowEditRoleInputs(true);
                      }}
                    >
                      Change role
                    </ButtonLabel>
                    <BodySmall color={colors.greyDark}>
                      Changing role will apply to all brands and strategies this
                      user has access to.
                    </BodySmall>
                  </div>
                </div>
              ) : null}

              {(showEditRoleInputs || showNameAndRoleField) && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 0,
                    textAlign: 'left',
                  }}
                >
                  <FormDropdown
                    title="Role"
                    name="role"
                    placeholder="Choose role"
                    onClick={() => setRoleDropdownActive(!roleDropdownActive)}
                    dropdownActive={roleDropdownActive}
                    selectedItem={startCase(values.role)}
                    setSelectedItem={(item) => {
                      if (!item) return;
                      const typedItem = String(item) as keyof typeof Role;
                      setFieldTouched('role', true);
                      setFieldValue('role', Role[typedItem], true);

                      if (typedItem.toUpperCase() === Role.Lead) {
                        if (!values.title) {
                          setFieldValue('title', startCase(Role.Lead), true);
                        }
                      } else {
                        setFieldValue('title', '', true);
                      }
                    }}
                    setDropdownActive={setRoleDropdownActive}
                    options={[
                      startCase(Role.Lead),
                      startCase(Role.Contributor),
                    ]}
                    errorMessage={touched.role ? errors.role : ''}
                  />
                  <div
                    style={{
                      marginBottom: 10,
                      marginTop: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 5,
                    }}
                  >
                    <BodySmall color={colors.greyDark}>
                      Leads can access all strategies, make decisions, manage
                      collaborators and contribute ideas.
                    </BodySmall>
                    <BodySmall color={colors.greyDark}>
                      Contributors can only contribute ideas to the strategy.
                    </BodySmall>
                  </div>

                  {values.role === Role.Contributor && (
                    <StyledFormCountryDropdown
                      title="Responsibility"
                      name="country"
                      placeholder="Choose responsibility"
                      onClick={() =>
                        setCountryDropdownActive(!countryDropdownActive)
                      }
                      dropdownActive={countryDropdownActive}
                      selectedItem={
                        (values.country as CountryGlobalRegional) ||
                        selectedCountry
                      }
                      setSelectedItem={(country) => {
                        setFieldTouched('country', true);
                        setSelectedCountry(country);
                        setFieldValue('country', country, true);
                      }}
                      setDropdownActive={setCountryDropdownActive}
                      options={countryAndGlobalRegionalArr}
                      errorMessage={touched.country ? errors.country : ''}
                    />
                  )}

                  {(values.role === Role.Lead ||
                    isViewingGlobalContributor) && (
                    <StyledTitleTextInput
                      errorMessageDisplayed={
                        !!(touched.title ? errors.title : '')
                      }
                      name="title"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={(e) => {
                        setFieldTouched('title', true);
                        handleChange(e);
                      }}
                      value={values.title}
                      title="Title"
                      errorMessage={touched.title ? errors.title : ''}
                    />
                  )}
                </div>
              )}

              {/* Buttons */}
              <ModalButtonContainer>
                <ModalButton
                  onClick={() => {
                    resetForm();
                    handleClose();
                  }}
                  text="Cancel"
                  level="secondary"
                />
                <ModalButton
                  loading={isSubmitting}
                  type="submit"
                  disabled={
                    !(
                      values.name &&
                      values.email &&
                      values.role &&
                      (values.role === Role.Contributor &&
                      values.country !== globalContributor
                        ? !!values.country
                        : !!values.title)
                    )
                  }
                  onClick={() => {}}
                  text={submitBtnMessage}
                />
              </ModalButtonContainer>

              {networkError ? (
                <StyledErrorBox>
                  <ErrorMessage>
                    {
                      'Invite could not be sent due to an unknown error. Please try again.'
                    }
                  </ErrorMessage>
                </StyledErrorBox>
              ) : null}
            </Wrapper>
          </form>
        );
      }}
    </Formik>
  );
};
