import { colors, zIndex } from 'constants/index';
import { useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Icon } from '.';
import { BodySmall, Subtitle2 } from './TextStyles';
import { device } from 'utils/breakpoints';
import ReactDOM from 'react-dom';
import { CompetitorSimpleFragment } from 'data/graphql/generated';
import { SpaceOccupiedMenuCards } from 'components/Positioning/SpaceOccupiedMenuCards';
import useClickOutsideComponent from 'hooks/useClickOutsideComponent';

interface Props {
  open: boolean;
  setOpen(state: boolean): void;
  strategyId: number;
  drugId: number;
  competitors: (CompetitorSimpleFragment | null | undefined)[];
}

const Wrapper = styled.div<{
  open: boolean;
  offsetTop: number;
  offsetTopOverride: number | undefined;
}>`
  display: flex;
  user-select: none;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: ${({ offsetTopOverride = 0 }) =>
    `calc(100vh -  50px - ${offsetTopOverride}px)`};
  top: 50px;
  background: linear-gradient(
      0deg,
      rgba(20, 20, 39, 0.05),
      rgba(20, 20, 39, 0.05)
    ),
    #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  right: ${({ open }) => (open ? '0px' : '-100%')};
  bottom: ${({ offsetTopOverride }) => (offsetTopOverride ? 0 + 'px' : 'auto')};

  z-index: ${zIndex.observationsMenu};

  transition: all 0.3s;

  @media ${device.tabletMin} {
    width: 430px;
  }
`;

export const GradientBG = styled.div<{
  selected?: boolean;
}>`
  background: ${({ selected }) =>
    selected ? colors.purplePinkGradient : colors.black30a};
  padding: 1px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: auto;
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  height: 61px;
  background: ${colors.white};
  padding: 15px;
  display: flex;

  ${BodySmall} {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 320px;
  overflow: hidden;
  }
  
  > * + * {
    margin-left: 5px;
  }
  align-items: center;
  > div:last-child {
    cursor: pointer;
    margin-left: auto;
  }
  > p > span {
    color: ${colors.black50};
  }
`;

export const SpaceOccupiedMenu = ({
  open,
  setOpen,
  competitors,
  drugId,
  strategyId,
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  /**
   * Disable scrolling on the body when the menu is open
   */
  useEffect(() => {
    const htmlNode = document.getElementsByTagName('html')[0];
    if (!htmlNode) return;
    if (open) {
      htmlNode.style['overflow'] = 'hidden';
    } else {
      htmlNode.style['overflow'] = 'auto';
    }
  }, [open]);

  useClickOutsideComponent(containerRef, (e) => {
    if (open) setOpen(false);
  });

  return ReactDOM.createPortal(
    <Wrapper
      ref={containerRef}
      offsetTopOverride={1}
      offsetTop={containerRef.current?.offsetTop || 0}
      open={open}
    >
      <Header>
        <div>
          <Subtitle2 style={{ width: 'auto' }}>Space Occupied</Subtitle2>
        </div>

        <div
          onClick={() => {
            setOpen(false);
          }}
        >
          <Icon name="Collapse right" size={30} color={colors.purple} />
        </div>
      </Header>

      <SpaceOccupiedMenuCards
        handleClose={() => setOpen(false)}
        competitors={competitors}
        drugId={+drugId}
        strategyId={+strategyId}
      />
    </Wrapper>,
    document.body
  );
};