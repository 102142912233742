import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { orderBy } from 'lodash';
import { Navbar } from 'components/Navbar';
import { PostItsEmpty } from 'components/PostItsEmpty';
import {
  BodyNormal,
  BodySmall,
  ContributionTab,
  TabGroup,
  Subtitle2,
} from 'components/shared';
import { colors, polling, themes } from 'constants/index';
import {
  Sort,
  Stakeholder,
  SuccessConditionsDocument,
  SuccessConditionsQueryVariables,
  useStakeholderDefinitionsQuery,
  useSuccessConditionUpsertMutation,
  useSuccessConditionDeleteMutation,
  useSuccessConditionsQuery,
  useSuccessConditionUpdateMutation,
  useSuccessConditionRatingUpsertMutation,
  useStrategicPossibilitiesForDriversAndBarriersQuery,
} from 'data/graphql/generated';
import useDesktop from 'hooks/useDesktop';
import { device } from 'utils/breakpoints';
import { Loading } from 'components/Loading';
import {
  SuccessConditions,
  Wrapper,
} from 'components/DriversAndBarriers/SuccessConditions';
import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import { useGlobalContext } from 'contexts/GlobalContext';

import {
  ImperativeSummarySidebar,
  sidebarTransition,
} from 'components/shared/ImperativeSummarySidebar';
import {
  SidebarMobileWrapper,
  SidebarDesktopWrapper,
  PageWrapper,
  StepHeader,
  MainContent,
} from 'components/shared/SidebarPageComponents';
import useGetElementTop from 'hooks/useGetElementTop';
import useSidePaneTopOffset from 'utils/useSidePaneTopOffset';
import OneButtonModalLink from 'components/OneButtonModalLink';
import { ModalButtonLink } from 'components/shared/KeyStatement';

const overlapWidth = 735;

const SuccessConditionsContainer = styled.div<{ sidebarOpen: boolean }>`
  overflow: hidden;
  transition: width ${sidebarTransition.duration}ms
    ${sidebarTransition.timingFunction};

  @media ${device.tabletMin} {
    padding: 15px;
    width: ${({ sidebarOpen }) => (sidebarOpen ? '435px' : '100%')};
  }

  @media (min-width: ${overlapWidth}px) {
    width: auto;
    margin: auto;
  }

  ${Wrapper} {
    margin: auto;
  }
`;

interface URLParams {
  drugId: string;
  strategyId: string;
  possibilityId: string;
}

export const DriversAndBarriers = () => {
  const { drugId, strategyId, possibilityId }: URLParams = useParams();
  const history = useHistory();
  const isDesktop = useDesktop();

  const totalNavHeight = isDesktop ? 50 : 110;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sidebarDesktopRef = useRef<HTMLDivElement | null>(null);
  const desktopWrapperTop = useGetElementTop(sidebarDesktopRef);
  const topOffset = useSidePaneTopOffset(sidebarDesktopRef, totalNavHeight);

  const [successConditionErrorModal, setSuccessConditionErrorModal] = useState(
    false
  );

  // handle global context path
  const [strategicPossiblityId, setStrategicPossiblity] = useGlobalContext(
    'strategicPossiblity'
  );

  useEffect(() => {
    if (!possibilityId && strategicPossiblityId) {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/2_4/${strategicPossiblityId}`
      );
    }
  }, [drugId, history, possibilityId, strategicPossiblityId, strategyId]);

  const stakeholderDefinitionsQueryVars = {
    where: {
      stakeholder: Stakeholder.Patient,
      strategyId: +strategyId,
    },
    orderBy: { createdAt: Sort.Asc },
    hasImageOrTitle: true,
  };

  const {
    data: stakeholderDefinitions,
    error: stakeholderDefinitionsError,
    loading: stakeholderDefinitionsLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsQuery({
    fetchPolicy: 'network-only',
    variables: stakeholderDefinitionsQueryVars,
  });

  const {
    data: strategicPossibilities,
    error: strategicPossibilitiesError,
    loading: strategicPossibilitiesLoading,
    startPolling: startPossibilitiesPolling,
    stopPolling: stopPossibilitiesPolling,
  } = useStrategicPossibilitiesForDriversAndBarriersQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        strategyId: +strategyId,
        imperative: true,
      },
    },
  });

  const possibilities = strategicPossibilities?.strategicPossibilities?.items;
  const activeImperative = possibilities?.find((p) => p.id === +possibilityId);

  const sortedStrategicPossibilities = useMemo(
    () =>
      orderBy(
        strategicPossibilities?.strategicPossibilities?.items,
        [(possibility) => possibility?.patientFlowBlock?.column?.idx],
        ['asc']
      ),
    [strategicPossibilities?.strategicPossibilities?.items]
  );

  useEffect(() => {
    // Ensure the first stakeholder, stakeholderDefinition, and possibility are selected by default if they are not available in the url
    if (
      (possibilityId === 'undefined' || typeof possibilityId === 'undefined') &&
      sortedStrategicPossibilities?.[0]?.id
    ) {
      let url = `/d/${drugId}/strategy/${strategyId}/2_4`;
      url += `/${sortedStrategicPossibilities?.[0].id}`;

      return history.replace(url);
    } else if (possibilityId !== 'undefined' && possibilityId) {
      let url = `/d/${drugId}/strategy/${strategyId}/2_4`;
      url += `/${possibilityId}`;

      return history.replace(url);
    }
  }, [
    drugId,
    history,
    sortedStrategicPossibilities,
    possibilityId,
    strategyId,
  ]);

  const successConditionsQueryVars: SuccessConditionsQueryVariables = {
    where: {
      strategyId: Number(strategyId),
    },
    include: {
      solutions: true
    },
    orderBy: { createdAt: Sort.Desc },
  };

  const {
    data: successConditionsData,
    loading: successConditionsLoading,
    error: successConditionsError,
    startPolling: startSuccessConditionsStartPolling,
    stopPolling: stopSuccessConditionsStopPolling,
  } = useSuccessConditionsQuery({
    variables: {
      ...successConditionsQueryVars,
    },
  });

  const [updateSuccessCondition] = useSuccessConditionUpdateMutation();
  const [upsertSuccessCondition] = useSuccessConditionUpsertMutation();
  const [deleteSuccessCondition] = useSuccessConditionDeleteMutation();

  const [
    upsertSuccessConditionRating,
  ] = useSuccessConditionRatingUpsertMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: SuccessConditionsDocument,
        variables: {
          ...successConditionsQueryVars,
        },
      },
    ],
  });

  useEffect(() => {
    startPolling(polling.default);
    startPossibilitiesPolling(polling.default);
    startSuccessConditionsStartPolling(polling.default);
    return () => {
      stopPolling();
      stopPossibilitiesPolling();
      stopSuccessConditionsStopPolling();
    };
  }, [
    startPolling,
    startPossibilitiesPolling,
    startSuccessConditionsStartPolling,
    stopPolling,
    stopPossibilitiesPolling,
    stopSuccessConditionsStopPolling,
  ]);

  const noStakeholderDefinitions =
    Number(stakeholderDefinitions?.stakeholderDefinitions?.total) < 1;

  const noStrategicPossibilities = !strategicPossibilities
    ?.strategicPossibilities.total;

  const filteredSuccessConditions =
    successConditionsData?.successConditions?.items.filter(
      (condition) => condition.strategicPossibility.id === +possibilityId
    ) || [];

  const showSidebar = (id?: number) => {
    setSidebarOpen(true);
    history.push(`/d/${drugId}/strategy/${strategyId}/2_4/${id}`);
  };

  const ratings = filteredSuccessConditions?.flatMap(
    (condition) => condition.ratings
  );

  const solutions = filteredSuccessConditions?.filter(condition => condition?.strategyId === Number(strategyId)).flatMap(
    (condition) => condition?.solutions || []
  );

  const competitiveAdvantageRowId = solutions.map(condition => { return condition?.competitiveAdvantageRowId });

  return (
    <>
      <Navbar disableSecondary={isDesktop} />

      <SidebarMobileWrapper totalNavHeight={totalNavHeight}>
        <StepHeader>
          <Subtitle2 style={{ marginBottom: '5px' }}>
            What would have to be true for this strategy to be a success?
          </Subtitle2>
          <BodyNormal color={colors.greyDark} style={{ marginBottom: '15px' }}>
            Consider from a stakeholder, competitor and company perspective. Add
            required truths and rate your confidence. Identify barriers and
            drivers.
          </BodyNormal>
          {noStrategicPossibilities ? null : (
            <TabGroup componentName="ContributionTab">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  height: 52,
                }}
              >
                {sortedStrategicPossibilities.map((possibility, index) => {
                  return (
                    <ContributionTab
                      key={possibility.id}
                      id={`contribution-tab-${possibility.id}`}
                      secondary=""
                      text={possibility.name || ''}
                      active={possibility.id === +possibilityId}
                      onClick={(id) => {
                        history.push(
                          `/d/${drugId}/strategy/${strategyId}/2_4/${id}`
                        );
                        setStrategicPossiblity(id)
                      }}
                      sidebarOpen={sidebarOpen}
                      displaySummary={() => showSidebar(possibility.id)}
                      revealTruncatedText={false}
                    />
                  );
                })}
              </div>
            </TabGroup>
          )}
        </StepHeader>

        <SidebarDesktopWrapper
          ref={sidebarDesktopRef}
          sidebarOpen={sidebarOpen}
          isRelative={desktopWrapperTop > totalNavHeight}
          totalNavHeight={topOffset}
        >
          <ErrorWrapper
            isLoading={
              stakeholderDefinitionsLoading ||
              strategicPossibilitiesLoading ||
              successConditionsLoading
            }
            errors={[
              stakeholderDefinitionsError ||
              strategicPossibilitiesError ||
              successConditionsError,
            ]}
            dataMissing={
              !stakeholderDefinitions ||
              !strategicPossibilities ||
              !successConditionsData
            }
          >
            <OneButtonModalLink
              title="Cannot remove this Condition for success"
              body="Content in later steps of the strategy depends on this Condition for success."
              modalOpen={successConditionErrorModal}
              handleClose={() => setSuccessConditionErrorModal(false)}
              onClick={() => setSuccessConditionErrorModal(false)}
            >
              <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
                Remove content in{' '}
              </BodySmall>
              <ModalButtonLink
                to={`/d/${drugId}/strategy/${strategyId}/4_4/ideas/${competitiveAdvantageRowId[0] ?? ''}`}
              >
                4.4 {' '}
              </ModalButtonLink>
              <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
                to do this.
              </BodySmall>
            </OneButtonModalLink>
            <PageWrapper
              paddingTop={false}
              fullWidthMobile
              totalNavHeight={totalNavHeight}
            >
              <ImperativeSummarySidebar
                activeImperative={activeImperative}
                activeImperativeId={activeImperative?.id}
                sidebarOpen={sidebarOpen && !!possibilityId}
                setSidebarOpen={setSidebarOpen}
                desktopWrapperTop={desktopWrapperTop}
                totalNavHeight={totalNavHeight}
                customTitle="Strategic Possibility"
              />

              <MainContent
                sidebarOpen={sidebarOpen}
                overlapWidth={overlapWidth}
              >
                {noStakeholderDefinitions ? (
                  <PostItsEmpty title="No stakeholder definitions to assess">
                    <BodySmall
                      color={colors.greyDark}
                      style={{ display: 'inline' }}
                    >
                      Leads must define stakeholders in{' '}
                    </BodySmall>
                    <Link
                      to={`/d/${drugId}/strategy/${strategyId}/1_2`}
                      style={{
                        color: colors.greyDark,
                        display: 'inline',
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      1.2 {themes.discover.tools['1.2'].name}{' '}
                    </Link>
                    <BodySmall
                      color={colors.greyDark}
                      style={{ display: 'inline' }}
                    >
                      to start assessing the competitive landscape
                    </BodySmall>
                  </PostItsEmpty>
                ) : strategicPossibilitiesLoading ||
                  successConditionsLoading ? (
                  <Loading />
                ) : noStrategicPossibilities ? (
                  <PostItsEmpty title="Nothing to discuss">
                    <BodySmall
                      color={colors.greyDark}
                      style={{ display: 'inline' }}
                    >
                      Conditions for success can only be discussed when Leads
                      choose strategic possibilities of focus in{' '}
                    </BodySmall>
                    <Link
                      to={`/d/${drugId}/strategy/${strategyId}/2_3`}
                      style={{
                        color: colors.greyDark,
                        display: 'inline',
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      2.3 {themes.explore.tools['2.3'].name}
                    </Link>
                  </PostItsEmpty>
                ) : (
                  filteredSuccessConditions && (
                    <SuccessConditionsContainer sidebarOpen={sidebarOpen}>
                      <SuccessConditions
                        strategyId={strategyId}
                        data={filteredSuccessConditions}
                        ratings={ratings}
                        key={possibilityId}
                        upsertSuccessCondition={async ({
                          text,
                          localUid,
                          type,
                          perspective,
                        }) => {
                          const successCondition = await upsertSuccessCondition(
                            {
                              variables: {
                                data: {
                                  localUid,
                                  text,
                                  strategicPossibilityId: Number(possibilityId),
                                  drugId: Number(drugId),
                                  strategyId: Number(strategyId),
                                  type,
                                  perspective,
                                },
                              },
                              awaitRefetchQueries: true,
                              refetchQueries: [
                                {
                                  query: SuccessConditionsDocument,
                                  variables: {
                                    ...successConditionsQueryVars,
                                  },
                                },
                              ],
                            }
                          );

                          return successCondition;
                        }}
                        updateSuccessCondition={async (
                          id,
                          { text, type, perspective }
                        ) => {
                          const updatedSuccessCondition = await updateSuccessCondition(
                            {
                              variables: {
                                id,
                                data: { text, type, perspective },
                              },
                              awaitRefetchQueries: true,
                              refetchQueries: [
                                {
                                  query: SuccessConditionsDocument,
                                  variables: {
                                    ...successConditionsQueryVars,
                                  },
                                },
                              ],
                            }
                          );

                          return updatedSuccessCondition;
                        }}
                        deleteSuccessCondition={async (id) => {
                          const updatedSuccessCondition = await deleteSuccessCondition(
                            {
                              variables: { id },
                              awaitRefetchQueries: true,

                              refetchQueries: [
                                {
                                  query: SuccessConditionsDocument,
                                  variables: {
                                    ...successConditionsQueryVars,
                                  },
                                },
                              ],
                              onError: (error) => {
                                if (
                                  error instanceof Error &&
                                  error.message.includes(
                                    'cannot be deleted as it has child data'
                                  )
                                ) {
                                  setSuccessConditionErrorModal(true);
                                } else {
                                  alert('Something went wrong');
                                }
                              },
                            }
                          );

                          return updatedSuccessCondition;
                        }}
                        upsertSuccessConditionRating={async ({
                          region,
                          userId,
                          score,
                          successConditionLocalUid,
                        }: {
                          region?: string;
                          userId?: number;
                          score: number;
                          successConditionLocalUid: string;
                        }) => {
                          return await upsertSuccessConditionRating({
                            variables: {
                              data: {
                                drugId: Number(drugId),
                                strategyId: Number(strategyId),
                                region,
                                userId,
                                score,
                                successConditionLocalUid,
                              },
                            },
                          });
                        }}
                      />
                    </SuccessConditionsContainer>
                  )
                )}
              </MainContent>
            </PageWrapper>
          </ErrorWrapper>
        </SidebarDesktopWrapper>
      </SidebarMobileWrapper>
    </>
  );
};
