import {
  BodyNormal,
  BodySmall,
  ButtonLabel,
  ContributionTab,
  Subtitle2,
  TabGroup,
} from 'components/shared';
import { kebabCase, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PageWrapper, StepHeader } from '.';
import { ErrorWrapper } from '../../components/ErrorLoadingComponent';
import { ErrorModal } from '../../components/ErrorModal';
import { SpaceOccupiedModal } from '../../components/Positioning/SpaceOccupiedModal';
import { TheWhy } from '../../components/Positioning/TheWhy';
import { colors, polling } from '../../constants';
import {
  CompetitorFragment,
  Sort,
  StatementsDocument,
  Step,
  SubStep,
  usePostItGroupsWithCardsQuery,
  useStakeholderDefinitionsWithPatientFlowBlockStarQuery,
  useStatementUpdateMutation,
  useStatementsQuery,
  useStrategyQuery,
  useTheWhyPageQuery,
} from '../../data/graphql/generated';
import { sortPostIts } from '../../hooks';
import useDesktop from '../../hooks/useDesktop';
import { usePostItCardMove } from '../../hooks/usePostItCardMove';
import { usePostItCards } from '../../hooks/usePostItCards';
import { usePostItGroups } from '../../hooks/usePostItGroups';
import { PositioningTabKebabCase, PostItGroupAndCards } from '../../types';
import { useGlobalContext } from 'contexts/GlobalContext';
import { PostItsEmpty } from 'components/PostItsEmpty';
import { SpaceOccupiedMenu } from 'components/shared/SpaceOccupiedMenu';
import { useAuthContext } from 'contexts/AuthContext';
import { verifyUserRole } from 'utils/verifyUserRole';

type Props = {};

interface URLParams {
  drugId: string;
  strategyId: string;
  positioningTab: PositioningTabKebabCase;
  groupId: string;
}

export const TheWhyPage = (props: Props) => {
  const [spaceOccupiedModal, setSpaceOccupiedModal] = useState(false);
  const [noGroupModal, setNoGroupModal] = useState(false);
  const [spaceOccupied, setSpaceOccupied] = useState(false);
  const { drugId, strategyId, groupId }: URLParams = useParams();
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);
  const history = useHistory();
  const isDesktop = useDesktop();
  const kebabCaseSubStep = kebabCase(SubStep.TheWhy);

  // handle global context path
  const [theWhyGroupId, setTheWhyGroupId] = useGlobalContext(
    'theWhyGroupId'
  );

  useEffect(() => {
    if (!groupId && theWhyGroupId) {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCaseSubStep}/${theWhyGroupId}`
      );
    }
  }, [drugId, history, groupId, theWhyGroupId, kebabCaseSubStep, strategyId]);

  const {
    data: strategyData,
    startPolling: startStrategyPolling,
    stopPolling: stopStrategyPolling,
  } = useStrategyQuery({
    variables: { id: +strategyId },
  });

  const {
    data: theWhyPageData,
    loading: theWhyPageLoading,
    startPolling: theWhyPageStartPolling,
    stopPolling: theWhyPageStopPolling,
    error: theWhyPageError,
  } = useTheWhyPageQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        id: Number(groupId) || -1,
      },
    },
    fetchPolicy: 'network-only',
  });

  const theWhyPageChildId = theWhyPageData?.theWhyPage?.id;

  const {
    data: statementsData,
    loading: statementsLoading,
    startPolling: statementsStartPolling,
    stopPolling: statementsStopPolling,
    error: statementsError,
  } = useStatementsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
        step: Step.Positioning,
        substep: [SubStep.TheWhy],
        theWhyPageId: theWhyPageChildId,
      },
    },
  });

  const statementRefetch = {
    refetchQueries: [
      {
        query: StatementsDocument,
        variables: {
          where: {
            strategyId: +strategyId,
            step: Step.Positioning,
            substep: SubStep.TheWhy,
            theWhyPageId: theWhyPageChildId,
          },
        },
      },
    ],
  };

  const [updateStatement] = useStatementUpdateMutation({
    ...statementRefetch,
  });

  const {
    data: stakeholderDefinitionsData,
    loading: stakeholderDefinitionsLoading,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsWithPatientFlowBlockStarQuery({
    variables: { where: { strategyId: +strategyId } },
    fetchPolicy: 'network-only',
  });

  const {
    items: insightGroups,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWho,
        preset: false,
      },
      orderBy: {
        id: Sort.Asc,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWho,
    }
  );


  const {
    items: groupItems,
    loading: groupsLoading,
    createGroup,
    updateGroup,
    removeGroup,
    error: groupsError,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWhy,
        theWhyPageId: theWhyPageChildId,
      },
      orderBy: {
        id: Sort.Asc,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWhy,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
    error: cardsError,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWhy,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWhy,
    }
  );

  const {
    items: insightCardItems,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWho,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Positioning,
      substep: SubStep.TheWho,
    }
  );

  // get "What are the brand position that matter to key insights" cards to sort the groups
  const {
    data: groupsWithCardsData,
  } = usePostItGroupsWithCardsQuery({
    variables: {
      where: {
        strategyId: Number(strategyId),
        step: Step.Positioning,
        substep: SubStep.TheWho,
        title: `What are the brand position that matter to key insights?`,
        preset: true,
      },
    },
    fetchPolicy: 'network-only',
  });

  const addCardsToGroups = useMemo(
    () =>
      insightGroups.reduce((acc, val) => {
        // find cards for each group
        return [
          ...acc,
          {
            ...val,
            cards:
              insightCardItems
                .filter((c) =>
                  c.postItGroupId === val.id &&
                  (!isLead ? c.user.country === user?.country : true)
                )
                .sort(({ pos: a }, { pos: b }) => {
                  return b - a;
                }) || [],
          },
        ];
      }, [] as PostItGroupAndCards[]),
    [insightCardItems, insightGroups, isLead, user?.country]
  );

  const whatAreTheBrandPositionGroup = groupsWithCardsData?.postItGroups?.items[0];

  const cardsByAlignmentOrder = useMemo(
    () => whatAreTheBrandPositionGroup?.cards || [],
    [whatAreTheBrandPositionGroup?.cards]
  );

  const insightTabs = useMemo(
    () =>
      cardsByAlignmentOrder
        .filter((e) => e.include === true)
        .map((card) => {
          const group = addCardsToGroups.find((group) => group.title === card.title);
          if (group) {
            return {
              ...group,
              pos: card.pos,
            };
          }
          return null;
        })
        .sort((a, b) => {
          if (a?.pos && b?.pos) {
            return b.pos - a.pos;
          }
          return 0;
        }) || []
          .filter((v) => !!v) as PostItGroupAndCards[],
    [addCardsToGroups, cardsByAlignmentOrder]
  );

  const theWhoStatementGroups = useMemo(() => {
    return insightTabs.map((group) => {
      return {
        id: group?.theWhyPageId,
        title: group?.title,
      };
    });
  }, [insightTabs]);

  const noWhoGroups = !theWhoStatementGroups?.length;

  const unsortedGroups = useMemo(() => groupItems.filter(g => g.theWhyPageId === +groupId), [groupId, groupItems])
  const groups = sortPostIts(unsortedGroups, cardItems);

  const onChange = usePostItCardMove(groups, updateCard);

  const noCardsData = !groups;

  const featuredCompetitors = useMemo(
    () =>
      uniqBy(
        stakeholderDefinitionsData?.stakeholderDefinitions?.items
          .reduce((acc, curr) => {
            const validCompetitors = curr?.CompetitorDetails?.map((cd) => cd?.Competitor) as CompetitorFragment[];

            if (!!validCompetitors) {
              acc.push(validCompetitors);
            }

            return acc;
          }, [] as CompetitorFragment[][])
          .flat() || [],
        'id'
      ),
    [stakeholderDefinitionsData?.stakeholderDefinitions?.items]
  );

  useEffect(() => {
    if (!theWhoStatementGroups) return;
    let url = `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCase(
      SubStep.TheWhy
    )}`;
    const isGroupIdUndefined = groupId === 'undefined' || groupId === 'null' || Number.isNaN(+groupId);

    if (groupId && !theWhoStatementGroups) setNoGroupModal(true);

    const didFindValidWhyPageId = theWhoStatementGroups.find(g => g.id === theWhyGroupId)

    if ((isGroupIdUndefined || !didFindValidWhyPageId) && theWhoStatementGroups[0]?.id) {
      url += `/${theWhoStatementGroups[0]?.id}`

      return history.replace(url);
    }

    if (groupId) return;

    const validTheWhyPageId = theWhoStatementGroups.filter(g => g.id !== null)
    // focused group
    if (validTheWhyPageId?.[0]?.id) {
      url += `/${validTheWhyPageId?.[0]?.id}`;
    } else {
      // Ensure the first group is selected by default
      const firstGroup = theWhoStatementGroups?.[0] || validTheWhyPageId?.[0];
      if (firstGroup) {
        url += `/${firstGroup.id}`;
      } else return;
    }
    return history.replace(url);
  }, [drugId, groupId, history, insightGroups, strategyId, theWhoStatementGroups, theWhyGroupId, theWhyPageData]);

  useEffect(() => {
    startPolling(polling.default);
    statementsStartPolling(polling.default);
    startStrategyPolling(polling.default);
    theWhyPageStartPolling(polling.default);
    return () => {
      stopPolling();
      statementsStopPolling();
      stopStrategyPolling();
      theWhyPageStopPolling();
    };
  }, [
    startPolling,
    startStrategyPolling,
    statementsStartPolling,
    statementsStopPolling,
    stopPolling,
    stopStrategyPolling,
    theWhyPageStartPolling,
    theWhyPageStopPolling
  ]);

  return (
    <>
      <ErrorModal
        title="Group no longer exists"
        text="The group you are looking for has been deleted."
        handleClose={() => setNoGroupModal(false)}
        visible={noGroupModal}
      />
      <SpaceOccupiedMenu
        open={spaceOccupied}
        setOpen={setSpaceOccupied}
        competitors={featuredCompetitors}
        drugId={+drugId}
        strategyId={+strategyId}
      />
      <SpaceOccupiedModal
        handleClose={() => setSpaceOccupiedModal(false)}
        competitors={featuredCompetitors}
        visible={spaceOccupiedModal}
        drugId={+drugId}
        strategyId={+strategyId}
      />
      <StepHeader isDesktop={isDesktop}>
        <Subtitle2 style={{ marginBottom: '5px' }}>
          Explore differentiation possibilities to inspire the brand target under each theme below.
        </Subtitle2>
        <div
          style={{
            whiteSpace: 'pre-wrap',
            marginBottom: '15px',
          }}
        >
          <BodyNormal color={colors.greyDark} style={{ display: 'inline' }}>
            Focus on the white space left by the competition.{' '}
          </BodyNormal>
          <ButtonLabel
            onClick={() => setSpaceOccupied(true)}
            style={{ display: 'inline', whiteSpace: 'nowrap' }}
            color={colors.purple}
          >
            View space occupied
          </ButtonLabel>
        </div>

        {noWhoGroups ? null : (
          <TabGroup componentName="ContributionTab">
            <>
              {theWhoStatementGroups.map(({ title, id }) => {
                if (!title) return null;
                return (
                  <div
                    key={id}
                    style={{ display: 'inline-block', padding: '0 2px' }}
                  >
                    <ContributionTab
                      key={id}
                      text={title}
                      onClick={() => {
                        history.push(
                          `/d/${drugId}/strategy/${strategyId}/3_1/${kebabCaseSubStep}/${id}`
                        );
                        setTheWhyGroupId(id)
                      }}
                      active={id === +groupId}
                      id={`contribution-tab-${id}`}
                      secondary=""
                      disableSidebar
                      sidebarOpen={false}
                      displaySummary={() => { }}
                      revealTruncatedText={false}
                    />
                  </div>
                );
              })}
            </>
          </TabGroup>
        )}
      </StepHeader>

      <ErrorWrapper
        isLoading={
          groupsLoading ||
          cardsLoading ||
          statementsLoading ||
          stakeholderDefinitionsLoading ||
          theWhyPageLoading
        }
        errors={[
          groupsError,
          cardsError,
          statementsError,
          theWhyPageError
        ]}
        dataMissing={
          noCardsData ||
          !statementsData ||
          !strategyData
        }
      >
        {noWhoGroups ? (
          <PostItsEmpty style={{ margin: 20 }} title="Nothing to discuss">
            <BodySmall color={colors.greyDark} style={{ display: 'inline' }}>
              Work can only begin when a Lead has chosen key insights in
            </BodySmall>

            <Link
              to={`/d/${drugId}/strategy/${strategyId}/3_1/the-who`}
              style={{
                color: colors.greyDark,
                display: 'inline',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {' '}
              Part 2
            </Link>
          </PostItsEmpty>
        ) : (
          <PageWrapper paddingTop={false} fullWidthMobile>
            <TheWhy
              strategyData={strategyData?.strategy}
              isDesktop={isDesktop}
              strategyId={Number(strategyId)}
              statements={statementsData?.statements?.items}
              step={Step.Positioning}
              substep={SubStep.TheWhy}
              groups={groups}
              addCard={createCard}
              removeCard={removeCard}
              updateCard={updateCard}
              handleCardChange={onChange}
              createGroup={createGroup}
              updateGroup={updateGroup}
              removeGroup={removeGroup}
              updateStatement={updateStatement}
              deleteGroupHide
              theWhyPage={theWhyPageData?.theWhyPage}
              isSpaceOccupiedOpen={spaceOccupied}
            />
          </PageWrapper>
        )}
      </ErrorWrapper>
    </>
  );
};
