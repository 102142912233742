import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { ErrorWrapper } from 'components/ErrorLoadingComponent';
import {
  Icon,
  BodySmall,
  BodyNormal,
  StepHeaderBar,
  Subtitle2,
  Toggle,
} from 'components/shared';
import { FutureTrend } from 'components/LongTermStrategy/FutureTrend';
import { black20ScrollBar } from 'components/Strategy/scrollbar';
import {
  FutureTrendFragment,
  useFutureTrendsQuery,
  Stakeholder,
  Step,
} from 'data/graphql/generated';
import { colors, polling } from 'constants/index';
import { ScrollableFadeContainer } from 'components/shared/ScrollableFadeContainer';
import { Navbar } from '../components/Navbar';
import { Observations } from '../components/Observations';
import useDesktop from 'hooks/useDesktop';
import { device } from 'utils/breakpoints';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  height: calc(100vh - 160px);
`;

const StyledStepHeaderBar = styled(StepHeaderBar)`
  padding: 15px;
  min-height: unset;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 0px;
`;

const StakeholdersWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100vw;
  padding: 20px 20px 5px 20px;
  flex: 1;
  scrollbar-gutter: stable;
  ${black20ScrollBar}
  margin-bottom: 5px;

  &::-webkit-scrollbar-track {
    margin: 0 5px;
  }
`;

const Stakeholders = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-self: stretch;
  gap: 15px;

  @media (min-width: 1687px) {
    justify-content: center;
  }
`;

const StakeholderColumn = styled.div`
  min-width: 280px;
  width: 25%;
  max-width: 400px;
  position: relative;
  max-height: 100%;
  display: flex;
  padding-top: 55px;
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
`;

const ColumnHeader = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
`;

const HeaderInside = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.black10};
  border-bottom: none;
  height: 55px;
  padding: 15px 130px 15px 15px;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  position: relative;
`;

const ToggleWrapper = styled.div`
  display: block;
  position: absolute;
  top: 17px;
  right: 10px;
`;

const StakeholderContent = styled.div`
  padding: 15px;
  background: ${colors.white};
  border: 1px solid ${colors.black10};
  border-top: none;
  border-radius: 0px 0px 5px 5px;
`;

const EmptyState = styled.div`
  background: ${colors.yellow20};
  border: 1px solid ${colors.greyMedium};
  height: auto;
  text-align: center;
  padding: 5px 5px 15px 5px;
  border-radius: 5px;

  img {
    margin: 18px auto;
  }
`;

const StepHeaderBarWrapper = styled.div`
  margin-top: 105px;

  @media ${device.tabletMin} {
    margin-top: 105px;
  }

  @media ${device.desktopMin} {
    margin-top: 0px;
  }
`;

const getIcon = (stakeholder: Stakeholder) => {
  switch (stakeholder) {
    case Stakeholder.Patient:
      return <Icon name="Patient" size={30} color={colors.purple} />;
    case Stakeholder.Healthcare:
      return <Icon name="HCP" size={30} color={colors.green} />;
    case Stakeholder.Payor:
      return <Icon name="Dollar" size={30} color={colors.blue} />;
    case Stakeholder.Provider:
      return <Icon name="Provider" size={30} color={colors.pink} />;
    case Stakeholder.Policymaker:
      return <Icon name="Policymaker" size={30} color={colors.pink} />;
  }
};

export const getTitle = (stakeholder: Stakeholder) => {
  switch (stakeholder) {
    case Stakeholder.Patient:
      return 'Patients';
    case Stakeholder.Healthcare:
      return 'Healthcare Professionals';
    case Stakeholder.Payor:
      return 'Payors';
    case Stakeholder.Provider:
      return 'Providers';
    case Stakeholder.Policymaker:
      return 'Policymakers';
  }
};

interface URLParams {
  drugId: string;
  strategyId: string;
}

export const LongTermStrategyFutureTrends = () => {
  const { drugId, strategyId }: URLParams = useParams();
  const isDesktop = useDesktop();
  const [focusedStakeholders, setFocusedStakeholders] = useState({
    [Stakeholder.Patient]: false,
    [Stakeholder.Healthcare]: false,
    [Stakeholder.Payor]: false,
    [Stakeholder.Provider]: false,
    [Stakeholder.Policymaker]: false,
  });

  const stakeholders = [
    Stakeholder.Patient,
    Stakeholder.Healthcare,
    Stakeholder.Payor,
    Stakeholder.Provider,
    Stakeholder.Policymaker,
  ];

  const {
    data,
    loading,
    error,
    startPolling,
    stopPolling,
  } = useFutureTrendsQuery({
    variables: {
      where: {
        strategyId: +strategyId,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  return (
    <>
      <Navbar
        disableSecondary={isDesktop ? true : false}
        navMenuChildren={null}
      />

      <StepHeaderBarWrapper>
        <Observations step={Step.KeyTrendsAnalysis} />
        <Page>
          <ErrorWrapper isLoading={loading} errors={[error]} dataMissing={false}>
            <StyledStepHeaderBar>
              <Subtitle2 style={{ marginBottom: '5px' }}>
                Consider which emerging trends/changes could create opportunity or
                risk beyond year 3.
              </Subtitle2>
              <BodyNormal
                color={colors.greyDark}
              >
                Discuss your thoughts in the comments. A Lead should make the
                final decision based on which create the greatest opportunity or
                introduce an important risk.
              </BodyNormal>
              <BodyNormal
                color={colors.greyDark}
              >
                Discuss your thoughts in the comments.
              </BodyNormal>
            </StyledStepHeaderBar>
            <StakeholdersWrapper>
              <Stakeholders>
                {stakeholders.map((stakeholder) => {
                  const trends: FutureTrendFragment[] =
                    data?.futureTrends?.items.filter((t) => {
                      if (t.stakeholder !== stakeholder) return false;
                      if (focusedStakeholders[stakeholder] && !t.focus) {
                        return false;
                      }
                      return true;
                    }) || [];
                  return (
                    <StakeholderColumn key={stakeholder}>
                      <ColumnHeader>
                        <HeaderInside>
                          {getIcon(stakeholder)}
                          <BodySmall color={colors.greyDark}>
                            {getTitle(stakeholder)}
                          </BodySmall>
                          <ToggleWrapper>
                            <Toggle
                              label="Focus only"
                              value={focusedStakeholders[stakeholder]}
                              onChange={() => {
                                setFocusedStakeholders({
                                  ...focusedStakeholders,
                                  [stakeholder]: !focusedStakeholders[
                                    stakeholder
                                  ],
                                });
                              }}
                              isSmall
                            />
                          </ToggleWrapper>
                        </HeaderInside>
                      </ColumnHeader>
                      <ScrollableFadeContainer>
                        <StakeholderContent>
                          {trends.length === 0 ? (
                            <EmptyState>
                              <img src="/empty.svg" width={115} alt="No trends" />
                              <Subtitle2
                                color={colors.greyDark}
                                style={{ marginBottom: 5 }}
                              >
                                {focusedStakeholders[stakeholder]
                                  ? 'No emerging trends / changes of focus'
                                  : 'No emerging trends / changes'}
                              </Subtitle2>
                              <div>
                                {focusedStakeholders[stakeholder] ? (
                                  <BodySmall
                                    color={colors.greyDark}
                                    style={{ display: 'inline' }}
                                  >
                                    Leads should choose where to focus
                                  </BodySmall>
                                ) : (
                                  <>
                                    <BodySmall
                                      color={colors.greyDark}
                                      style={{ display: 'inline' }}
                                    >
                                      Emerging trends / changes with alignment
                                      under{' '}
                                    </BodySmall>
                                    <Link
                                      to={`/d/${drugId}/strategy/${strategyId}/1_1/${stakeholder}`}
                                      style={{
                                        color: colors.greyDark,
                                        display: 'inline',
                                        fontSize: 14,
                                        fontWeight: 500,
                                      }}
                                    >
                                      Key Stakeholder Analysis{' '}
                                    </Link>
                                    <BodySmall
                                      color={colors.greyDark}
                                      style={{ display: 'inline' }}
                                    >
                                      will appear here
                                    </BodySmall>
                                  </>
                                )}
                              </div>
                            </EmptyState>
                          ) : (
                            trends.map((trend) => (
                              <FutureTrend
                                key={trend.id}
                                trend={trend}
                                stakeholder={stakeholder}
                              />
                            ))
                          )}
                        </StakeholderContent>
                      </ScrollableFadeContainer>
                    </StakeholderColumn>
                  );
                })}
              </Stakeholders>
            </StakeholdersWrapper>
          </ErrorWrapper>
        </Page>
      </StepHeaderBarWrapper>
    </>
  );
};
