import { colorsv2, texts } from 'constants/index';
import React from 'react';
import styled from 'styled-components';
import { BodySmall } from './TextStyles';
import { toCss } from 'utils/toCss';

export const Label = styled.label`
  display: block;
  position: relative;
  text-align: left;
  width: 100%;
`;

export const Span = styled.span<{ disabled?: boolean }>`
  ${() => toCss(texts.label100)}
  color: ${({ disabled }) => (disabled ? colorsv2.text.disabled : colorsv2.text.default)};
  display: inline-block;
  margin-bottom: 5px;
`;

export const Icon = styled.span<{ disabled?: boolean }>`
  position: absolute;
  width: 14px;
  height: 20px;
  right: 15px;
  top: calc(50% - 20px / 2);

  padding: 0 5px;
  background: white;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  color: ${({ disabled }) => (disabled ? colorsv2.text.disabled : colorsv2.indigo['800'])};
`;

export const ErrorContainer = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
`;

const ErrorText = styled(BodySmall)`
  ${() => toCss(texts.label200)}
  color: ${colorsv2.text.danger};
`;

export const ErrorMessage: React.FC<{
  message: string;
  style?: React.CSSProperties;
  className?: string;
}> = ({ message, style, className }) => (
  <ErrorContainer className={className} style={style}>
    <ErrorText>{message}</ErrorText>
  </ErrorContainer>
);

interface Props {
  name: string;
  title: string;
  icon?: string;
  disabled?: boolean;
  children: React.ReactNode;
  innerRef?: React.MutableRefObject<null>;
  className?: string;
}

export const FormField: React.FC<Props> = ({
  name,
  title,
  icon,
  disabled,
  children,
  innerRef,
  className,
}) => {
  return (
    <Label className={className} ref={innerRef} htmlFor={name}>
      <Span disabled={disabled}>{title}</Span>
      {icon && <Icon disabled={disabled}>{icon}</Icon>}
      {children}
    </Label>
  );
};

export default FormField;
