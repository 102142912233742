import React, { useEffect, useState } from 'react';

import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { ButtonPill } from 'components/shared';
import { ModalForm } from 'components/ModalForm';

const StyledButtonPill = styled.div`
//   margin-left: auto;
//   margin-bottom: 15px;
//   box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
//   border-radius: 25px;
`;


const SubTitle = styled.div`
color: var(--black, #141427);
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
`;

const PageWrapper = styled.div`
display: flex;
justify-content: center; /* Center horizontally */
align-items: center;     /* Center vertically */
height: 125px;           /* Set a height for vertical centering */
width: 125px;            /* Optional: set width if needed */
flex-direction: column;

@media ${device.tabletMax} {
  border:none;
}
`;
export const NAModalCompetitor: React.FC = () => {

  const [modalState, setModalState] = useState<Pick<
    ModalProps,
    'type' | 'heading' | 'name' | 'id'
  > | null>(null);

  return (
    <PageWrapper
    onClick={() => {
        setModalState({
          type: 'create',
          heading: 'This competitor is not applicable for this outcome',
        });
      }}
    >
      <NAModal
        handleClose={() => {
          setModalState(null);
        }}
        visible={!!modalState}
        heading={modalState?.heading}
        name={modalState?.name}
        id={modalState?.id}
        type={modalState?.type}
      />
     
      <StyledButtonPill
        
      >N/A</StyledButtonPill>

    </PageWrapper>
  );
};

interface ModalProps {
  type: 'create' | 'edit' | undefined;
  handleClose(): void;
  visible: boolean;
  heading?: string;
  name?: string;
  id?: number;
  
}

function NAModal({
  handleClose,
  visible,
  heading,
  name,
}: ModalProps) {

  const [, setTextValue] = useState(name || '');
  const [, setResponseError] = useState('');
  const [, setIsSubmitting] = useState(false);
  const [, setInputTouched] = useState(false);

  useEffect(() => {
    setTextValue(name || '');
  }, [name]);

  useEffect(() => {
    setResponseError('');

    return () => {
      setInputTouched(false);
      setIsSubmitting(false);
      setTextValue('');
      setResponseError('');
    };
  }, [visible]);

  return (
    <ModalForm
      handleClose={() => {
        handleClose();
      }}
      visible={visible}
      heading={heading}
    >
      <SubTitle>
      This competitor was not included for this outcome in the analysis.
      </SubTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 20,
          gap: 15,
        }}
      >
        <ButtonPill
          level="primary"
          width="180px"
          text="OK"
          onClick={() => {
            setResponseError('');
            handleClose();
          }}
        />
      </div>
    </ModalForm>
  );
}