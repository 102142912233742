export const texts = {
  // Headlines
  headline100Regular: {
    fontFamily: 'GTPressura, sans-serif',
    fontWeight: '400',
    fontSize: '40px',
    lineHeight: '50px'
  },
  headline200Regular: {
    fontFamily: 'GTPressura, sans-serif',
    fontWeight: '400',
    fontSize: '29px',
    lineHeight: '32px'
  },
  headline200Light: {
    fontFamily: 'GTPressura, sans-serif',
    fontWeight: '300',
    fontSize: '29px',
    lineHeight: '36px'
  },
  headline300Light: {
    fontFamily: 'GTPressura, sans-serif',
    fontWeight: '300',
    fontSize: '23px',
    lineHeight: '32px'
  },
  headline400Regular: {
    fontFamily: 'GTPressura, sans-serif',
    fontWeight: '400',
    fontSize: '17px',
    lineHeight: '24px'
  },
  headline400Light: {
    fontFamily: 'GTPressura, sans-serif',
    fontWeight: '300',
    fontSize: '17px',
    lineHeight: '24px'
  },

  subtitle400Regular: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '400',
    fontSize: '17px',
    lineHeight: '24px'
  },
  subtitle400Light: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '300',
    fontSize: '17px',
    lineHeight: '24px'
  },


  // Paragraphs
  paragraph100: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '24px'
  },
  paragraph200: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px'
  },
  paragraph300: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px'
  },

  // Buttons
  button100: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px'
  },
  button200: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px'
  },

  // Labels
  label100: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px'
  },
  label200: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px'
  },
}

export type Texts = keyof typeof texts;