import { Page, Wrapper } from 'components/Page';
import { Wrapper as StatementWrapper } from 'components/Positioning/Statement';
import { Wrapper as PostItsWrapper } from 'components/PostIts';
import { StepHeaderBar, StrategicSummary } from 'components/shared';
import { colors } from 'constants/colors';
import { SubStep } from 'data/graphql/generated';
import { kebabCase } from 'lodash';
import { FC } from 'react';
import { Redirect, Route, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { PositioningTabKebabCase } from '../../types';
import { CompetitorPositioningPage } from './CompetitorPositioningPage';
import { Navbar } from './Navbar';
import { TheWhoPage } from './TheWhoPage';
import { TheWhyPage } from './TheWhyPage';
// shared
export const PageWrapper = styled(Page)`
  padding-top: 15px;
  padding-bottom: 60px;
  ${Wrapper} {
    width: 100%;
    max-width: unset;

    @media ${device.desktopMin} {
      display: flex;
      justify-content: space-between;
    }
  }

  @media ${device.desktopMin} {
    padding: 20px;

    ${StatementWrapper} {
      margin-right: 0;
    }

    ${PostItsWrapper} {
      flex: 1;
    }
  }
`;

export const StepHeader = styled(StepHeaderBar)<{ isDesktop: boolean }>`
  display: block;
  padding: 15px 20px 0px;
  border-bottom: 0.5px solid ${colors.greyMedium};
  margin-top: ${({ isDesktop }) => (isDesktop ? '110px' : '155px')};
  min-height: auto;
  overflow: visible;
`;

const theWhoKebabCase = kebabCase(SubStep.TheWho);
const theWhyKebabCase = kebabCase(SubStep.TheWhy);
const competitorPositioningKebabCase = kebabCase(SubStep.CompetitorPositioning);

interface URLParams {
  drugId: string;
  strategyId: string;
  positioningTab: PositioningTabKebabCase;
  competitorId: string;
}

export const BrandPositioning: FC = () => {
  const {
    drugId,
    strategyId,
    positioningTab,
    competitorId,
  }: URLParams = useParams();
  let { path, url } = useRouteMatch();
  return (
    <>
      <StrategicSummary />

      <Navbar
        drugId={drugId}
        strategyId={strategyId}
        competitorId={competitorId}
        currentPositioningTab={positioningTab}
      />

      <Route path={`${path}/${competitorPositioningKebabCase}/:competitorId?`}>
        <CompetitorPositioningPage />
      </Route>

      <Route path={`${path}/${theWhoKebabCase}`}>
        <TheWhoPage />
      </Route>

      <Route path={`${path}/${theWhyKebabCase}/:groupId?`}>
        <TheWhyPage />
      </Route>

      {!positioningTab && (
        <Redirect to={`${url}/${competitorPositioningKebabCase}`} />
      )}
    </>
  );
};
