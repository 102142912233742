import React from 'react';
import { PostItGroupAndCards } from 'types';
import {
  Alignment,
  PostItCardInput,
  PostItCardType,
  Stakeholder,
  Step,
  SubStep,
  useAlignmentCreateMutation,
  CompetitiveCategory,
} from 'data/graphql/generated';
import { usePostItCardsPayload } from 'hooks/usePostItCards';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { device } from 'utils/breakpoints';
import { BodyNormal, BodySmall, Icon, Subtitle2 } from 'components/shared';
import { colors } from 'constants/colors';
import { AddNoteButton } from './AddNoteButton';
import { maxBy } from 'lodash';
import { OutcomesSortable, OutcomeCard } from './index';
import { useAuthContext } from 'contexts/AuthContext';
import {
  closestCorners,
  DndContext,
  DragCancelEvent,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent, 
  MouseSensor, 
  TouchSensor, 
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useParams } from 'react-router-dom';

// START: types
interface Props {
  step: Step;
  subStep?: SubStep;
  groups: PostItGroupAndCards[] | any;
  stakeholderDefinitionId: number;
  handleCardChange(
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    targetId?: string
  ): void;
  addCard: usePostItCardsPayload['createCard'];
  removeCard: usePostItCardsPayload['removeCard'];
  updateCard: usePostItCardsPayload['updateCard'];
  createGroupDisabled?: boolean;
  stakeholder: Stakeholder;
  loading: boolean;
  selectedPostItGroupId: number;
  sidebarOpen?: boolean;
  Content?: (groupId: number) => React.ReactNode;
  refetch(): void;
  hasNoSelectedCompetitor: boolean;
  hasNoTargetPopulation: boolean;
  movingOrSwapping: boolean;
  startPolling(): void;
  stopPolling(): void;
}
// END: types

// START: styled-components
const ActionWrapper = styled.div`
  height: 40px;
  margin-top: 10px;
`;

const GroupWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media ${device.tabletMax} {
    border: none;
    border-radius: 0px;
  }
`;

const GroupHeader = styled.div<{ centeredTitle: boolean }>`
  margin-bottom: 15px;
  display: block;
  justify-content: space-between;
  align-items: center;
  flex-wrap: ${({ centeredTitle }) => (centeredTitle ? 'nowrap' : 'wrap')};

  @media ${device.tabletMin} {
    justify-content: flex-start;
  }
`;

const GroupContent = styled.div<{ empty: boolean }>`
  display: flex;
  background: transparent;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0px 15px 15px 15px;
  overflow-y: auto;
`;

const GroupBody = styled.div`
  height: 100%;
  min-height: 100px;
`;

const GroupTitle = styled.div`
  align-items: center;
  display: inline-flex;
  width: 100%;
  position: relative;

  margin-top: 0;
  &::before,
  &::after {
  margin-left: 5px;
  margin-right: 5px;
    content: '';
    flex: 1 1 0;
    height: 0;
    border: 1px solid #141427B2;
  }

  & > span {
    color: #141427B2;
    font-size: 14px;
    font-family: 'ABC Favorit Trial';
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }
`;

const EmptyWrapper = styled.div`
  text-align: center;
  position: unset;
  pointer-events: none;
  z-index: 0;
  border-radius: 5px;
  transition: 0.3s;
`;
// END: styled-components

// START: components
export const EmptyState: React.FC<{ 
  text: string, 
  hasIcon?: boolean,
  style?: React.CSSProperties
}> = ({
  text,
  hasIcon = false,
  style = {}
}) => {
  return (
    <EmptyWrapper style={style}>
      {hasIcon && (
        <Icon
          name="GenericEmptyState"
          size={115}
          height={115}
          color="initial"
          style={{ margin: '0 auto' }}
        />
      )}
      <Subtitle2 color="#141427B2" style={{ fontSize: 20 }}>{text}</Subtitle2>
    </EmptyWrapper>
  );
};
// END: components


interface URLParams {
  drugId: string;
  strategyId: string;
  stakeholder: Stakeholder;
  category: CompetitiveCategory;
  stakeholderDefinitionId: string;
  competitorId: string;
}

export const OutcomesContext: React.FC<Props> = ({
  step,
  subStep,
  groups,
  hasNoSelectedCompetitor,
  hasNoTargetPopulation,
  stakeholder,
  selectedPostItGroupId,
  addCard,
  handleCardChange,
  refetch,
  removeCard,
  updateCard,
  stakeholderDefinitionId,
  loading,
  movingOrSwapping,
  startPolling,
  stopPolling,
}) => {
  const {
    category,
  }: URLParams = useParams();
  const [{ user }] = useAuthContext();
  // use hooks
  const [ createAlignment ] = useAlignmentCreateMutation();

  // variables
  const [ cards, setCards ] = React.useState<any>(groups);
  // @ts-ignore
  const highestCardPos: any = maxBy(cards.includes, 'pos')?.pos || 0;
  // active card state
  const [ activeCard, setActiveCard ] = React.useState<any>(null);
  // context processing state
  const [ processing, setProcessing ] = React.useState(false);
  // context focused state
  const [ focused, setFocused ] = React.useState(false);
  // add note loading state
  const [ addNoteLoading, setAddNoteLoading ] = React.useState(false);
  // memoized loading state
  const isLoading = (loading || processing || movingOrSwapping) && !focused;
  // create update key based on cards (used to prevent unpredictable behavior)
  const updateKey = React.useMemo(() => {
    // create includes key
    const includesKey = groups.includes.map((c: any) => `${c.id}-${c.title}`).join('-');
    // create excludes key
    const excludesKey = groups.excludes.map((c: any) => `${c.id}-${c.title}`).join('-');

    let key = `${stakeholder}`;
    if (includesKey.length) {
      key += `-inc-${includesKey}`;
    }

    if (excludesKey.length) {
      key += `-exc-${excludesKey}`;
    }

    // key to base 64
    return btoa(key);
  }, [groups, stakeholder]);

  // dnd-kit sensors
  // mouse sensor
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    }
  });
  // touch sensor
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5
    }
  });
  // sensors
  const sensors = useSensors(mouseSensor, touchSensor);

  /**
   * Handle add card
   * 
   * @param e {Pick<PostItCardInput>}
   * 
   * @returns {Promise<void>}
   */
  const handleAddCard = async (e: Pick<
    PostItCardInput, 
    "title" | "title" | "pos" | "postItGroup"
  >) => {
    setAddNoteLoading(true);
    
    const card = await addCard({
      postItGroup: selectedPostItGroupId,
      pos: (highestCardPos || 0) + 1000,
      title: e.title,
      category: category,
      ...(Step.Keystakeholders ? { step: Step.Keystakeholders, stakeholder, include: true } : undefined),
      // competitor: +competitorId,
      ...(stakeholderDefinitionId ? { stakeholderDefinition: Number(stakeholderDefinitionId) } : undefined),
      type: PostItCardType.Plain,
    });

    const collaborationId = card?.data?.postItCardCreate?.collaboration?.id ?? null;
    const strategyId = card?.data?.postItCardCreate?.strategyId ?? null;

    if (collaborationId && strategyId) {
      await createAlignment({
        variables: {
          data: { collaborationId, strategyId },
        },
        update: (cache, { data }) => {
          cache.modify({
            id: cache.identify({
              __ref: `Collaboration:${data?.alignmentCreate.collaborationId}`,
            }),
            fields: {
              alignmentCount(alignment) {
                return alignment + 1;
              },
              alignments(
                alignments: Pick<
                  Alignment, 'id' | 'createdAt' | 'collaborationId'
                >[]
              ) {
                return [...alignments, data?.alignmentCreate];
              },
            },
          });
        },
      });

      await refetch();
      setAddNoteLoading(false);
    }
  }

  /**
   * Handle drag start event
   * 
   * @param {DragStartEvent} event
   * 
   * @returns {void}
   */
  const handleDragStart = (event: DragStartEvent) => {
    stopPolling();

    // get event data
    const { active } = event;
    // get current data
    const data = active?.data?.current || {};

    setActiveCard((prev: any) => {
      if (prev && prev.id === active.id) {
        return prev;
      }

      let activeId = data?.sortable?.containerId;
      if (!activeId) {
        return null;
      }

      // find the card
      const card = cards[activeId].find((card: any) => card.id === active.id);
      if (!card) {
        return null;
      }

      return { ...card, ...data };
    });
  }

  /**
   * Handle drag over event
   * 
   * @param {DragOverEvent} event
   * 
   * @returns {void}
   */
  const handleDragOver = (event: DragOverEvent) => {
    // get event data
    const { active, over } = event;

    // get current data
    const activeData = active?.data?.current || {};
    const overData = over?.data?.current || {};

    // get the group id
    let activeId: any = activeData?.sortable?.containerId;
    let overId: any = over?.id;

    if (overData?.sortable) {
      overId = overData?.sortable?.containerId;
    }

    // exclude sorting
    if (!activeId
      || !overId
      || activeId === overId
    ) {
      return;
    }

    // NOTE: It is very important to do the
    // state updates inside the setStates to
    // avoid maximum update depth exceeded error
    setCards((prev: any) => {
      const activeCards = prev[activeId] || [];
      const overCards = prev[overId] || [];

      const activeIndex = activeCards.findIndex((card: any) => card.id === active.id);
      const overIndex = overCards.findIndex((card: any) => card.id === over?.id);

      let newIndex;
      if (overId in prev) {
        newIndex = overCards.length + 1;
      } else {
        // determine relative position of the active and over
        const activeRect = active?.rect?.current?.translated;
        const overRect = over?.rect;

        // determine the position and height
        const activeTop = activeRect?.top || 0;
        const overTop = overRect?.top || 0;
        const overHeight = overRect?.height || 0;


        // determine if below last item
        const isBelowLastItem = 
          over && activeTop > overTop + overHeight;

        // determine modifier
        const modifier = isBelowLastItem ? 1 : 0;
        // determine the new index
        newIndex = overIndex >= 0 ? overIndex + modifier : overCards.length + 1;
      }

      // re-index the cards
      let filtered = activeCards.filter((card: any) => card.id !== active.id);

      // create updated card
      const update = { 
        ...prev[activeId][activeIndex], 
        include: overId === 'includes'
      };

      // create a new state
      const state =  {
        ...prev,
        [activeId]: filtered,
        // @ts-ignore
        [overId]: [
          ...overCards.slice(0, newIndex),
          update,
          ...overCards.slice(newIndex),
        ],
      }

      return state;
    });
  }

  /**
   * Handle drag end event
   * 
   * @param {DragEndEvent} event
   */
  const handleDragEnd = (event: DragEndEvent) => {
    setActiveCard(null);

    // get event data
    const { active, over } = event;

    // get current data
    const activeData = active?.data?.current || {};
    const overData = over?.data?.current || {};

    // get the group id
    let activeId: any = activeData?.sortable?.containerId;
    let overId: any = over?.id;

    if (overData?.sortable) {
      overId = overData?.sortable?.containerId;
    }

    // // if drag end from one group to another
    // // can't really think of a better way to make
    // // this work to the new dnd-kit library
    // // but this seems smooth enough
    let shouldUpdate = true;
    if (activeCard.group !== overId) {
      // @ts-ignore
      let overIndex = cards[overId].findIndex((card: any) => card.id === over?.id);
      handleCardChange(activeCard.group, activeCard.index, overIndex, overId as any);
      shouldUpdate = false;
    }

    // make sure to limit to sorting
    if (!activeId 
      || !overId 
      || activeId !== overId
    ) {
      return;
    }

    // get the index
    let activeIndex = cards[activeId].findIndex((card: any) => card.id === active.id);
    // @ts-ignore
    let overIndex = cards[overId].findIndex((card: any) => card.id === over?.id);


    // invalid index?
    if (activeIndex < 0 || overIndex < 0) {
      return;
    }

    // update the state
    if (activeIndex !== overIndex) {
      setProcessing(true);
      // update cache
      setCards((prev: any) => {
        return {
          ...prev,
          // @ts-ignore
          [overId]: arrayMove(prev[overId], activeIndex, overIndex),
        }
      });

      // update global state
      if (shouldUpdate) {
        handleCardChange(activeId, activeIndex, overIndex);
      }

      setProcessing(false);
    }
  }

  /**
   * Handle drag cancel event
   * 
   * @param {DragCancelEvent} event
   * 
   * @returns {void}
   */
  const handleDragCancel = (event: DragCancelEvent) => {
    setActiveCard(null);
    setCards(groups);
  }

  /**
   * Disable polling when loading
   * 
   * @returns {void}
   */
  React.useEffect(() => {
    if (movingOrSwapping || processing) {
      stopPolling();
    } else {
      startPolling();
    }
  }, [
    movingOrSwapping, 
    processing, 
    startPolling, 
    stopPolling
  ]);

  /**
   * FIX: Unpredictable behavior when
   * after dragging / updating the cards
   * only update if update key changes
   * 
   * @returns {void}
   */
  React.useEffect(() => {
    // console.log('Update state: ', updateKey);
    setCards((prev: any) => groups);
  }, [updateKey, groups]);

  return (
    <GroupWrapper>
      {(hasNoTargetPopulation || hasNoSelectedCompetitor) ? (
        <GroupContent empty className="group-content" style={{ justifyContent: 'center' }}>
          <GroupHeader centeredTitle>
            <Transition timeout={100} component={null}>
              {(state) => (
                <div style={{ textAlign: 'center', marginTop: 50 }}>
                  <EmptyState
                    hasIcon
                    text={`Cannot add ${category?.toLowerCase()} yet`}
                  />
                  
                  <BodySmall color={colors.greyDark} style={{ marginTop: 10 }}>
                    Work can only begin when competitors are defined.
                  </BodySmall>
                </div>
              )}
            </Transition>
          </GroupHeader>
        </GroupContent>
      ) : (
        <>
          <GroupHeader centeredTitle style={{ margin: 10, marginBottom: 25 }}>
            <ActionWrapper>
              <AddNoteButton
                loading={addNoteLoading}
                group={cards as any}
                step={step}
                subStep={subStep}
                addCard={handleAddCard}
                cardArgs={{
                  postItGroup: selectedPostItGroupId,
                  pos: highestCardPos ? highestCardPos + 1000 : 1000,
                  stakeholder,
                  title: '',
                }} 
              />
            </ActionWrapper>
          </GroupHeader>

          <GroupContent 
            empty={!!cards} 
            className={`group-content ${movingOrSwapping ? 'items-saving' : ''}`}
          >
            <DndContext
              collisionDetection={closestCorners}
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDragEnd={handleDragEnd}
              onDragCancel={handleDragCancel}
              modifiers={[restrictToVerticalAxis]}
              sensors={sensors}
            >
              <DragOverlay dropAnimation={{ duration: 150, easing: 'linear' }}>
                <OutcomeCard
                  style={{ visibility: activeCard ? 'visible' : 'hidden' }}
                  id={activeCard?.id}
                  key={activeCard?.id}
                  card={activeCard || {}}
                  cards={cards[activeCard?.group || ''] || []}
                  userId={user?.id || 0}
                  userRole={user?.role || null}
                  isOverlay={true}
                  loading={isLoading}
                />
              </DragOverlay>

              <OutcomesSortable 
                id="includes" 
                items={cards.includes.map((c: any) => c.id)}
                disabled={isLoading}
              >
                <GroupBody style={{ marginBottom: cards.includes.length ? 20 : 0 }}>
                  <GroupTitle>
                    <BodyNormal color="#141427B2">Includes</BodyNormal>
                  </GroupTitle>

                  {cards?.includes?.map((card: any, i: number) => {
                    return (
                      <OutcomeCard
                        id={card.id}
                        key={`includes-${card.id}`}
                        card={{ ...card, index: i, group: 'includes' }}
                        cards={[...cards.includes, ...cards.excludes]}
                        userId={user?.id || 0}
                        userRole={user?.role || null}
                        isActive={activeCard?.id === card.id}
                        updateCard={updateCard}
                        removeCard={removeCard}
                        refetch={refetch}
                        loading={isLoading}
                        onFocus={(focused: boolean) => setFocused(focused)}
                        onBlur={(processing: boolean) => setProcessing(processing)}
                      />
                    )
                  })}

                  {!cards?.includes?.length && (
                    <EmptyState 
                      style={{ margin: '26px 0' }}
                      text={`No ${category?.toLowerCase()} to include`} 
                    />
                  )}
                </GroupBody>
              </OutcomesSortable>

              <OutcomesSortable 
                id="excludes" 
                items={cards.excludes.map((c: any) => c.id)}
                disabled={isLoading}
              >
                <GroupBody>
                  <GroupTitle>
                    <BodyNormal color="#141427B2">Excludes</BodyNormal>
                  </GroupTitle>

                  {cards?.excludes?.map((card: any, i: number) => {
                    return (
                      <OutcomeCard
                        id={card.id}
                        key={`excludes-${card.id}`}
                        card={{ ...card, index: i, group: 'excludes' }}
                        cards={[...cards.includes, ...cards.excludes]}
                        userId={user?.id || 0}
                        userRole={user?.role || null}
                        isActive={activeCard?.id === card.id}
                        updateCard={updateCard}
                        removeCard={removeCard}
                        refetch={refetch}
                        loading={isLoading}
                        onFocus={(focused: boolean) => setFocused(focused)}
                        onBlur={(processing: boolean) => setProcessing(processing)}
                      />
                    )
                  })}

                  {!cards?.excludes?.length && (
                    <EmptyState 
                      style={{ margin: '26px 0' }}
                      text={`No ${category?.toLowerCase()} left out`} 
                    />
                  )}
                </GroupBody>
              </OutcomesSortable>
            </DndContext>
          </GroupContent>
        </>
      )}
    </GroupWrapper>
  )
}