import { colorsv2, texts } from 'constants/index';
import styled, { css } from 'styled-components';
import { toCss } from 'utils/toCss';

interface Props {
  color?: string;
  wordWrap?: string;
}

export const Heading1 = styled.h1<Props>`
  ${() => toCss(texts.headline100Regular)}
  margin: 0;
  color: ${({ color }) => color || colorsv2.text.default};
  cursor: default;
  pointer-events: none;
`;

export const Heading2 = styled.h2<Props>`
  ${() => toCss(texts.headline200Light)}
  margin: 0;
  color: ${({ color }) => color || colorsv2.text.default};
  cursor: default;
  pointer-events: none;
`;

export const Heading3 = styled.h3<Props>`
  ${() => toCss(texts.headline300Light)}
  margin: 0;
  color: ${({ color }) => color || colorsv2.text.default};
  pointer-events: none;
  cursor: default;
`;

export const Subtitle1 = styled.p<Props>`
  ${() => toCss(texts.subtitle400Regular)};
  margin: 0;
  color: ${({ color }) => color || colorsv2.text.default};
  pointer-events: none;
  cursor: default;
`;

export const Subtitle2 = styled.p<Props>`
  ${() => toCss(texts.subtitle400Light)};
  margin: 0;
  color: ${({ color }) => color || colorsv2.text.default};
  pointer-events: none;
  cursor: default;
`;

export const BodyExtraLarge = styled.p<Props>`
  ${() => toCss(texts.paragraph100)}
  margin: 0;
  color: ${({ color }) => color || colorsv2.text.default};
  pointer-events: none;
  cursor: default;
`;

export const BodyLarge = styled.p<Props>`
  ${() => toCss(texts.paragraph100)}
  margin: 0;
  color: ${({ color }) => color || colorsv2.text.default};
  pointer-events: none;
  cursor: default;
`;

export const BodyNormal = styled.p<Props>`
  ${() => toCss(texts.paragraph200)}
  margin: 0;
  color: ${({ color }) => color || colorsv2.text.default};
  pointer-events: none;
  cursor: default;
`;

export const BodySmallStyle = css<Props>`
  ${() => toCss(texts.paragraph300)}
  word-wrap: ${({ wordWrap }) => wordWrap || 'break-word'};
  margin: 0;
  color: ${({ color }) => color || colorsv2.text.default};
  pointer-events: none;
  cursor: default;
`;

export const BodySmall = styled.p<Props>`
  ${BodySmallStyle}
`;

export const BodyExtraSmall = styled.p<Props>`
  ${() => toCss(texts.paragraph300)}
  margin: 0;
  color: ${({ color }) => color || colorsv2.text.default};
  cursor: default;
`;

export const ButtonLabel = styled.p<Props>`
  ${() => toCss(texts.button200)}
  margin: 0;
  color: ${({ color }) => color || colorsv2.text.default};
  cursor: pointer;
`;

export const Caption = styled.p<Props>`
  ${() => toCss(texts.label200)}
  margin: 0;
  color: ${({ color }) => color || colorsv2.text.default};
  pointer-events: none;
  cursor: default;
`;
