import { PostIts } from 'components/PostIts';
import {
  StatementFragment,
  Step,
  StrategyFragment,
  SubStep,
  TheWhyPageFragment,
  useStatementUpdateMutation,
  useTheWhyPageUpdateMutation,
} from 'data/graphql/generated';
import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { BodySmall, ButtonPill, StatementV2, Tooltip } from 'components/shared';
import { StatementWrapper } from './StatementV2';
import { useAuthContext } from 'contexts/AuthContext';
import { colors } from 'constants/index';
import { BulletPointWithText } from './Statement';
import { ModalForm } from 'components/ModalForm';
import { EditableUnderlineTextDiv } from 'components/shared/EditableUnderlineTextDiv';
import { ButtonsContainer } from 'components/CompetitiveLandscape/CreateUpdateCompetitorModal';
import { StatementModal } from './StatementModal';
import { device } from 'utils/breakpoints';
import { isTouchDevice } from 'utils/isTouchDevice';
import { verifyUserRole } from 'utils/verifyUserRole';
import FocusDropdown from '../shared/FocusDropdown';
import useManageFocusRationale from '../../hooks/useManageFocusRationale';
import { RationaleModal } from '../shared/RationaleModal';
import { useWidth } from 'hooks/useWidth';

const FocusWrapper = styled.div`
  margin-bottom: 15px;
`;

const StatementCover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
`;

const StyledStatementV2 = styled(StatementV2)``;

const StatementsScrollWrapper = styled.div<{
  isTouchDevice: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 315px;

  @media ${device.tabletMax} {
    flex-direction: row;
    width: auto;
    overflow-x: scroll;
    padding: 15px;
    margin-top: -15px;
    background: ${colors.greyLight};

    > ${StyledStatementV2} {
      width: 315px;
      flex: none;
    }
    ${({ isTouchDevice }) => {
    if (isTouchDevice) {
      return css`
          &::-webkit-scrollbar {
            /*Chrome, Safari and Opera */
            display: none;
          }

          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        `;
    }
  }}
  }
`;

const StatementsWrapper = styled.div<{
  edgeFade: { left: boolean; right: boolean };
}>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 315px;

  @media ${device.tabletMax} {
    position: relative;
    width: 100%;
    ::after {
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      position: absolute;
      pointer-events: none;
      background-image: ${`linear-gradient(
        90deg,
        rgba(232, 232, 233, 0) 90%,
        ${colors.greyLight} 100%
      )`};
      opacity: ${({ edgeFade }) => Number(edgeFade.right)};
      transition: opacity 0.3s;
    }

    ::before {
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      position: absolute;
      pointer-events: none;
      background-image: ${`linear-gradient(
        90deg,
        ${colors.greyLight} 0%,
        rgba(232, 232, 233, 0) 10%
      )`};
      opacity: ${({ edgeFade }) => Number(edgeFade.left)};
      transition: opacity 0.3s;
    }
  }
`;

export const StyledBulletPointWithText = styled(BulletPointWithText)`
  cursor: pointer;

  .bullet-point-text {
    background: ${colors.purple10};
  }
`;

const Wrapper = styled.div<{ isSpaceOccupiedOpen: boolean; width: number }>`
  width: 100%;
  display: flex;
  gap: 15px;

  @media ${device.tabletMax} {
    flex-direction: column-reverse;
  }

  ${({ isSpaceOccupiedOpen, width }) => {
    if (isSpaceOccupiedOpen) {
      const calculatedWidth = width / 2 - 355;
      return css`
        transition: all 0.3s;
        width: calc(40% + ${calculatedWidth}px);
        position: absolute;

        @media ${device.tabletMax} {
          transform: translateX(0);
          width: 100%;
        }
      `;
    }
  }}
`;

interface Props {
  strategyId: number;
  statements: StatementFragment[] | undefined;
  step: Step;
  substep: SubStep;
  isDesktop?: boolean;
  strategyData: StrategyFragment | undefined | null;
  updateStatement: ReturnType<typeof useStatementUpdateMutation>[0];
  theWhyPage: TheWhyPageFragment | undefined | null;
  isSpaceOccupiedOpen: boolean;
}

export const TheWhy = ({
  groups,
  addCard: createCard,
  removeCard,
  updateCard,
  handleCardChange: onChange,
  createGroup,
  updateGroup,
  removeGroup,
  statements,
  strategyData,
  updateStatement,
  theWhyPage,
  isSpaceOccupiedOpen,
}: Props & Omit<React.ComponentProps<typeof PostIts>, 'step'>) => {
  const [scrollAvailable, setScrollAvailable] = useState({
    left: false,
    right: true,
  });
  const width = useWidth();
  const [{ user }] = useAuthContext();
  const { isLead } = verifyUserRole(user?.role, user?.country);
  const drugName = strategyData?.drug?.name;
  const whatItIsStatement = statements?.filter(
    (statement) => statement.title.toLowerCase() === 'what it is'.toLowerCase()
  )?.[0];
  const benefitsStatement = statements?.filter(
    (statement) => statement.title.toLowerCase() === 'benefits'
  )[0];
  const reasonsToBelieveStatement = statements?.filter(
    (statement) =>
      statement.title.toLowerCase() === 'reasons to believe'.toLowerCase()
  )[0];

  const statementText = reasonsToBelieveStatement?.text.filter((v) => !!v);

  const [
    whatItIsStatementModalIsOpen,
    setWhatItIsStatementModalIsOpen,
  ] = useState(false);
  const [
    benefitsStatementModalIsOpen,
    setBenefitsStatementModalIsOpen,
  ] = useState(false);
  const [
    reasonsToBelieveStatementModalIsOpen,
    setReasonsToBelieveStatementModalIsOpen,
  ] = useState(false);

  const isTouch = isTouchDevice();

  // Focus rationale modal logic
  const {
    modalSlideIdx,
    setModalSlideIdx,
    setShowSingleSlide,
    closeModal,
  } = useManageFocusRationale();

  const [updateTheWhyPage] = useTheWhyPageUpdateMutation();

  async function onFocusClick(e: React.MouseEvent<HTMLDivElement>) {
    if (!theWhyPage) return;

    if (theWhyPage.focus) {
      try {
        await updateTheWhyPage({
          variables: {
            id: theWhyPage.id,
            data: {
              focus: !theWhyPage.focus,
              focusRationale: "",
            },
          },
        });
      } catch (error) {
        console.error(error);
        alert('Something went wrong');
      }
    } else {
      setModalSlideIdx(1);
    }
  }

  async function handleRationaleSubmit(rationale: string) {
    if (!theWhyPage) return;

    try {
      await updateTheWhyPage({
        variables: {
          id: theWhyPage?.id,
          data: {
            focus: true,
            focusRationale: rationale,
          },
        },
      });
    } catch (error) {
      console.error(error);
      alert('Something went wrong');
    }
  }

  return (
    <>
      <Tooltip id={'statement__tooltip'} effect="float" place="right" />


      <WhatItIsStatementModal
        drugName={drugName}
        setIsOpen={setWhatItIsStatementModalIsOpen}
        isOpen={whatItIsStatementModalIsOpen}
        statementText={whatItIsStatement?.text || []}
        statementId={whatItIsStatement?.id}
        updateStatement={updateStatement}
        heading={whatItIsStatement?.title || ''}
      />
      <BenefitsStatementModal
        drugName={drugName}
        setIsOpen={setBenefitsStatementModalIsOpen}
        isOpen={benefitsStatementModalIsOpen}
        statementText={benefitsStatement?.text || []}
        statementId={benefitsStatement?.id}
        updateStatement={updateStatement}
        heading={benefitsStatement?.title || ''}
      />
      <StatementModal
        placeholder={'A key reason to believe'}
        closeModal={() => setReasonsToBelieveStatementModalIsOpen(false)}
        visible={reasonsToBelieveStatementModalIsOpen}
        title={`Reasons to believe`}
        subTitle={
          'Summarise the reasons to believe. Each new line will create a new bullet point.'
        }
        onUpdate={async (v) => {
          if (!isLead) return;
          const trimmedValidValues = v
            .filter((arr) => !!arr)
            .map((s) => s.trim());

          if (reasonsToBelieveStatement?.id) {
            await updateStatement({
              optimisticResponse: {
                statementUpdate: {
                  ...reasonsToBelieveStatement,
                  text: trimmedValidValues,
                },
              },
              variables: {
                id: reasonsToBelieveStatement?.id,
                data: {
                  text: trimmedValidValues,
                },
              },
            });
          }
        }}
        statement={statementText}
      />
      <Wrapper isSpaceOccupiedOpen={isSpaceOccupiedOpen} width={width}>
        <PostIts
          step={Step.Positioning}
          subStep={SubStep.TheWhy}
          groups={groups}
          addCard={createCard}
          removeCard={removeCard}
          updateCard={updateCard}
          handleCardChange={onChange}
          createGroup={createGroup}
          updateGroup={updateGroup}
          removeGroup={removeGroup}
          createGroupHide
          deleteGroupHide
        />
        <StatementsWrapper edgeFade={scrollAvailable}>
          <StatementsScrollWrapper
            isTouchDevice={isTouch}
            onScroll={(e) => {
              const div = e.target as HTMLDivElement;

              setScrollAvailable({
                left: div.scrollLeft > 0,
                right: div.offsetWidth + div.scrollLeft !== div.scrollWidth,
              });
            }}
          >
            <StyledStatementV2
              title="Frame of Reference (What it is)"
              header={
                <FocusWrapper>
                  <FocusDropdown
                    disabled={!isLead}
                    onClick={onFocusClick}
                    checked={!!theWhyPage?.focus}
                    setModalSlideIdx={setModalSlideIdx}
                    setShowSingleSlide={setShowSingleSlide}
                    rationaleText={theWhyPage?.focusRationale || ''}
                  />
                  <RationaleModal
                    modalSlideIdx={modalSlideIdx}
                    handleClose={closeModal}
                    handleSubmit={handleRationaleSubmit}
                    rationaleText={theWhyPage?.focusRationale || ''}
                    showSingleSlide={true}
                  />
                </FocusWrapper>
              }
            >
              <StatementWrapper canEdit={isLead}>
                <BodySmall>{drugName} is </BodySmall>
                <BodySmall
                  data-for={'statement__tooltip'}
                  data-tip={isLead ? '' : 'Only Leads can edit'}
                  onClick={() => {
                    if (isLead) setWhatItIsStatementModalIsOpen(true);
                  }}
                  className="statement__text--highlight cypress-statement-input"
                  style={{
                    color: !!whatItIsStatement?.text[0]
                      ? colors.black
                      : colors.greyDark,
                  }}
                >
                  {whatItIsStatement?.text[0] ||
                    'a type of drug or device category.'}
                </BodySmall>
              </StatementWrapper>
            </StyledStatementV2>

            <StyledStatementV2
              title="Benefit (Emotional and Functional)"
              collaboration={benefitsStatement?.collaboration}
              totalUsers={strategyData?.users.length}
            >
              <StatementWrapper canEdit={isLead}>
                <BodySmall>{drugName} </BodySmall>
                <BodySmall
                  data-for={'statement__tooltip'}
                  data-tip={isLead ? '' : 'Only Leads can edit'}
                  onClick={() => {
                    if (isLead) setBenefitsStatementModalIsOpen(true);
                  }}
                  className="statement__text--highlight"
                  style={{
                    color: !!benefitsStatement?.text[0]
                      ? colors.black
                      : colors.greyDark,
                  }}
                >
                  {benefitsStatement?.text[0] ||
                    'provides a functional benefit'}
                </BodySmall>
                <BodySmall> so that </BodySmall>
                <BodySmall
                  data-for={'statement__tooltip'}
                  data-tip={isLead ? '' : 'Only Leads can edit'}
                  onClick={() => {
                    if (isLead) setBenefitsStatementModalIsOpen(true);
                  }}
                  className="statement__text--highlight"
                  style={{
                    color: !!benefitsStatement?.text[1]
                      ? colors.black
                      : colors.greyDark,
                  }}
                >
                  {benefitsStatement?.text[1] || ' an emotional benefit.'}
                </BodySmall>
              </StatementWrapper>
            </StyledStatementV2>
            <StyledStatementV2
              title="Reasons To Believe"
              collaboration={reasonsToBelieveStatement?.collaboration}
              totalUsers={strategyData?.users.length}
            >
              <div
                data-for={'statement__tooltip'}
                data-tip={isLead ? '' : 'Only Leads can edit'}
                style={{ position: 'relative' }}
              >
                <StatementCover
                  style={{
                    cursor: isLead ? 'pointer' : 'not-allowed',
                    pointerEvents: !isLead ? 'all' : 'none',
                  }}
                />
                {!!statementText?.length ? (
                  statementText?.map((text, idx) => (
                    <StyledBulletPointWithText
                      key={idx}
                      text={text}
                      textStyle={{
                        pointerEvents: isLead ? 'all' : 'none',
                        cursor: 'pointer',
                        wordBreak: 'break-word',
                        hyphens: 'auto',
                      }}
                      color={colors.black}
                      onClick={() => {
                        isLead && setReasonsToBelieveStatementModalIsOpen(true);
                      }}
                    />
                  ))
                ) : (
                  <StyledBulletPointWithText
                    text={'A key reason to believe'}
                    textStyle={{
                      pointerEvents: isLead ? 'all' : 'none',
                      cursor: 'pointer',
                      wordBreak: 'break-word',
                      hyphens: 'auto',
                    }}
                    color={colors.greyDark}
                    onClick={() => {
                      isLead && setReasonsToBelieveStatementModalIsOpen(true);
                    }}
                  />
                )}
              </div>
            </StyledStatementV2>
          </StatementsScrollWrapper>
        </StatementsWrapper>
      </Wrapper>
    </>
  );
};

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  white-space: pre-wrap;

  > * {
    display: inline-block;
  }
`;

interface ModalProps {
  drugName?: string;
  setIsOpen(v: boolean): void;
  isOpen: boolean;
  statementText: string[];
  statementId?: number;
  updateStatement: Props['updateStatement'];
  heading: string;
}

const WhatItIsStatementModal = ({
  drugName,
  setIsOpen,
  isOpen,
  statementText,
  statementId,
  updateStatement,
  heading,
}: ModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [text, setText] = useState(statementText);

  return (
    <ModalForm
      heading={heading}
      message="Enter the competitive category."
      handleClose={() => {
        setIsOpen(false);
      }}
      visible={isOpen}
    >
      <ModalContentWrapper>
        <BodySmall>{drugName} is </BodySmall>
        <EditableUnderlineTextDiv
          style={{ minWidth: 213 }}
          placeholder="type of drug or device category."
          suppressContentEditableWarning
          contentEditable
          onInput={(s) => {
            setText((text) => {
              const textCopy = text.slice();
              textCopy[0] = s;
              return textCopy;
            });
          }}
          initialValue={statementText[0]}
        />

        <ButtonsContainer
          style={{ width: '100%', display: 'flex', marginTop: 30 }}
        >
          <ButtonPill
            onClick={() => {
              setIsOpen(false);
            }}
            text="Cancel"
            level="secondary"
          />
          <ButtonPill
            clickClassName="cypress-rationale-save"
            onClick={async () => {
              setIsLoading(true);
              if (!!statementId) {
                await updateStatement({
                  variables: {
                    id: statementId,
                    data: {
                      text: text,
                    },
                  },
                });
              }
              setIsLoading(false);
              setIsOpen(false);
            }}
            text="Save"
            loading={isLoading}
            disabled={isLoading}
          />
        </ButtonsContainer>
      </ModalContentWrapper>
    </ModalForm>
  );
};

const BenefitsStatementModal = ({
  drugName,
  setIsOpen,
  isOpen,
  statementText,
  statementId,
  updateStatement,
  heading,
}: ModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [text, setText] = useState(statementText);

  return (
    <ModalForm
      heading={heading}
      message="Summarise the functional benefit followed by the emotional benefit to the key stakeholder."
      handleClose={() => {
        setIsOpen(false);
      }}
      visible={isOpen}
    >
      <ModalContentWrapper>
        <BodySmall>{drugName} </BodySmall>
        <EditableUnderlineTextDiv
          style={{ minWidth: 183 }}
          placeholder="provides a functional benefit"
          suppressContentEditableWarning
          contentEditable
          onInput={(s) => {
            setText((text) => {
              const textCopy = text.slice();
              textCopy[0] = s;
              return textCopy;
            });
          }}
          initialValue={statementText[0]}
        />
        <BodySmall> so that </BodySmall>
        <EditableUnderlineTextDiv
          style={{ minWidth: 136 }}
          placeholder="an emotional benefit."
          suppressContentEditableWarning
          contentEditable
          onInput={(s) => {
            setText((text) => {
              const textCopy = text.slice();
              textCopy[1] = s;
              return textCopy;
            });
          }}
          initialValue={statementText[1]}
        />
        <ButtonsContainer
          style={{ width: '100%', display: 'flex', marginTop: 30 }}
        >
          <ButtonPill
            onClick={() => {
              setIsOpen(false);
            }}
            text="Cancel"
            level="secondary"
          />
          <ButtonPill
            onClick={async () => {
              setIsLoading(true);
              if (!!statementId) {
                await updateStatement({
                  variables: {
                    id: statementId,
                    data: {
                      text: text,
                    },
                  },
                });
              }
              setIsLoading(false);
              setIsOpen(false);
            }}
            text="Save"
            loading={isLoading}
            disabled={isLoading}
          />
        </ButtonsContainer>
      </ModalContentWrapper>
    </ModalForm>
  );
};
