import { blocks, themes } from 'constants/index';
import { ProductOnDrugs } from 'data/graphql/generated';

export type ExtendedProductOnDrugs = ProductOnDrugs & { block: any };

export const getThemeKey = (feature: any) => {
  let themeKey: string | null = null;
  for(let i in themes) {
    const theme = themes[i];

    if (theme.tools?.[feature]) {
      themeKey = theme.key;
      break;
    }
  }

  return themeKey;
}

export const getThemeTool = (feature: any) => {
  let key = getThemeKey(feature) || '';
  let theme = themes[key];

  if (!theme) {
    return {};
  }

  return theme.tools[feature] || {};
}

export const getPrevThemeTool = (feature: any) => {
  let key = getThemeKey(feature) || '';
  let theme = themes?.[key];

  if (!theme) {
    return {};
  }

  let tools = Object.keys(theme.tools);
  let index = tools.indexOf(feature);

  let tool: any = Object.values(theme.tools)[index - 1] || {};
  let toolKey: any = Object.keys(theme.tools)[index - 1];

  if (!tool.name) {
    // get prev theme
    let prevTheme: any = Object.values(themes)[Object.keys(themes).indexOf(key) - 1];
    if (!prevTheme) {
      return;
    }

    let prevTools = Object.keys(prevTheme.tools);
    tool = prevTheme.tools[prevTools[prevTools.length - 1]];
    toolKey = prevTools[prevTools.length - 1];
  }

  return { ...tool, key: toolKey };
}

export const getNextThemeTool = (feature: any) => {
  let key = getThemeKey(feature) || '';
  let theme = themes?.[key];

  if (!theme) {
    return {};
  }

  let tools = Object.keys(theme.tools);
  let index = tools.indexOf(feature);

  let tool: any = Object.values(theme.tools)[index + 1] || {};
  let toolKey: any = Object.keys(theme.tools)[index + 1];

  if (!tool.name) {
    // get next theme
    let nextTheme: any = Object.values(themes)[Object.keys(themes).indexOf(key) + 1];
    if (!nextTheme) {
      return;
    }

    let nextTools = Object.keys(nextTheme.tools);
    tool = nextTheme.tools[nextTools[0]];
    toolKey = nextTools[0];
  }

  return { ...tool, key: toolKey };
}

export const getPrevBlockTool = (feature: any) => {
  let block = getActiveBlockCache() || '';
  let current = blocks[block];
  if (!current) {
    return {};
  }

  let tools = current.tools;
  let index = tools.indexOf(feature);

  if (!tools?.[index - 1]) {
    return {};
  }
  return { ...getThemeTool(tools[index - 1]), key: tools[index - 1] };
}

export const getNextBlockTool = (feature: any) => {
  let block = getActiveBlockCache() || '';
  let current = blocks[block];
  if (!current) {
    return {};
  }

  let tools = current.tools;
  let index = tools.indexOf(feature);

  if (!tools?.[index + 1]) {
    return {};
  }

  return { ...getThemeTool(tools[index + 1]), key: tools[index + 1] };
}

export const getBlockKeys = (feature: any) => {
  // get all block keys from `blocks` that contains the feature
  let blockKeys: string[] = [];
  for(let i in blocks) {
    const block = blocks[i];
    if (block.tools.includes(feature)) {
      blockKeys.push(block.key);
    }
  }

  return blockKeys;
}

export const getBlockKeyByFeatures = (features: string[]) => {
  let blockKey: string | null = null;
  for(let i in blocks) {
    const block = blocks[i];
    let toolsKey: any = block.tools.sort((a: any, b: any) => a - b);
    toolsKey = toolsKey.join('_');

    let featuresKey: any = features.sort((a: any, b: any) => a - b);
    featuresKey = featuresKey.join('_');

    if (toolsKey === featuresKey) {
      blockKey = block.key;
      break;
    }
  }

  return blockKey;
} 

export const sortProductOnDrugs = (products: any): ExtendedProductOnDrugs[] | any => {
  if (!products) {
    return;
  }

  let productBlocks: any = {};
  for(let i in products) {
    const product = products[i].Product;
    let features = product?.features?.map((feature: any) => feature.featureSet);
    // sort features
    features = features.sort((a: any, b: any) => a - b);
    // get block key
    const blockKey = getBlockKeyByFeatures(features);

    if (!blockKey) {
      continue;
    }

    productBlocks[blockKey] = products[i];
  }

  let sortedProducts: any = {};
  for(let i in blocks) {
    const block = blocks[i];
    if (productBlocks[block.key]) {
      sortedProducts[block.key] = {
        ...productBlocks[block.key],
        block: block,
      } as ExtendedProductOnDrugs;
    }
  }

  return Object.values(sortedProducts);
}

export const setActiveBlockCache = (block: any) => {
  localStorage.setItem('@nmblr/active-block', block);
}

export const getActiveBlockCache = () => {
  return localStorage.getItem('@nmblr/active-block');
}