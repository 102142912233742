import React from 'react';
import { Navbar } from 'components/Navbar';
import styled from 'styled-components';
import InformationModal from 'components/shared/InformationModal';
import { colorsv2 } from 'constants/colors';
import { ButtonPill, BodySmall, Heading3 } from 'components/shared';
import { device } from 'utils/breakpoints';

export const PageWrapper = styled.div`
  align-items: center;
  background: ${colorsv2.bg.surfaceBase2};
  background-image: url(/Profile@2x-8.png);
  background-size: 1377px 944px;
  background-repeat: no-repeat;
  background-position: center 70px;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  position: absolute;
  height: 100%;
  width: 100%;

  @media (${device.mobile}) {
    position: fixed;
  }
`;

const SignInModal = styled(InformationModal)`
  margin-top: 85px;
  margin-bottom: 85px;
  max-width: 456px;
  padding: 32px;
  width: 100%;

  @media (${device.mobile}) {
    border-radius: 0;
    height: 100%;
    margin-top: 50px;
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Title = styled(Heading3)`
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: auto;
  width: 100%;
`;

export const ErrorBox = styled.div`
  width: 376px;
  width: 100%;
  padding: 15px;
  background: ${colorsv2.bg.dangerWeak};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline;
`;

export const ErrorMessage = styled(BodySmall)`
  width: 346px;
  display: inline;
`;

export const SubmitButton = styled(ButtonPill)`
`;

export const Signin: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <>
      <Navbar
        exclude={[
          'drugLink',
          'strategyLink',
          'filesLink',
          'profile',
          'notifications',
          'navItems',
          'navContent',
          'sidebarTrigger',
        ]}
        disableSecondary
      />
      <PageWrapper>
        <SignInModal>
          {children}
        </SignInModal>
      </PageWrapper>
    </>
  );
};
