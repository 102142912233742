import React, { useEffect, useState } from 'react';
import {
  ProductsDocument,
  ProductCreateMutation,
  ProductUpdateMutation,
  ProductDeleteMutation,
  useProductsQuery,
  useProductCreateMutation,
  useProductUpdateMutation,
  Sort,
} from '../data/graphql/generated';
import { Navbar } from '../components/Navbar';
import styled from 'styled-components/macro';
import { device } from 'utils/breakpoints';
import { ButtonPill } from 'components/shared';
import { ModalForm } from 'components/ModalForm';
import FormTextInput from 'components/shared/FormTextInput';
import {
  apolloCreateHelper,
  apolloUpdateHelper
} from 'utils/apolloQueryHelpers';
import { featureSet as featureList } from 'constants/features';
import { Table } from 'components/shared/Table';

const StyledButtonPill = styled(ButtonPill)`
  margin-left: auto;
  margin-bottom: 15px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
`;

const PageWrapper = styled.div`
  padding: 80px 15px 0 15px;

  @media ${device.tabletMax} {
    padding-top: 65px;
  }
`;

const CheckboxList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style-type: none;
  padding: 0;
  margin: 20px 0px 10px;
  gap: 10px;
`;

const CheckboxListItem = styled.li`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 5px;
  border-top: 1px solid #E8E8E9;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Label = styled.label`
  font-size: 16px;
  margin-left: 5px;
`;


const FeatureSetMultiSelect = ({ options, selectedFeatures, onChange }: any) => {
  const handleCheckboxChange = (featureSet: any) => {
    if (selectedFeatures.includes(featureSet)) {
      onChange(selectedFeatures.filter((feature: any) => feature !== featureSet));
    } else {
      onChange([...selectedFeatures, featureSet]);
    }
  };

  return (
    <CheckboxList>
      {options.map((option: any) => (
        <CheckboxListItem key={option.id} onClick={() => handleCheckboxChange(option.text)}>
          <input
            type="checkbox"
            checked={selectedFeatures.includes(option.text)}
            onChange={() => handleCheckboxChange(option.text)}
          />
          <Label>{option.text}</Label>
        </CheckboxListItem>
      ))}
    </CheckboxList>
  );
};

export const Products: React.FC = () => {
  const { data: productData, refetch } = useProductsQuery({
    variables: {
      orderBy: {
        id: Sort.Asc
      }
    },
    fetchPolicy: 'network-only',
  });

  const [createProduct] = useProductCreateMutation({
    update: apolloCreateHelper({
      responseField: 'productsCreate',
      query: ProductsDocument,
      queryName: 'products',
      queryVars: {},
    }),
  });

  const [updateProduct] = useProductUpdateMutation({
    update: apolloUpdateHelper({
      responseField: 'productsUpdate',
      query: ProductsDocument,
      queryName: 'products',
      queryVars: {},
    }),
  });

  const [modalState, setModalState] = useState<Pick<
    ModalProps,
    'type' | 'heading' | 'id' | 'productName' | 'features' | 'description'
  > | null>(null);

  const columns = ['ID', 'Name', 'Tools'];
  const renderRow = (product: any, idx: number) => (
    <tr key={product.id}>
      <td style={{ paddingLeft: 10 }}>{product.id}</td>
      <td>{product.productName}</td>
      <td>{product.features.map((feature: any) => feature.featureSet).join(', ')}</td>
      <td style={{ display: 'flex', paddingBottom: 10, alignItems: 'center' }}>
        <ButtonPill
          iconName="Edit"
          level="secondary"
          text="Edit"
          onClick={() => {
            setModalState({
              type: 'edit',
              heading: 'Update product',
              features: product.features,
              productName: product.productName,
              id: product.id,
            });
          }}
        />
      </td>
    </tr>
  );

  return (
    <PageWrapper>
      <Modal
        handleClose={() => {
          setModalState(null);
        }}
        onDelete={async (id: number) => Promise.resolve()}
        visible={!!modalState}
        heading={modalState?.heading}
        productName={modalState?.productName}
        description={modalState?.description}
        features={modalState?.features}
        id={modalState?.id}
        type={modalState?.type}
        onUpdate={async (productName: string, features: any, description: string, id: number) => {
          const { data } = await updateProduct({
            variables: { data: { productName, features, description }, id },
          });

          return data;
        }}
        onCreate={async (productName: string, features: any, description: string) => {
          const { data } = await createProduct({
            variables: { data: { productName, features, description } },
          });

          refetch();

          return data;
        }}
      />
      <Navbar
        disableSecondary
        include={['userLink', 'productLink']}
        exclude={[
          'drugLink',
          'filesLink',
          'strategyLink',
          'notifications',
          'navItems',
          'navContent',
        ]}
      />
      <StyledButtonPill
        className="cypress-create-product"
        iconName="Plus"
        text="Create product"
        onClick={() => {
          setModalState({
            type: 'create',
            heading: 'Create product',
            features: undefined
          });
        }}
      />
      <Table
        cols={columns}
        rows={renderRow}
        data={productData?.products?.items
          ?.filter((item) => item !== null)
          .map((item) => ({
            ...item,
            id: item?.id,
          })) || []}
        emptyStateText={{
          header: 'No products available',
          subHeading: 'Please add a product to get started.',
        }}
        isDesktop={true}
      />
    </PageWrapper>
  );
};

interface ModalProps {
  type: 'create' | 'edit' | undefined;
  handleClose(): void;
  visible: boolean;
  heading?: string;
  productName?: string;
  description?: string;
  features?: any;
  id?: number;
  onUpdate(
    description: string,
    productName: string,
    features: any,
    id: number
  ): Promise<ProductUpdateMutation | null | undefined>;
  onCreate(productName: string, features: any, description: string): Promise<ProductCreateMutation | null | undefined>;
  onDelete?(id: number): Promise<ProductDeleteMutation | void | null>;
}

function Modal({
  handleClose,
  visible,
  heading,
  productName,
  description,
  features,
  id,
  onUpdate,
  onCreate,
  type,
}: ModalProps) {
  const [textValue, setTextValue] = useState(productName || '');
  const [descriptionValue, setDescription] = useState(description || '');
  const [featureSet, setFeatureSet] = useState(features || [

  ]);
  const [responseError, setResponseError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputTouched, setInputTouched] = useState(false);

  useEffect(() => {
    setTextValue(productName || '');
  }, [productName]);
  useEffect(() => {
    setDescription(description || '');
  }, [description]);
  useEffect(() => {
    if (type === 'create') setFeatureSet([
    ]);
  }, [type]);

  useEffect(() => {
    if (features) {
      setFeatureSet(features || []);
    }
  }, [features]);

  useEffect(() => {
    setResponseError('');

    return () => {
      setInputTouched(false);
      setIsSubmitting(false);
      setTextValue('');
      setResponseError('');
    };
  }, [visible]);

  const items = featureList.length > 0
    ? featureList.map((item: any) => ({
      id: item.id,
      text: item.feature,
    }))
    : [{
      id: 0,
      text: "Select Feature",
    }];

  return (
    <ModalForm
      handleClose={() => {
        handleClose();
      }}
      visible={visible}
      heading={heading}
    >
      <FormTextInput
        name="productName"
        type="text"
        onChange={(e) => {
          setTextValue(e.target.value);
        }}
        onBlur={() => {
          setInputTouched(true);
        }}
        value={textValue}
        title="Product name"
        errorMessage={
          responseError ||
          (!textValue && inputTouched ? 'Name cannot be blank.' : '')
        }
      />

      <FeatureSetMultiSelect
        options={items}
        selectedFeatures={featureSet.map((f: any) => f.featureSet)}
        onChange={(selectedFeatures: any) => {
          setFeatureSet(selectedFeatures.map((featureSet: any) => ({
            featureSet,
          })));
        }}
      />

      <FormTextInput
        name="welcomeText"
        type="text"
        onChange={(e) => {
          setDescription(e.target.value);
        }}
        onBlur={() => {
          setInputTouched(true);
        }}
        value={descriptionValue}
        title="Welcome Text"
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 20,
          gap: 15,
        }}
      >
        <ButtonPill
          level="secondary"
          width="180px"
          text="Cancel"
          onClick={() => {
            setResponseError('');
            handleClose();
          }}
        />
        <ButtonPill
          className="cypress-submit"
          disabled={isSubmitting || !textValue}
          type="submit"
          width="180px"
          text={type === 'create' ? 'Create product' : 'Update product'}
          onClick={async () => {
            if (type) {
              setIsSubmitting(true);
              const trimmedTextValue = textValue.trim();
              if (!trimmedTextValue) {
                setResponseError('Name cannot be blank.');
                setTextValue('');
                setIsSubmitting(false);
                return;
              }

              try {
                if (type === 'create') {
                  await onCreate(trimmedTextValue, featureSet, descriptionValue);
                }

                if (type === 'edit' && typeof id === 'number') {
                  await onUpdate(trimmedTextValue, featureSet, descriptionValue, id);
                }
              } catch (error) {
                if (error instanceof Error) {
                  setResponseError(error.message);
                }
                setIsSubmitting(false);
                return;
              }

              setIsSubmitting(false);
              handleClose();
            }
          }}
        />
      </div>
    </ModalForm>
  );
}