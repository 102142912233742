import { Navbar } from 'components/Navbar';
import { Page } from 'components/Page';
import { PostIts } from 'components/PostIts';
import { BodyNormal, StepHeaderBar, Subtitle2 } from 'components/shared';
import { ErrorModal } from 'components/ErrorModal';
import { StakeHolderTabs } from 'components/StakeHolderTabs';
import { StakeholderDefinitions } from 'components/StakeholderDefinitions';
import { colors } from '../constants';
import { useMemo } from 'react';
import { useStakeholderContext } from 'contexts/StakeholderContext';
import {
  Step,
  StakeholderDefinitionsQueryVariables,
  useStakeholderDefinitionsQuery,
  Stakeholder,
} from 'data/graphql/generated';
import { usePostItCards } from 'hooks/usePostItCards';
import { usePostItGroups } from 'hooks/usePostItGroups';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { URLParams } from 'types';
import { device } from 'utils/breakpoints';
import { mapStakeholderEnumToSingularStakeholder } from 'utils/mapStakeholderEnumToSingularStakeholder';
import { usePostItCardMove } from 'hooks/usePostItCardMove';
import { Observations } from '../components/Observations';
import { ErrorWrapper } from '../components/ErrorLoadingComponent';
import { polling } from 'constants/index';
import { sortPostIts } from '../hooks';

const PageWrapper = styled(Page)`
  padding-top: 20px;
  overflow: hidden;
  @media ${device.tabletMax} {
    > div {
      width: 100%;
      padding: 0;
    }
  }
`;

const StepHeaderBarWrapper = styled.div`
  margin-top: 110px;
  @media ${device.tabletMax} {
    margin-top: 160px;
  }

  ${StepHeaderBar} {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
`;

const ContributionsHeader = styled.div`
  width: 100%;
  padding: 15px 0;
`;

const StakeholderDefinitionsWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const KeyStakeholders: React.FC = () => {
  const [deleteErrorModal, setDeleteErrorModal] = useState(false);
  const [moveErrorModal, setMoveErrorModal] = useState(false);

  const {
    strategyId,
    drugId,
    stakeholder: stakeholderParam,
  }: URLParams = useParams();
  const [stakeholder, setStakeholder] = useStakeholderContext(stakeholderParam);
  const history = useHistory();

  useEffect(() => {
    if (!stakeholderParam) {
      history.replace(
        `/d/${drugId}/strategy/${strategyId}/1_2/${
          stakeholder || Stakeholder.Patient
        }`
      );
    }
  }, [drugId, history, stakeholder, stakeholderParam, strategyId]);

  const {
    items: groupItems,
    loading: groupsLoading,
    disabled: groupsDisabled,
    createGroup,
    updateGroup,
    removeGroup,
  } = usePostItGroups(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Keystakeholders,
        stakeholder: stakeholder,
      },
    },
    {
      strategyId,
      drugId,
      step: Step.Keystakeholders,
      stakeholder: stakeholder,
      idx: 10,
    }
  );

  const {
    items: cardItems,
    loading: cardsLoading,
    createCard,
    updateCard,
    removeCard,
  } = usePostItCards(
    {
      where: {
        strategyId: Number(strategyId),
        step: Step.Keystakeholders,
        stakeholder,
      },
    },
    { strategyId, drugId, step: Step.Keystakeholders, stakeholder: stakeholder }
  );

  const stakeholderDefinitionsQueryVars: StakeholderDefinitionsQueryVariables = {
    where: { strategyId: Number(strategyId), stakeholder },
  };

  const {
    data: stakeholderDefinitionsData,
    loading: stakeholderDefinitionsLoading,
    error,
    startPolling,
    stopPolling,
  } = useStakeholderDefinitionsQuery({
    variables: {
      ...stakeholderDefinitionsQueryVars,
    },
  });
  useEffect(() => {
    startPolling(polling.default);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const initialGroups = sortPostIts(groupItems, cardItems).sort((a, b) => {
    const isPresetALarger = Number(a.preset) < Number(b.preset);
    const isPresetBLarger = Number(a.preset) > Number(b.preset);
    const isIdxASmaller = a.idx < b.idx;
    const isIdxBLarger = a.idx > b.idx;
    const isCreatedAtASmaller = a.createdAt < b.createdAt;
    const isCreatedAtBLarger = a.createdAt > b.createdAt;

    if (isPresetALarger) {
      return 1;
    } else if (isPresetBLarger) {
      return -1;
    } else if (isIdxASmaller) {
      return -1;
    } else if (isIdxBLarger) {
      return 1;
    } else if (isCreatedAtASmaller) {
      return -1;
    } else if (isCreatedAtBLarger) {
      return 1;
    } else {
      return 0;
    }
  });

  const groups = useMemo(() => {
    return initialGroups?.filter(
      (p) =>
        !p.title.includes('What are the outcomes that matter to') || 
        (p.stakeholder === Stakeholder.Provider || p.stakeholder === Stakeholder.Policymaker)
    );
  }, [initialGroups]);


  const onChange = usePostItCardMove(
    groups,
    // @ts-ignore
    async (vals) => {
      try {
        await updateCard(vals);
      } catch (err) {
        if (err instanceof Error && err.message === 'MOVE_ERROR') {
          setMoveErrorModal(true);
        }
      }
    }
  );

  const singularStakeHolder =
    mapStakeholderEnumToSingularStakeholder[stakeholder];

  return (
    <>
      <Navbar navMenuChildren={null}>
        <StakeHolderTabs
          stakeholder={stakeholder}
          setStakeholder={(stakeholder) => {
            history.push(
              `/d/${drugId}/strategy/${strategyId}/1_2/${stakeholder}`
            );
            setStakeholder(stakeholder);
          }}
        />
      </Navbar>

      <Observations stakeholder={stakeholder} step={Step.Keystakeholders} />

      <StepHeaderBarWrapper>
        <StepHeaderBar>
          <ContributionsHeader>
            <Subtitle2 style={{ marginBottom: 5 }}>
              Understanding the {singularStakeHolder?.toLocaleLowerCase()}'s
              point of view
            </Subtitle2>
            <BodyNormal color={colors.greyDark}>
              Add notes based on the prompts. Add more themes to organise other
              ideas. A Lead should finalise the definitions.
            </BodyNormal>
          </ContributionsHeader>

          <StakeholderDefinitionsWrapper>
            {!stakeholderDefinitionsLoading && stakeholderDefinitionsData ? (
              <StakeholderDefinitions
                drugId={drugId}
                strategyId={strategyId}
                data={stakeholderDefinitionsData}
                stakeholder={stakeholder}
                stakeholderTab={singularStakeHolder}
              />
            ) : null}
          </StakeholderDefinitionsWrapper>
        </StepHeaderBar>
      </StepHeaderBarWrapper>

      <ErrorWrapper
        isLoading={cardsLoading || groupsLoading}
        errors={[error]}
        dataMissing={!groups}
      >
        <PageWrapper paddingTop={false}>
          {cardsLoading || groupsLoading ? null : (
            <PostIts
              step={Step.Keystakeholders}
              groups={groups}
              addCard={createCard}
              updateCard={updateCard}
              removeCard={async (vals) => {
                try {
                  return await removeCard(vals);
                } catch (err) {
                  if (err instanceof Error && err.message === 'DELETE_ERROR') {
                    setDeleteErrorModal(true);
                  }
                }
              }}
              handleCardChange={onChange}
              createGroup={createGroup}
              updateGroup={updateGroup}
              removeGroup={removeGroup}
              createGroupDisabled={groupsDisabled}
            />
          )}
        </PageWrapper>
      </ErrorWrapper>

      <ErrorModal
        visible={deleteErrorModal}
        handleClose={() => setDeleteErrorModal(false)}
        title="Cannot delete this note"
        text="Content in later steps depends on this note as an outcome. Remove content and try again."
      />

      <ErrorModal
        visible={moveErrorModal}
        handleClose={() => setMoveErrorModal(false)}
        title="Cannot move this note"
        text="Content in later steps depends on this note as an outcome. Remove content and try again."
      />
    </>
  );
};
