import { colorsv2 } from 'constants/index';
import styled from 'styled-components';
import { uid } from 'uid';
import { Icon } from './Icon';
import { Tooltip } from './Tooltip';

interface Props {
  direction: 'l' | 'r';
  disabled?: boolean;
  tooltip?: string;
  onClick?(): void;
  className?: string;
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 40px;
  white-space: nowrap;
  width: 40px;
`;

const IconWrapper = styled.button<{ direction: 'l' | 'r' }>`
  width: 40px;
  height: 40px;
  align-items: center;
  background-color: ${colorsv2.bg.surfaceBase};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  transform: ${({ direction }) =>
    `rotate(${direction === 'r' ? '-' : ''}90deg)`};
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  :hover {
    background-color: ${({ disabled }) =>
      disabled ? colorsv2.bg.surfaceBase : colorsv2.bg.layer};
  }

  :active {
    background-color: ${({ disabled }) =>
      disabled ? colorsv2.bg.surfaceBase: colorsv2.bg.layer};
  }

  transition: all 0.3s ease;
`;

export const StepButton = ({
  direction,
  disabled,
  tooltip = '',
  onClick,
  className,
}: Props) => {
  const key = uid();
  return (
    <Wrapper className={className} onClick={onClick}>
      <IconWrapper
        data-offset="{ 'right': -14, 'top':-35 }"
        data-place="right"
        data-for={key}
        data-tip={tooltip}
        disabled={disabled}
        direction={direction}
      >
        <Icon name="Chevron-down" size={24} color={colorsv2.textInverted.default} />
      </IconWrapper>
      <Tooltip effect="float" id={key} />
    </Wrapper>
  );
};
